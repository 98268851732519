import { FC, SyntheticEvent, useState } from "react";
import { Box, SwipeableDrawer } from "@mui/material";
import { UserTitle } from "../avatar/UserTitle";
import { NotificationIndicator } from "../notification/NotificationIndicator";
import { BoardBase } from "../board/BoardBase";
import GreenCoin from "../../../../assets/sweep/icons/GreenCoin.png";
import GoldenCoin from "../../../../assets/sweep/icons/GoldenCoin.png";
import { Switcher } from "../switcher/Switcher";
import ExitIcon from "../../../../assets/sweep/icons/ExitIcon.webp";
import { ButtonWithIcon } from "../button/ButtonWithIcon";
import SettingsIcon from "../../../../assets/sweep/icons/SettingIcon.webp";
import ShopIcon from "../../../../assets/sweep/icons/ShopIcon.webp";
import CloseIcon from "../../../../assets/sweep/icons/CloseIcon.webp";
import AnnounceIcon from "../../../../assets/sweep/icons/AnounceBig.webp";
import RankingIcon from "../../../../assets/sweep/icons/RankingBig.webp";
import FavoriteIcon from "../../../../assets/sweep/icons/FavoritesBig.webp";
import { HexagonButtonLong } from "../button/HexagonButtonLong";
import DragonMobile from "../../../../assets/sweep/Dragon-mobile.webp"
import { WalletWindow } from "../wallet/WalletWindow";
import { LobbySettings } from "../lobby_settings/LobbySettings";

interface GameDrawerProps {
    isDrawerOpened: boolean
    setIsDrawerOpened: (isDrawerOpened: boolean) => void
    setLoginOpened: (loginOpened: boolean) => void
}

export const GameDrawer: FC<GameDrawerProps> = ({ isDrawerOpened, setIsDrawerOpened, setLoginOpened }) => {
    const [funEnabled, setFunEnabled] = useState<boolean>(true)
    const [isLobbySettingsOpened, setIsLobbySettingsOpened] = useState<boolean>(false)
    const [isWalletOpened, setIsWalletOpened] = useState<boolean>(false)

    const toggleDrawer = (open: boolean) => (event: SyntheticEvent<{}>) => {
        setIsDrawerOpened(open);
    }

    return <SwipeableDrawer
        anchor="left"
        open={isDrawerOpened}
        onOpen={toggleDrawer(true)}
        onClose={toggleDrawer(false)}

        sx={{
            '.MuiBackdrop-root': {
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                backdropFilter: 'blur(0.6vh)'
            },
            '.MuiPaper-root': {
                backgroundColor: 'transparent',
                backgroundImage: 'none'
            }
        }}
    >
        <Box
            height={'100%'}
            width={'40vh'}
            maxWidth={'80vw'}
            display={'flex'}
            flexDirection={'column'}
            padding={'4vh'}
            position={'relative'}
            rowGap={'6vh'}
        >
            {/* Close button */}
            <Box
                position={'fixed'}
                right={'4vw'}
                top={'4vh'}
            >
                <ButtonWithIcon iconURL={CloseIcon} iconAlt={'Settings icon'} onClick={() => setIsDrawerOpened(false)}/>
            </Box>


            <Box
                zIndex={-1}
                position={'absolute'}
                top={0}
                left={'-4vh'}
                right={0}
                bottom={0}
            >
                <BoardBase width={'100%'} height={'100%'}/>
            </Box>


            <Box
                display={'flex'}
                columnGap={'1.7vw'}
                justifyContent={'space-between'}
                alignItems={'center'}
            >
                <UserTitle/>
                <NotificationIndicator/>
            </Box>
            <Box
                display={'flex'}
                columnGap={'1.7vw'}
                justifyContent={'space-between'}
                alignItems={'center'}
            >
                <Switcher
                    width={'100%'}
                    onSwitch={() => setFunEnabled(!funEnabled)}
                    leftIsActive={funEnabled}
                    leftText={'100'}
                    leftIcon={GreenCoin}
                    rightText={'790'}
                    rightIcon={GoldenCoin}
                />
            </Box>
            <Box
                display={'flex'}
                columnGap={'1.7vw'}
                justifyContent={'space-evenly'}
                alignItems={'center'}
            >
                <ButtonWithIcon iconURL={ExitIcon} iconAlt={'Exit icon'} onClick={() => setLoginOpened(true)}/>
                <ButtonWithIcon iconURL={SettingsIcon} iconAlt={'Settings icon'} onClick={() => setIsLobbySettingsOpened(true)}/>
                <ButtonWithIcon green iconURL={ShopIcon} iconAlt={'Shop icon'} onClick={() => setIsWalletOpened(true)}/>
            </Box>

            <Box
                display={'flex'}
                flexDirection={'column'}
                rowGap={'3vh'}
            >
                <HexagonButtonLong iconImageUrl={RankingIcon} title={'Ranking'}/>
                <HexagonButtonLong iconImageUrl={AnnounceIcon} title={'Announce'}/>
                <HexagonButtonLong iconImageUrl={FavoriteIcon} title={'Favorites'}/>
            </Box>

            <Box
                position={'fixed'}
                bottom={0}
                left={0}
                height={'40vh'}
                width={'63vh'}
                zIndex={-1}
                sx={{
                    pointerEvents: 'none',
                    backgroundImage: `url(${DragonMobile})`,
                    backgroundSize: 'contain',
                    backgroundPosition: 'left bottom',
                    backgroundRepeat: 'no-repeat'
                }}
            />
        </Box>

        {isWalletOpened && <WalletWindow onClose={() => setIsWalletOpened(false)}/>}

        {isLobbySettingsOpened && <LobbySettings onClose={() => setIsLobbySettingsOpened(false)}/>}
    </SwipeableDrawer>
}