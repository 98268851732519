import { FC, ReactNode } from "react";
import { Box } from "@mui/material";
import {getShadows as getGreenShadows, backgroundGradient as backgroundGradientGreen} from "./greenStyle"
import {getShadows as getRedShadows, backgroundGradient as backgroundGradientRed} from "./redStyle"
import {getShadows as getBlueShadows, backgroundGradient as backgroundGradientBlue} from "./blueStyle"
import {getShadows as getGrayShadows, backgroundGradient as backgroundGradientGray} from "./grayStyle"

export enum Color {
    green = 'green',
    red = 'red',
    blue = 'blue',
    gray = 'gray'
}

interface GameButtonFilledBaseProps {
    width?: string
    height?: string
    children?: ReactNode
    size?: number
    color?: Color

}

export const GameButtonFilledBase: FC<GameButtonFilledBaseProps> = ({width, height, children, size= 5, color  = Color.green}) => {
    const baseSize = 48 / size

    const shadows = getShadows(baseSize, color)
    const bgGradient = getBgGradient(baseSize, color)

    return <Box
        borderRadius={`${12 / baseSize}vh`}
        width={width}
        height={height}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'space-evenly'}
        boxShadow={shadows}
        overflow={'hidden'}
        sx={{background: bgGradient}}
    >
        {children}
    </Box>
}

const getShadows = (baseSize: number, color: Color): string => {
    switch (color) {
        case Color.gray:
            return getGrayShadows(baseSize)
        case Color.red:
            return getRedShadows(baseSize)
        case Color.blue:
            return getBlueShadows(baseSize)
        default:
            return getGreenShadows(baseSize)
    }
}

const getBgGradient = (baseSize: number, color: Color): string => {
    switch (color) {
        case Color.gray:
            return backgroundGradientGray
        case Color.red:
            return backgroundGradientRed
        case Color.blue:
            return backgroundGradientBlue
        default:
            return backgroundGradientGreen
    }
}