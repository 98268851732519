import { FC } from "react";
import { Box } from "@mui/material";

interface PaymentCardBackgroundProps {
    size?: number
    borderRadius?: string
}

export const PaymentCardBackground: FC<PaymentCardBackgroundProps> = ({ size = 5, borderRadius }) => {
    const baseSize = 48 / size
    const radius = borderRadius ? borderRadius : `${12 / baseSize}vh`

    return <Box
        position={'absolute'}
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={-1}
        borderRadius={radius}
        sx={{
            background: 'linear-gradient(180.00deg, rgba(62, 41, 183, 0),rgba(38, 34, 205, 0.8) 100%),rgba(22, 9, 67, 0.45)'
        }}
    >
        <Box
            border={`${3 / baseSize}vh solid #3E29B7FF`}
            width={'100%'}
            height={'100%'}
            borderRadius={radius}
        />
    </Box>
}