import { Box } from "@mui/material";
import { FC, ReactNode } from "react";

interface PaymentCardContainerProps {
    children: ReactNode
}

export const PaymentCardContainer: FC<PaymentCardContainerProps> = ({children}) => {
    return <Box
        position={'relative'}
        maxWidth={'24vh'}
        minWidth={'24vh'}
        height={'16vh'}
        overflow={'visible'}
    >
        {children}
    </Box>
}