import { FC } from "react";
import { Box } from "@mui/material";
import { TabItem } from "./TabItem";

export interface TabObject {
    name: string
}

interface TabsPanelProps {
    size?: number
    tabs: TabObject[]
    onTabIndexChanged: (index: number) => void
    activeTabIndex: number
}

export const TabsPanel: FC<TabsPanelProps> = ({size = 5, tabs, onTabIndexChanged, activeTabIndex}) => {
    const baseSize = 48 / size
    const borderRadius = `${12 / baseSize}vh`

    const shadowList = [
        `0 ${12 / baseSize}vh ${24 / baseSize}vh rgba(14, 0, 54, 0.35)`,
    ]

    const shadows = shadowList.join(', ')

    const getPositionInPanel = (index: number) => {
        switch (index) {
            case 0:
                return 'left'
            case tabs.length - 1:
                return 'right'
            default:
                return 'center'
        }
    }

    return <Box
        borderRadius={borderRadius}
        display={'flex'}
        width={'100%'}
        minHeight={`${70 / baseSize}vh`}
        boxShadow={shadows}
        sx={{
        backgroundColor: '#063C81'
        }}
    >
        {tabs.map((tab, index) => {
            return <TabItem
                size={size}
                onClick={() => {
                    onTabIndexChanged(index)
                }}
                title={tab.name}
                key={tab.name}
                positionInPanel={getPositionInPanel(index)}
                active={activeTabIndex === index}
            />
        })}
    </Box>
}