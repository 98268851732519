import { FC, ReactNode } from "react";
import { Box } from "@mui/material";
import { BottomButtonPanel } from "./BottomButtonPanel";
import { WheelButton } from "./WheelButton";

interface ButtonPanelPortraitProps {
    children?: ReactNode
}

export const  ButtonPanelPortrait: FC<ButtonPanelPortraitProps> = ({children}) => {
    return <Box
        minHeight={'12vh'}
        maxHeight={'20vw'}
        position={'fixed'}
        left={0}
        right={0}
        bottom={0}
        display={'flex'}
        pt={'1vh'}
        pb={'2vh'}
        px={'6vw'}
        columnGap={'7vw'}
    >
        <BottomButtonPanel>
            {children}
        </BottomButtonPanel>
        <WheelButton portrait/>
    </Box>
}