export const contourColor = '#79CC49'
export const overlayGradient = 'linear-gradient(180deg, rgba(48,105,44,0) 70%, #30692C 100%)'

export const getContainerShadows = (baseSize: number): string => {
    const shadowsList: string[] = [
        `0 0 ${12 / baseSize}vh rgba(47, 113, 8, 0.65)`, // outer frame glowing
        `0 0 ${24 / baseSize}vh #2F7108`, // thin outer frame glowing

        `inset 0 ${2 / baseSize}vh 0 #90EE5A`, // frame bevel
    ]
    return shadowsList.join(', ')
}

export const getInnerBoxShadows = (baseSize: number): string => {
    const shadowsList: string[] = [
        `inset 0 ${1 / baseSize}vh ${4 / baseSize}vh #3A850F`, // thin dark contour inside frame
        `inset 0 ${2 / baseSize}vh ${24 / baseSize}vh #79CC49`, // shadow inside frame

        `0 0 ${12 / baseSize}vh rgba(47, 113, 8, 0.65)`, // inner frame glowing

        `0 ${2 / baseSize}vh 0 #90EE5A`, // frame bevel
    ]
    return shadowsList.join(', ')
}