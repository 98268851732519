import { FC } from "react";
import { GameDialog } from "../game_dialog_windows/GameDialog";
import { Box, Typography } from "@mui/material";
import { UserTitle } from "../avatar/UserTitle";
import { WhiteShadowedText } from "../text/WhiteShadowedText";
import { GameButtonFilled } from "../button/GameButtonFilled";
import { GameTextField } from "../game_text_field/GameTextField";

interface UserProfileProps {
    onClose?: () => void
}

export const UserProfile: FC<UserProfileProps> = ({onClose = () => {}}) => {
    return <GameDialog onClose={onClose}>
        <Box
            display={'flex'}
            flexDirection={'column'}
            rowGap={'2vh'}
            minWidth={'40vh'}
            maxHeight={'80vh'}
        >
            <Box
                display={'flex'}
                columnGap={'3vh'}
                alignItems={'center'}
                pb={'2vh'}
            >
                <UserTitle/>

                <WhiteShadowedText>
                    id: 27503222
                </WhiteShadowedText>
            </Box>

            <Box
                display={'flex'}
                flexDirection={'column'}
                rowGap={'2vh'}
                flexGrow={1}
                sx={{
                    overflowY: 'auto',
                    scrollbarWidth: 'none',
                    scrollbarColor: 'rgba(255, 255, 255, 0.1) transparent',
                    '&:hover': {
                        scrollbarWidth: 'thin',
                    }
                }}
            >
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    rowGap={'1vh'}
                >
                    <Typography>Current nickname</Typography>
                    <GameTextField
                        placeholder={'Alexandr'}
                        type={'text'}
                    />
                </Box>

                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    rowGap={'1vh'}
                >
                    <Typography>Email</Typography>
                    <GameTextField
                        // value={login}
                        // onChange={event => setLogin(event.target.value)}
                        placeholder={'@'}
                        type={'email'}
                    />
                </Box>

                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    rowGap={'1vh'}
                >
                    <Typography>Phone number</Typography>
                    <GameTextField
                        placeholder={'+370'}
                        type={'text'}
                    />
                </Box>

                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    rowGap={'1vh'}
                >
                    <Typography>WhatsApp</Typography>
                    <GameTextField
                        placeholder={'+370'}
                        type={'text'}
                    />
                </Box>

                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    rowGap={'1vh'}
                >
                    <Typography>Telegram</Typography>
                    <GameTextField
                        placeholder={'nickname'}
                        type={'text'}
                    />
                </Box>
            </Box>

            <GameButtonFilled>
                Save information
            </GameButtonFilled>
        </Box>
    </GameDialog>
}