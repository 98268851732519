import { FC, useEffect, useState } from "react";
import { PaymentAccount, PaymentMethodTemplate } from "../../../../features/paymentSystems/PaymentSystemModels";
import { usePaymentSystems } from "../../../../features/paymentSystems/usePaymentSystems";
import { MoneyInput } from "../../../../components/money_input/MoneyInput";
import { convertCentsToCurrencyString, convertStringCurrencyToCents } from "../../../../features/common_funcs";
import { useUser } from "../../../../features/user/useUser";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import { Box, Button, CircularProgress, LinearProgress, Stack } from "@mui/material";
import { AddPayment } from "../../payment_accounts/add_payment/AddPayment";
import { useSnackbar, VariantType } from "notistack";
import { useLanguage } from "../../../../features/localisation/useLanguage";

interface RequestPayoutProps {
    cashierPaymentAccount: PaymentAccount;
    onDone: () => void;
}

export const RequestPayout: FC<RequestPayoutProps> = ({ onDone, cashierPaymentAccount }) => {
    const { languagePack: { pack: { withdrawMoney: lang } } } = useLanguage();
    const { user } = useUser();
    const {
        userPaymentAccounts,
        getUserPaymentAccounts,
        isPaymentAccountsLoading,
        isRequestPayoutLoading,
        getPaymentMethods,
        isPaymentMethodsLoading,
        paymentMethods,
        requestPayout
    } = usePaymentSystems();
    const [userPaymentAccount, setUserPaymentAccount] = useState<PaymentAccount | null>(null);
    const [paymentMethodTemplate, setPaymentMethodTemplate] = useState<PaymentMethodTemplate | null>(null);
    const [amount, setAmount] = useState<string>('');
    const [maxAmount, setMaxAmount] = useState<string>('');
    const [hasError, setHasError] = useState<boolean>(false);
    const [initLoad, setInitLoad] = useState(true);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (user !== null) {
            const balance = convertCentsToCurrencyString(user.user_balance);
            if (maxAmount !== balance) {
                setMaxAmount(balance);
            }
        }
    }, [maxAmount, user]);

    useEffect(() => {
        if (initLoad) {
            // If the component has just been mounted
            if (userPaymentAccounts === null && !isPaymentAccountsLoading) {
                // If user payment accounts are not loaded and not currently loading
                // Request user payment accounts
                getUserPaymentAccounts();
            }
            if (paymentMethods === null && !isPaymentMethodsLoading) {
                // If payment method templates are empty and not currently loading
                // Request payment method templates
                getPaymentMethods();
            }
            setInitLoad(false);
        }
    }, [getPaymentMethods, getUserPaymentAccounts, initLoad, isPaymentAccountsLoading, isPaymentMethodsLoading, paymentMethods, userPaymentAccounts]);

    useEffect(() => {
        if (userPaymentAccounts !== null && paymentMethods !== null) {
            // If user payment accounts and payment method templates are loaded
            if (userPaymentAccount === null) {
                // If there is no user payment account yet (e.g., it has not been found
                // or created yet), then search for it
                const paymentAccount = userPaymentAccounts.find(acc => acc.method_id === cashierPaymentAccount.method_id);
                if (paymentAccount) {
                    // If the method is found, save it to the state
                    setUserPaymentAccount(paymentAccount);
                } else {
                    // If the method cannot be found, search for the payment method template
                    // and save it to the state
                    const template = paymentMethods.find(t => t.id === cashierPaymentAccount.method_id);
                    if (template) {
                        setPaymentMethodTemplate(template);
                    }
                }
            }
        }
    }, [cashierPaymentAccount, paymentMethods, userPaymentAccount, userPaymentAccounts]);

    const sendSnackBar = (variant: VariantType, message: string) => {
        enqueueSnackbar(message, { variant });
    };

    const requestClickHandler = () => {
        requestPayout(cashierPaymentAccount.method_id, convertStringCurrencyToCents(amount), () => {
            const money = `${amount} ${user?.user_currency}`;
            const message = lang.moneySuccessfulRequestedMessage.replace('%money%', money);
            sendSnackBar('success', message);
            onDone();
        });
    };

    return <Stack rowGap={'10px'}>
        {(isPaymentAccountsLoading || isRequestPayoutLoading || isPaymentMethodsLoading) && <LinearProgress sx={{ width: '100%' }} />}

        {/* If user payment account is not available, but the payment method template is loaded,
        show the option to add a payment */}
        {userPaymentAccount === null && paymentMethodTemplate !== null && <AddPayment paymentMethodTemplate={paymentMethodTemplate} />}

        {Boolean(userPaymentAccount) && <MoneyInput
            label={lang.requestPayoutLabel}
            value={amount}
            maxAmount={maxAmount}
            onValueChanged={setAmount}
            currency={user?.user_currency}
            hasError={(has) => setHasError(has)}
        />}

        <Box display={'flex'} justifyContent={'end'}>
            <Button
                disabled={hasError || amount === ''}
                endIcon={isRequestPayoutLoading ? <CircularProgress size={16} /> : <MonetizationOnOutlinedIcon />}
                variant={'contained'}
                onClick={requestClickHandler}
            >
                {lang.requestBtn}
            </Button>
        </Box>
    </Stack>;
};
