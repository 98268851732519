import React, { FC } from "react";
import { Box, Typography } from "@mui/material";
import { GameButtonFilled } from "../../button/GameButtonFilled";
import { BackButton } from "../../button/BackButton";
import { PaymentMethodCard } from "../../payment_method/PaymentMethodCard";
import { Coin } from "../../coin_pack_cover/Coin";
import { WhiteConvexText } from "../../text/WhiteConvexText";
import { SubtleText } from "../../text/SubtleText";
import { WarningBox } from "../../info_boxes/WarningBox";

interface SecondCoinScreenProps {
    onProceed?: () => void
    hidden?: boolean
    onBack?: () => void
}

export const SecondCoinScreen: FC<SecondCoinScreenProps> = ({onProceed= () => {}, hidden = false, onBack = () => {}}) => {
    return <Box
        maxWidth={'50vh'}
        display={hidden ? 'none' : 'flex'}
        flexDirection={'column'}
        justifyContent={'space-between'}
        rowGap={'2vh'}
        maxHeight={'60vh'}
    >
        <BackButton onBack={onBack}/>
        <Box
            // maxWidth={'55vh'}
            display={'flex'}
            columnGap={'2vh'}
            py={'0.5vh'}
            sx={{
                overflowX: 'auto',
                scrollbarWidth: 'thin',
                scrollbarColor: 'rgba(255, 255, 255, 0.08) transparent',
            }}
        >
                <PaymentMethodCard paymentMethod={'crypto'}/>
                <PaymentMethodCard paymentMethod={'card'}/>
                <PaymentMethodCard paymentMethod={'cash'}/>
        </Box>

        <Box
            display={'flex'}
            flexDirection={'column'}
            rowGap={'0.8vh'}
        >
            <Typography fontWeight={700} fontSize={'2.8vh'}>
                You will receive:
            </Typography>

            <Box
                display={'flex'}
                columnGap={'2vh'}
                alignItems={'center'}
            >
                <Box
                    display={'flex'}
                    columnGap={'1vh'}
                    alignItems={'center'}
                >
                    <Coin coinType={'real'} heightUniversalPx={24}/>
                    <WhiteConvexText size={'2vh'} shadowSize={3}>
                        1100 GC
                    </WhiteConvexText>
                </Box>

                <SubtleText>
                    plus free
                </SubtleText>

                <Box
                    display={'flex'}
                    columnGap={'1vh'}
                    alignItems={'center'}
                >
                    <Coin coinType={'fun'} heightUniversalPx={24}/>
                    <WhiteConvexText size={'2vh'} shadowSize={3}>
                        110 CS
                    </WhiteConvexText>
                </Box>
            </Box>

            <SubtleText>
                Purchase 'Gold coins' using cryptocurrency or your credit card
            </SubtleText>

            <Typography fontWeight={300} fontSize={'1.6vh'} sx={{textDecoration: 'underline'}} color={'#7061C7'} className={'cursor-pointer'}>
                <Box
                    display={'flex'}
                    columnGap={'2vh'}
                >
                    <span>Privacy Policy</span>
                    <span>Terms of Use</span>
                    <span>How it works</span>
                </Box>

            </Typography>
        </Box>

        <WarningBox>
            Card is currently disabled and will be back online shortly
        </WarningBox>

        <GameButtonFilled onClick={() => onProceed()}>
            Proceed with order
        </GameButtonFilled>
    </Box>
}