import { Box } from "@mui/material";
import { GameButtonBase } from "./GameButtonBase";
import { FC } from "react";

interface ButtonWithIconProps {
    iconURL: string
    iconAlt: string
    green?: boolean
    onClick?: () => void
}

export const ButtonWithIcon: FC<ButtonWithIconProps> = ({ iconURL, iconAlt, green = false, onClick = () => {} }) => {
    return <GameButtonBase green={green} onClick={onClick}>
        <Box
            height={'100%'}
            width={'100%'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            className={'pointer'}
        >
            <Box height={'6vh'} width={'6vh'}>
                <img height={'100%'} alt={iconAlt} src={iconURL}/>
            </Box>
        </Box>
    </GameButtonBase>
}