import { FC, useState } from "react";
import { FirstCoinScreen } from "./FirstCoinScreen";
import { SecondCoinScreen } from "./SecondCoinsScreen";
import { ThirdCoinsScreen } from "./ThirdCoinsScreen";

export const CoinsSection: FC = () => {
    const [activeScreenIndex, setActiveScreenIndex] = useState<number>(0);

    return <>
        <FirstCoinScreen hidden={activeScreenIndex !== 0} onProceed={() => setActiveScreenIndex(1)}/>
        <SecondCoinScreen hidden={activeScreenIndex !== 1} onBack={() => setActiveScreenIndex(0)} onProceed={() => setActiveScreenIndex(2)}/>
        <ThirdCoinsScreen hidden={activeScreenIndex !== 2} onBack={() => setActiveScreenIndex(1)} onProceed={() => setActiveScreenIndex(0)}/>
    </>
}