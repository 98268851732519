import Box from "@mui/material/Box"
import { FC } from "react";
import JadeSweepLogo from "../../../../assets/sweep/jade-sweep-logo.png"

export const JadeSweepDialogLogo: FC = () => {
    return <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'end'}
        overflow={'visible'}
        height={'20px'}
        zIndex={100}
    >
        <Box
            mb={'-7vh'}
            height={'30vh'}
            width={'100%'}
            minWidth={'40vh'}
            sx={{
                backgroundImage: `url(${JadeSweepLogo})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center bottom',
            }}
        />
    </Box>
}