import { FC } from "react";
import { Box } from "@mui/material";

interface PictureBoxHolderProps {
    picUrl: string
    size?: number
    heightUniversalPx: number
    round?: boolean
}

export const PictureBoxHolder: FC<PictureBoxHolderProps> = ({picUrl, size = 5, heightUniversalPx, round = false}) => {
    const baseSize = 48 / size

    return <Box
        borderRadius={round ? `${(heightUniversalPx / 2) / baseSize}vh` : 0}
        width={`${(heightUniversalPx) / baseSize}vh`}
        height={`${(heightUniversalPx) / baseSize}vh`}
        sx={{
            pointerEvents: 'none',
            backgroundImage: `url('${picUrl}')`,
            backgroundPosition: 'center',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
        }}
    />
}