import { FC } from "react";
import { Box } from "@mui/material";
import { HexagonButton } from "../button/HexagonButton";

import Bottom from "../../../../assets/sweep/Bot menu.svg";
import RankingIcon from '../../../../assets/sweep/icons/RankingIcon.png'
import AnnounceIcon from '../../../../assets/sweep/icons/AnnounceIcon.png'
import Favourites from '../../../../assets/sweep/icons/Favourites.png'

export const BottomMenu: FC = () => {
    return <Box
        height={'21vh'}
        position={'fixed'}
        left={0}
        right={0}
        bottom={0}
        zIndex={999}

        display={'flex'}
        justifyContent={'center'}
        alignItems={'end'}
    >
        <Box
            width={'76.10vh'}
            height={'5.55vh'}
            // sx={{
            //     background: `#1Eff22`,
            // }}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'end'}
            position={'relative'}
        >
            <Box
                top={'-3.58vh'}
                position={'absolute'}
                height={'7.16vh'}
                display={'flex'}
                columnGap={'1vw'}
            >
                <HexagonButton iconImageUrl={AnnounceIcon}/>
                <HexagonButton iconImageUrl={RankingIcon}/>
                <HexagonButton iconImageUrl={Favourites}/>
            </Box>
            <img width={'100%'} src={Bottom} alt="" style={{marginBottom: '-0.5vh'}}/>
        </Box>
    </Box>
}