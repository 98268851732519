import { useLocation } from "react-router-dom";
import { useTransactions } from "./useTransactions";
import { useEffect } from "react";
import { getNumberParam } from "../common_funcs";

export const useTransactionsWatcher = (disabled: boolean = false) => {
    const location = useLocation()
    const { pageSize, currentPage, getTransactions, transactions } = useTransactions()

    useEffect(() => {
        if (!disabled) {
            const searchParams = new URLSearchParams(location.search)
            const qPage = getNumberParam(searchParams.get('page'))
            const qPageSize = getNumberParam(searchParams.get('pageSize'))

            const pageHasChanges = qPage !== null && qPage !== currentPage
            const pageSizeHasChanges = qPageSize !== null && qPageSize !== pageSize
            const transactionsNotLoadedYet = transactions === null

            if (pageHasChanges || pageSizeHasChanges || transactionsNotLoadedYet) {
                const pageNew = qPage !== null ? qPage : 1
                const newPageSize = qPageSize !== null ? qPageSize : pageSize
                getTransactions(pageNew, newPageSize)
            }
        }
    }, [currentPage, disabled, getTransactions, location.search, pageSize, transactions])
}