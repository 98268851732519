import { FC } from "react";
import { Green3dTitle } from "../text/Green3dTitle";
import { Box } from "@mui/material";
import { GameDialog } from "../game_dialog_windows/GameDialog";
import { GameTextField } from "../game_text_field/GameTextField";
import { useLoginDialogLogic } from "./useLoginDialogLogic";
import { JadeSweepDialogLogo } from "../game_dialog_windows/JadeSweepDialogLogo";
import { GameButtonFilled } from "../button/GameButtonFilled";

interface LoginDialogProps {
    onClose?: () => void
}

export const LoginDialog: FC<LoginDialogProps> = ({onClose = () => {}}) => {
    const { login, setLogin, setPassword, password } = useLoginDialogLogic()

    return <GameDialog onClose={onClose}>
        <Box
            display={'flex'}
            flexDirection={'column'}
            rowGap={'2vh'}
        >
            <JadeSweepDialogLogo/>

            <Green3dTitle size={'30px'}>
                <Box display={'flex'} justifyContent={'center'}>Authorization</Box>
            </Green3dTitle>


            <GameTextField
                value={login}
                onChange={event => setLogin(event.target.value)}
                placeholder={'Enter your email'}
                type={'email'}
            />

            <GameTextField
                value={password}
                onChange={event => setPassword(event.target.value)}
                placeholder={'Enter password'}
                type={'password'}
            />

            <GameButtonFilled disabled={login === '' || password === ''}>
                Login to account
            </GameButtonFilled>
        </Box>
    </GameDialog>
}