import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    typography: {
        fontFamily: 'Gotham, sans-serif'
    },
    palette: {
        mode: 'dark',
        primary: {
            light: '#12a2e5',
            main: '#0794D4',
            dark: '#0e83b7',
            contrastText: '#fff'
        },
    secondary: {
        light: '#494949',
        main: '#333333',
        dark: '#262626',
        contrastText: '#fff'
    },
    },
});