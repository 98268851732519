import { FC } from "react";
import { Box } from "@mui/material";

export type CoinsPackColor = 'orange' | 'purple' | 'cyan' | 'violet' | 'blue';

interface CoinPackBackgroundProps {
    size?: number
    borderRadius?: string
    color?: CoinsPackColor
}

export const CoinPackBackground: FC<CoinPackBackgroundProps> = ({ size = 5, borderRadius , color = 'orange'}) => {
    const baseSize = 48 / size
    const radius = borderRadius ? borderRadius : `${12 / baseSize}vh`

    return <Box
        position={'absolute'}
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={-1}
        borderRadius={radius}
        sx={{
            backgroundImage: getGradient(color)
        }}
    >
        <Box
            border={`${3 / baseSize}vh solid rgba(255, 255, 255, 0.2)`}
            width={'100%'}
            height={'100%'}
            borderRadius={radius}
        />
    </Box>
}

const getGradient = (color: CoinsPackColor) => {
    switch (color) {
        case 'orange':
            return 'linear-gradient(135.00deg, rgb(255, 206, 33),rgb(207, 17, 85) 100%)'
        case 'purple':
            return 'linear-gradient(135.00deg, rgb(237, 33, 255),rgb(17, 25, 207) 100%)'
        case 'cyan':
            return 'linear-gradient(135.00deg, rgb(20, 224, 114),rgb(17, 81, 207) 100%)'
        case 'violet':
            return 'linear-gradient(135.00deg, rgb(33, 201, 255),rgb(146, 17, 207) 100%)'
        case 'blue':
            return 'linear-gradient(135.00deg, rgb(33, 95, 255),rgb(17, 70, 207) 100%)'
    }
}