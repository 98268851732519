import { FC, useState } from "react";
import { PaymentCardBackground } from "./PaymentCardBackground";
import { PaymentCardContainer } from "./PaymentCardContainer";
import { Box } from "@mui/material";
import { GameCheckBox } from "../common_controls/checkbox/GameCheckBox";
import { PaymentMethodIcon } from "./PaymentMethodIcon";
import { WhiteConvexText } from "../text/WhiteConvexText";

interface PaymentMethodCardProps {
    size?: number
    paymentMethod: 'card' | 'crypto' | 'cash'
}

export const PaymentMethodCard: FC<PaymentMethodCardProps> = ({size = 5, paymentMethod}) => {
    const baseSize = 48 / size

    const [isSelected, setIsSelected] = useState<boolean>(false)

    return <PaymentCardContainer>
        <PaymentCardBackground/>
        <Box position={'absolute'} left={`${10 / baseSize}vh`} bottom={`${10 / baseSize}vh`}>
            <GameCheckBox isChecked={isSelected} onStateChanged={setIsSelected} size={size}/>
        </Box>

        <Box
            width={'100%'}
            height={'100%'}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'space-evenly'}
            alignItems={'center'}
        >
            <PaymentMethodIcon iconVariant={paymentMethod} size={size}/>

            <WhiteConvexText size={`${22 / baseSize}vh`} textTransform={'initial'} lineHeight={1.2}>
                {(() => {
                    switch (paymentMethod) {
                        case 'card': return 'Card'
                        case 'crypto': return 'Crypto'
                        case 'cash': return 'Cash'
                    }
                })()}
            </WhiteConvexText>
        </Box>
    </PaymentCardContainer>
}