import { FC } from "react";
import { Box } from "@mui/material";
import { GameCardBase } from "../game_card/GameCardBase";
import TempGamePic0 from "../../../../assets/sweep/gamePictures/295b18158366921 1.png";
import Grid from "@mui/material/Unstable_Grid2";
import TempGamePic from "../../../../assets/sweep/gamePictures/image 18.jpg";
import TempGamePic2 from "../../../../assets/sweep/gamePictures/image 20.jpg";
import TempGamePic3 from "../../../../assets/sweep/gamePictures/image 19.jpg";
import TempGamePic4 from "../../../../assets/sweep/gamePictures/image 21.jpg";

export const GamesGrid: FC = () => {
    return <Box display={'flex'} width={'100%'} columnGap={'2vh'}>
        <Box
            flex={1}
            height={'56vh'}>
            <GameCardBase imageUrl={TempGamePic0} green/>
        </Box>
        <Grid container spacing={'2vh'} width={'100%'} flex={2}>
            <Grid xs={6} height={'29vh'}>
                <Box
                    height={'100%'}>
                    <GameCardBase imageUrl={TempGamePic}/>
                </Box>
            </Grid>
            <Grid xs={6} height={'29vh'}>
                <Box
                    height={'100%'}>
                    <GameCardBase imageUrl={TempGamePic2}/>
                </Box>
            </Grid>
            <Grid xs={6} height={'29vh'}>
                <Box
                    height={'100%'}>
                    <GameCardBase imageUrl={TempGamePic3}/>
                </Box>
            </Grid>
            <Grid xs={6} height={'29vh'}>
                <Box
                    height={'100%'}>
                    <GameCardBase imageUrl={TempGamePic4}/>
                </Box>
            </Grid>
        </Grid>
    </Box>
}