import { FC, useEffect, useState } from "react";
import { Avatar, Box, LinearProgress, Paper, Stack } from "@mui/material";
import { clearPaymentSystems } from "../../../features/paymentSystems/paymentSystemsSlice";
import { useAppDispatch } from "../../../store";
import { usePaymentSystems } from "../../../features/paymentSystems/usePaymentSystems";
import PaymentsIcon from "@mui/icons-material/Payments";
import Typography from "@mui/material/Typography";
import { RequestPayoutDialog } from "./request_payout/RequestPayoutDialog";
import { PaymentAccount } from "../../../features/paymentSystems/PaymentSystemModels";
import { DashboardContentBox } from "../../../components/dashboard/DashboardContentBox";
import { CenteredList } from "../../../components/dashboard/CenteredList";

export const WithdrawMoney: FC = () => {
    const dispatch = useAppDispatch()
    const { userPayoutAccounts, isPayoutAccountsLoading, getPayoutAccounts } = usePaymentSystems()
    const [ initLoad, setInitLoad ] = useState<boolean>(true)
    const [showRequestPayout, setShowRequestPayout] = useState<PaymentAccount|null>(null)
    
    useEffect(() => {
        if (initLoad) {
            getPayoutAccounts()
            setInitLoad(false)
        }
    }, [getPayoutAccounts, initLoad])

    useEffect(() => {
        return () => {
            dispatch(clearPaymentSystems())
        }
    }, [ dispatch ])

    return <>
        <DashboardContentBox>
            <CenteredList>
                {isPayoutAccountsLoading && <LinearProgress sx={{ width: '100%' }}/>}
                {userPayoutAccounts?.map(payoutAccount => <Paper
                    onClick={() => setShowRequestPayout(payoutAccount)}
                    key={`payout-method-id-${payoutAccount.id}`} sx={{ width: '100%' }}>
                    <Box padding={'10px'} sx={{ cursor: 'pointer' }}>
                        <Stack direction={'row'} alignItems={'center'} columnGap={'18px'}>
                            <Avatar
                                alt={`${payoutAccount.method_name} payment method icon`}
                                src={payoutAccount.pic_url}
                                // src={config.hostDomain + payoutAccount.pic_url}
                                sx={{ width: 52, height: 52 }}
                            >
                                <PaymentsIcon fontSize={'small'}/>
                            </Avatar>
                            <Typography fontSize={18} fontWeight={'500'}>
                                {payoutAccount.method_name}
                            </Typography>
                        </Stack>
                    </Box>
                </Paper>)}
            </CenteredList>
        </DashboardContentBox>
        {showRequestPayout && <RequestPayoutDialog cashierPaymentAccount={showRequestPayout} close={() => setShowRequestPayout(null)}/>}
    </>
}