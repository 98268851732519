import { FC, ReactNode } from "react";
import { Box } from "@mui/material";
import { backgroundGradient as blueBackground, contourColor as blueContourColor, getBlueContainerShadows, getBlueInnerContainerShadows } from "./blueButtonStyle";
import {
    backgroundGradient as greenBackground,
    shadedBackgroundGradient as greenShadedBackground,
    contourColor as greenContourColor,
    getGreenContainerShadows,
    getGreenInnerContainerShadows
} from "./greenButtonStyle";

interface GameButtonBaseProps {
    size?: number
    children?: ReactNode
    green?: boolean
    onClick?: () => void
    width?: string
    shaded?: boolean
}

// 095DBF
// 3EAAFE

export const GameButtonBase: FC<GameButtonBaseProps> = ({ children , size = 5, green = false, onClick = () => {}, width, shaded= false}) => {
    const baseSize = 48 / size

    return <Box
        onClick={onClick}
            sx={{
                backgroundColor: !green ? blueContourColor : greenContourColor,
            }}
            position={'relative'}
            borderRadius={`${12 / baseSize}vh`}
            height={`${52 / baseSize}vh`}
            width={width ?? `${52 / baseSize}vh`}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            overflow={'hidden'}
            boxShadow={!green ? getBlueContainerShadows(size) : getGreenContainerShadows(size)}
            mt={`-${10 / baseSize}vh`}
        >
            <Box
                position={'absolute'}
                sx={{ background: !green ? blueBackground : (shaded ? greenShadedBackground : greenBackground) }}
                top={`${6 / baseSize}vh`}
                bottom={`${6 / baseSize}vh`}
                left={`${6 / baseSize}vh`}
                right={`${6 / baseSize}vh`}
                borderRadius={`${7 / baseSize}vh`}
                boxShadow={!green ? getBlueInnerContainerShadows(size) : getGreenInnerContainerShadows(size)}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                overflow={'hidden'}
            >
                {children}
            </Box>
        </Box>
}