import React, { FC, useState } from "react";
import { GameDialog } from "../../game_dialog_windows/GameDialog";
import { GameDialogContent } from "../../game_dialog_windows/GameDialogContent";
import { WhiteShadowedText } from "../../text/WhiteShadowedText";
import { Box } from "@mui/material";
import { NotificationItem, NotificationObj } from "./NotificationItem";
import { BackButton } from "../../button/BackButton";

interface ReadNotificationsProps {
    onClose?: () => void
}

export const ReadNotifications: FC<ReadNotificationsProps> = ({ onClose = () => {} }) => {
    const [openedMessage, setOpenedMessage] = useState<NotificationObj | null>(null)

    return <GameDialog onClose={onClose}>
        <GameDialogContent>
            <WhiteShadowedText>
                Notifications
            </WhiteShadowedText>

            <Box
                maxWidth={'60vh'}
                display={!openedMessage ? 'flex' : 'none'}
                flexDirection={'column'}
                rowGap={'2vh'}
                flexGrow={1}
                maxHeight={'60vh'}
                sx={{
                    overflowY: 'auto',
                    scrollbarWidth: 'thin',
                    scrollbarColor: 'rgba(255, 255, 255, 0) transparent',
                    '&:hover': {
                        scrollbarColor: 'rgba(255, 255, 255, 0.1) transparent',
                    }
                }}
            >
                {notifications.map((notification, index) => (
                    <NotificationItem
                        key={index}
                        notification={notification}
                        onClickMore={setOpenedMessage}
                    />
                ))}
            </Box>

            {openedMessage && <Box
                maxWidth={'60vh'}
                display={'flex'}
                flexDirection={'column'}
                rowGap={'2vh'}
                flexGrow={1}
                maxHeight={'60vh'}
                sx={{
                    overflowY: 'auto',
                    scrollbarWidth: 'thin',
                    scrollbarColor: 'rgba(255, 255, 255, 0) transparent',
                    '&:hover': {
                        scrollbarColor: 'rgba(255, 255, 255, 0.1) transparent',
                    }
                }}
            >
                <BackButton onBack={() => setOpenedMessage(null)} text={'Back to Notifications'}/>
                <NotificationItem notification={openedMessage} fullMode/>
            </Box>}
        </GameDialogContent>
    </GameDialog>
}

const notifications: NotificationObj[] = [
    {
        title: "New Transaction",
        shortMessage: "You received a $100 bonus. Use it to explore our latest games and win big today!",
        fullMessage: "Congratulations! You have received a $100 bonus. Check your account to start playing. Make the most of it by trying out our newest games and features!",
        read: false,
        type: "finance"
    },
    {
        title: "System Update",
        shortMessage: "Scheduled maintenance update tomorrow from 12:00 PM to 2:00 PM. Some features might be temporarily unavailable.",
        fullMessage: "Dear users, there will be a scheduled maintenance update tomorrow from 12:00 PM to 2:00 PM. Short disruptions may occur. We apologize for any inconvenience and appreciate your understanding.",
        read: false,
        type: "info"
    },
    {
        title: "New Transaction",
        shortMessage: "Your $50 deposit was successful and is now available in your account. Enjoy your gaming experience!",
        fullMessage: "Your deposit of $50 was successful and has been added to your account. Happy gaming! Dive into your favorite games and make the most of your deposit.",
        read: true,
        type: "finance"
    },
    {
        title: "Promotion!",
        shortMessage: "Get up to 50% bonus on all deposits until the end of the month. Don't miss out on this amazing offer!",
        fullMessage: "Celebrate our anniversary with up to 50% bonus on all deposits. The promotion lasts until the end of the month. Maximize your deposits and enhance your gaming experience with extra funds.",
        read: false,
        type: "info"
    },
    {
        title: "Reminder",
        shortMessage: "Don't forget to claim your daily bonus. Log in every day to earn free rewards and boost your balance!",
        fullMessage: "Remember to log in and claim your daily bonus. Don't miss out on free rewards! Each day you log in, you get more chances to win big.",
        read: true,
        type: "info"
    },
    {
        title: "Security Alert",
        shortMessage: "Suspicious activity detected on your account. Please change your password immediately and review recent transactions for any discrepancies.",
        fullMessage: "We detected suspicious activity on your account. Please change your password and review recent transactions. Ensuring your account's security is our top priority.",
        read: false,
        type: "info"
    },
    {
        title: "New Transaction",
        shortMessage: "A refund of $25 has been processed to your account. Check your balance for confirmation and continue enjoying our games.",
        fullMessage: "A refund of $25 has been processed to your account. Please check your balance for confirmation. We're here to ensure you have a smooth gaming experience.",
        read: true,
        type: "finance"
    },
    {
        title: "Company News",
        shortMessage: "We are excited to announce a new partnership with ABC Games. Look forward to exciting new games and features coming soon!",
        fullMessage: "We are excited to announce a new partnership with ABC Games. Expect exciting new games soon! Stay tuned for more updates and enjoy a richer gaming experience.",
        read: false,
        type: "info"
    },
    {
        title: "Payment Confirmation",
        shortMessage: "Your payment of $75 is currently pending confirmation. We will notify you once the process is complete.",
        fullMessage: "Your payment of $75 is pending confirmation. Please check back later for status updates. We will notify you as soon as the payment is confirmed.",
        read: true,
        type: "finance"
    },
    {
        title: "New Transaction",
        shortMessage: "You have successfully sent a payment of $200. Check the recipient's balance for confirmation and enjoy your winnings!",
        fullMessage: "You have successfully sent a payment of $200. Check the recipient's balance for confirmation. Thank you for using our services, and we wish you the best of luck!",
        read: false,
        type: "finance"
    }
];
