import React, { FC, useEffect, useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import s from './UserInfoEditor.module.css';
import { Button, CircularProgress } from "@mui/material";
import { useUser } from "../../../features/user/useUser";
import { User } from "../../../features/user/user";
import { validateEmail } from "../../../features/common_funcs";
import { useLanguage } from "../../../features/localisation/useLanguage";

export const UserInfoEditor: FC = () => {
    const { languagePack: { pack: { userInfoForm } } } = useLanguage()
    const { user, updateUserInfo, changePassword, isUserInfoUpdateLoading, isChangePasswordLoading } = useUser()

    const [ emailError, setEmailError ] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [newPasswordError, setNewPasswordError] = useState('');
    const [retypeNewPasswordError, setRetypeNewPasswordError] = useState('');

    const [ email, setEmail ] = useState('');
    const [ phone, setPhone ] = useState('');
    const [ firstname, setFirstname ] = useState('');
    const [ lastname, setLastname ] = useState('');

    const [ currentPassword, setCurrentPassword ] = useState<string|undefined>();
    const [ newPassword, setNewPassword ] = useState<string|undefined>();
    const [ retypeNewPassword, setRetypeNewPassword ] = useState<string|undefined>();

    useEffect(() => {
        if (user && (user !== prevUser.current)) {
            setEmail(user.user_email || '');
            setPhone(user.user_phone || '');
            setFirstname(user.user_firstname || '');
            setLastname(user.user_lastname || '');

            prevUser.current = user;
        }
    }, [ user ]);

    useEffect(() => {
        if ((currentPassword !== undefined && currentPassword.trim() === '')  || (currentPassword === undefined && (retypeNewPassword || newPassword))) {
            setPasswordError(userInfoForm.emptyFieldWarningMessage)
        } else setPasswordError('')

        if ((newPassword !== undefined && newPassword.trim() === '') || (newPassword === undefined && (currentPassword || retypeNewPassword))) {
            setNewPasswordError(userInfoForm.emptyFieldWarningMessage)
        } else {
            setNewPasswordError('')
        }

        if (((newPassword || currentPassword) && retypeNewPassword === undefined) || (retypeNewPassword !== undefined && retypeNewPassword.trim() === '')) {
            setRetypeNewPasswordError(userInfoForm.emptyFieldWarningMessage)
        } else if (newPassword && retypeNewPassword && newPassword !== retypeNewPassword) {
            setRetypeNewPasswordError(userInfoForm.passNotMatchWarningMessage)
        } else {
            setRetypeNewPasswordError('')
        }
    }, [currentPassword, newPassword, retypeNewPassword, userInfoForm.emptyFieldWarningMessage, userInfoForm.passNotMatchWarningMessage])

    const prevUser = useRef<User | null>(null);

    const handleSaveInfo = () => {
        if (user) {
            const updatedUser: User = {
                ...user,
                user_email: email,
                user_phone: phone,
                user_firstname: firstname,
                user_lastname: lastname
            }
            updateUserInfo(updatedUser)
        }
    };

    const handleUpdatePassword = () => {
        if (Boolean(currentPassword) && Boolean(newPassword) && Boolean(retypeNewPassword)) {
            changePassword(currentPassword as string, newPassword as string, () => {
                setCurrentPassword(undefined)
                setNewPassword(undefined)
                setRetypeNewPassword(undefined)
            })
        }
    };

    return (
        <div className={s.container}>
            <div className={s.fieldsSection}>
                <TextField
                    disabled
                    label={userInfoForm.usernameLabel}
                    type={'text'}
                    value={user?.user_name ?? ''}
                />
                <TextField
                    disabled={isUserInfoUpdateLoading}
                    label={userInfoForm.emailFieldLabel}
                    type={'email'}
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value)
                        const emailValidationResult = validateEmail(e.target.value, userInfoForm.emailIsRequiredMess, userInfoForm.notValidEmail);
                        setEmailError(emailValidationResult);
                    }}
                    error={Boolean(emailError)}
                    helperText={emailError}
                />
                <TextField
                    disabled={isUserInfoUpdateLoading}
                    label={userInfoForm.phoneFieldLabel}
                    type={'tel'}
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                />
                <TextField
                    disabled={isUserInfoUpdateLoading}
                    label={userInfoForm.firstnameFieldLabel}
                    value={firstname}
                    onChange={(e) => setFirstname(e.target.value)}
                />
                <TextField
                    disabled={isUserInfoUpdateLoading}
                    label={userInfoForm.lastnameFieldLabel}
                    value={lastname}
                    onChange={(e) => setLastname(e.target.value)}
                />
                <Button endIcon={isUserInfoUpdateLoading ? <CircularProgress size={16}/> : <></>}
                        disabled={Boolean(emailError) || isUserInfoUpdateLoading} className={s.button}
                        variant={'contained'} onClick={handleSaveInfo}>
                    {userInfoForm.saveInfoBTN}
                </Button>
            </div>
            <div className={s.fieldsSection}>
                <TextField
                    disabled={isChangePasswordLoading}
                    label={userInfoForm.currentPassFieldLabel}
                    type={'password'}
                    value={Boolean(currentPassword) ? currentPassword : ''}
                    error={Boolean(passwordError)}
                    helperText={passwordError}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                />
                <TextField
                    disabled={isChangePasswordLoading}
                    label={userInfoForm.newPassFieldLabel}
                    type={'password'}
                    value={Boolean(newPassword) ? newPassword : ''}
                    error={Boolean(newPasswordError)}
                    helperText={newPasswordError}
                    onChange={(e) => setNewPassword(e.target.value)}
                />
                <TextField
                    disabled={isChangePasswordLoading}
                    label={userInfoForm.retypePassFieldLabel}
                    type={'password'}
                    value={Boolean(retypeNewPassword) ? retypeNewPassword : ''}
                    error={Boolean(retypeNewPasswordError)}
                    helperText={retypeNewPasswordError}
                    onChange={(e) => setRetypeNewPassword(e.target.value)}
                />
                <Button disabled={isChangePasswordLoading || (!Boolean(currentPassword) || !Boolean(newPassword) || !Boolean(retypeNewPassword) || Boolean(passwordError) || Boolean(newPasswordError) || Boolean(retypeNewPasswordError))}
                        endIcon={isChangePasswordLoading ? <CircularProgress size={16}/> : <></>}
                        className={s.button} variant={'contained'} onClick={handleUpdatePassword}>
                    {userInfoForm.updatePassBTN}
                </Button>
            </div>
        </div>
    );
};
