import { FC } from "react";
import { Box } from "@mui/material";
import { CoinsPackContainer } from "./CoinsPackContainer";
import { CoinPackBackground, CoinsPackColor } from "./CoinPackBackground";
import { DigitsField } from "../game_text_field/digits_field/DigitsField";
import { CoinsPackPrice } from "./CoinsPackPrice";
import { Coin } from "./Coin";
import { WhiteConvexText } from "../text/WhiteConvexText";

interface CoinPackCoverProps {
    hasRealCoins?: boolean
    hasFunCoins: boolean
    title: string
    size?: number
    color?: CoinsPackColor
    price: string
}

export const CoinPackCover: FC<CoinPackCoverProps> = ({hasRealCoins = false, hasFunCoins, title, size=5, color = 'orange', price}) => {
    const baseSize = 48 / size
    const borderRadius = `${12 / baseSize}vh`

    return <CoinsPackContainer>
        <CoinPackBackground color={color} borderRadius={borderRadius} size={size}/>

        <Box
            position={'absolute'}
            left={0}
            right={0}
            top={`-${12 / baseSize}vh`}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            columnGap={`${15 / baseSize}vh`}
        >
            {hasRealCoins && <Coin coinType={'real'} size={size} heightUniversalPx={80}/>}
            {hasFunCoins && <Coin coinType={'fun'} size={size} heightUniversalPx={80}/>}
        </Box>

        <Box
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            height={'100%'}
        >
            <WhiteConvexText size={`${24 / baseSize}vh`}>
                {title}
            </WhiteConvexText>
        </Box>

        <Box
            position={'absolute'}
            left={0}
            bottom={0}
            padding={'1vh'}
        >
            <DigitsField size={size}/>
        </Box>

        <Box
            position={'absolute'}
            right={0}
            bottom={0}
        >
            <CoinsPackPrice color={color} borderRadius={borderRadius} size={size} price={price}/>
        </Box>
    </CoinsPackContainer>
}