import { FC } from "react";
import { Box } from "@mui/material";

interface NegativeBoardProps {
    size?: number
}

export const NegativeBoard: FC<NegativeBoardProps> = ({size = 5}) => {
    const baseSize = 48 / size

    return <Box
        position={'absolute'}
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={-1}
        border={`${2 / baseSize}vh solid #3E29B7`}
        borderRadius={`${9 / baseSize}vh`}
        sx={{
            background: 'rgba(22, 9, 67, 0.45)',
        }}
        padding={`${4 / baseSize}vh`}
    >
        <Box
            height={'100%'}
            width={'100%'}
            borderRadius={`${6 / baseSize}vh`}
            sx={{
                background: 'rgba(23, 12, 70, 0.5)',
            }}
        />
    </Box>
}