import { FC} from "react";
import { RightVerticalButton } from "../../components/sweepstake_layout/main_layout/RightVerticalButton";
import { MainLayout } from "../../components/sweepstake_layout/main_layout/MainLayout";
import { GamesGrid } from "../../components/sweepstake_layout/main_layout/GamesGrid";
import '../../../fonts/fonts.css';
import { ContentCenter } from "../../components/sweepstake_layout/main_layout/ContentCenter";
import { AfterContentPanel } from "../../components/sweepstake_layout/main_layout/AfterContentPanel";
import { useCommonMediaqueries } from "../../features/mediaQueries/useCommonMediaqueries";
import { GamesFlex } from "../../components/sweepstake_layout/main_layout/GamesFlex";

export const HomePage: FC = () => {
    const {portraitMode} = useCommonMediaqueries()

    return <MainLayout>
        <ContentCenter>
            {portraitMode ? <GamesFlex/> : <GamesGrid/>}
            <AfterContentPanel>
                <RightVerticalButton/>
            </AfterContentPanel>
        </ContentCenter>


    </MainLayout>
}