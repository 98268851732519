import React from "react";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ReceiptIcon from "@mui/icons-material/Receipt";
import HistoryIcon from "@mui/icons-material/History";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import AddCardIcon from "@mui/icons-material/AddCard";
import CreditCardIcon from "@mui/icons-material/CreditCard";

interface MenuItem {
    iconColor: string
    tabName: string
    title: string
    icon: React.ElementType
}

export const menuList: MenuItem[] = [
    { tabName: 'userInfo', title: 'User Info', icon: ManageAccountsIcon, iconColor: '#f37a84' },
    { tabName: 'transactions', title: 'Transactions', icon: ReceiptIcon, iconColor: '#a4cdf0' },
    { tabName: 'gamesHistory', title: 'Games History', icon: HistoryIcon, iconColor: '#8be788' },
    { tabName: 'paymentAccounts', title: 'Payment accounts', icon: AccountBalanceWalletIcon, iconColor: '#f3ea6c' },
    { tabName: 'depositMoney', title: 'Deposit Money', icon: AddCardIcon, iconColor: '#a573ee' },
    { tabName: 'withDrawMoney', title: 'Withdraw Money', icon: CreditCardIcon, iconColor: '#87fcfb' }
]

const coolColorsList = [
    '#f37a84',
    '#a4cdf0',
    '#8be788',
    '#f3ea6c',
    '#a573ee',
    '#87fcfb',
    '#05ef9c',
    '#f87fde',
    '#feba6d',
    '#488af6'
]