import { FC } from "react";

export const HexagonShape: FC = () => {
    return <svg width="100%" height="100%" viewBox="0 0 70 78" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_i_2061_1300)">
            <path d="M38 3.04145L64.641 18.4226C66.4974 19.4944 67.641 21.4752 67.641 23.6188V54.3812C67.641 56.5248 66.4974 58.5056 64.641 59.5774L38 74.9585C36.1436 76.0303 33.8564 76.0303 32 74.9585L5.35898 59.5774C3.50258 58.5056 2.35898 56.5248 2.35898 54.3812V23.6188C2.35898 21.4752 3.50258 19.4944 5.35898 18.4226L32 3.04145C33.8564 1.96966 36.1436 1.96966 38 3.04145Z" stroke="#79CC49" strokeWidth="4"/>
        </g>
        <defs>
            <filter id="filter0_i_2061_1300" x="0.358887" y="0.237549" width="69.2822" height="77.5249" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="2"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.566204 0 0 0 0 0.933333 0 0 0 0 0.353889 0 0 0 1 0"/>
                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2061_1300"/>
            </filter>
        </defs>
    </svg>
}