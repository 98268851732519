import { FC } from "react";

export const HexagonShapeFilled: FC = () => {
    return <svg width="100%" height="100%" viewBox="0 0 70 78" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M31 1.3094C33.4752 -0.119661 36.5248 -0.119662 39 1.3094L65.641 16.6906C68.1162 18.1197 69.641 20.7607 69.641 23.6188V54.3812C69.641 57.2393 68.1162 59.8803 65.641 61.3094L39 76.6906C36.5248 78.1197 33.4752 78.1197 31 76.6906L4.35898 61.3094C1.88377 59.8803 0.358982 57.2393 0.358982 54.3812V23.6188C0.358982 20.7607 1.88377 18.1197 4.35898 16.6906L31 1.3094Z" fill="url(#paint0_linear_2061_1299)"/>
        <defs>
            <linearGradient id="paint0_linear_2061_1299" x1="35" y1="79" x2="35" y2="-27.7606" gradientUnits="userSpaceOnUse">
                <stop stopColor="#26177B"/>
                <stop offset="1" stopColor="#331CAF"/>
            </linearGradient>
        </defs>
    </svg>
}