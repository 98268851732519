import UserStorage from "../../localStorage/userStorage";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { User } from "./user";


interface UserState {
    token: string | null
    user: User | null
    isLogInLoading: boolean
    isUserInfoUpdateLoading: boolean
    isChangePasswordLoading: boolean
}

const initialState: UserState = {
    token: UserStorage.getToken(),
    user: UserStorage.getUser(),
    isLogInLoading: false,
    isUserInfoUpdateLoading: false,
    isChangePasswordLoading: false
}

export const UserSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setToken: (state, action: PayloadAction<string>) => {
            state.token = action.payload
            UserStorage.setToken(action.payload)
        },
        setUser: (state, action: PayloadAction<User>) => {
            state.user = action.payload
            UserStorage.setUser(action.payload)
        },
        clearToken: (state) => {
            state.token = null
            UserStorage.removeToken()
        },
        updateUserBalance: (state, action: PayloadAction<number>) => {
            if (state.user) {
                state.user = {
                    ...state.user,
                    user_balance: action.payload
                }
                UserStorage.setUser({
                    ...state.user,
                    user_balance: action.payload
                })
            }

        },
        clearUser: (state) => {
            state.user = null
            UserStorage.removeUser()
        },
        setLogInLoading: (state, action: PayloadAction<boolean>) => {
            state.isLogInLoading = action.payload
        },
        setUserInfoUpdateLoading: (state, action: PayloadAction<boolean>) => {
            state.isUserInfoUpdateLoading = action.payload
        },
        setChangePasswordLoading: (state, action: PayloadAction<boolean>) => {
            state.isChangePasswordLoading = action.payload
        }
    }
})

export default UserSlice.reducer

export const { setToken, setUser, clearToken, clearUser, setLogInLoading, setUserInfoUpdateLoading, setChangePasswordLoading, updateUserBalance } = UserSlice.actions

export const selectToken = (state: RootState) => state.user.token