import { FC, useState } from "react";
import { Box, Drawer, Hidden, Paper } from "@mui/material";
import { TopHeader } from "../../components/play_game_layout/TopHeader";
import IconButton from "@mui/material/IconButton";
import MenuIcon from '@mui/icons-material/Menu';
// import { SideMenu } from "./SideMenu";
import { DashboardContent } from "./DashboardContent";
import { useUser } from "../../features/user/useUser";
import { LoginForm } from "../../components/common/login/LoginForm";
import Typography from "@mui/material/Typography";
import { SideMenu } from "../../components/play_game_layout/side_menu/SideMenu";

export const UserDashboard: FC = () => {
    const [ isDrawerOpen, setDrawerOpen ] = useState(false)
    const { user, token } = useUser()
    const userIsLogged = Boolean(user) && Boolean(token)

    // Function to toggle the state of the drawer (open/close)
    const toggleDrawer = (open: boolean) => (event: KeyboardEvent | MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as KeyboardEvent).key === 'Tab' || (event as KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        setDrawerOpen(open);
    }


    return <Box
        display={'flex'}
        flexDirection={'column'}
    >
        <TopHeader inDashboardMode leftAdornment={
            userIsLogged ? <Hidden mdUp>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={() => setDrawerOpen(!isDrawerOpen)}
                >
                    <MenuIcon/>
                </IconButton>
            </Hidden> : <></>
        }/>
        <Box
            height={'calc(100vh - 50px)'}
            component={'main'}
            width={'100vw'}
            display={'flex'}
        >
            {userIsLogged ? <>
                {/* --------------------------- Logged zone ---------------------------- */}

                <Hidden mdUp>
                <Drawer
                    sx={{
                        top: '50px',
                        '.MuiBackdrop-root': {
                            top: '50px'
                        },
                        '.MuiPaper-root': {
                            top: '50px',
                            backgroundColor: 'var(--dark-bg-color) !important',
                            backgroundImage: 'none'
                        }
                    }}
                    anchor="left"
                    open={isDrawerOpen}
                    onClose={toggleDrawer(false)}
                >
                    <SideMenu close={() => setDrawerOpen(false)} inDrawer/>
                </Drawer>
            </Hidden>

                <Hidden mdDown>
                <SideMenu/>
                </Hidden>

            <Box width={'100%'} height={'100%'} overflow={'auto'}>
                <DashboardContent/>
            </Box>

            </> : <>
                {/* -------------------------- Unauthorized zone ---------------------------- */}

                <Box
                    width={'100%'}
                    height={'100%'}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                >
                    <Paper
                        sx={{
                            padding: '16px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                            rowGap: '14px'
                    }}
                    >
                        <Typography alignSelf={'center'}>
                            Log In
                        </Typography>
                        <LoginForm/>
                    </Paper>
                </Box>
            </>}
        </Box>
    </Box>
}