import EuroIcon from '@mui/icons-material/Euro';
import CurrencyFrancIcon from '@mui/icons-material/CurrencyFranc';
import CurrencyLiraIcon from '@mui/icons-material/CurrencyLira';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import CurrencyYenIcon from '@mui/icons-material/CurrencyYen';
import CurrencyYuanIcon from '@mui/icons-material/CurrencyYuan';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { FC, ReactElement } from "react";

interface CurrencyRenderProps {
    currency: string
}

export const CurrencyRender: FC<CurrencyRenderProps> = ({currency}) => {
    const iconMap: Record<string, ReactElement> = {
        EUR: <EuroIcon fontSize={'small'} />, // European Union Euro
        CHF: <CurrencyFrancIcon fontSize={'small'} />, // Swiss Franc
        TRY: <CurrencyLiraIcon fontSize={'small'} />, // Turkish Lira
        GBP: <CurrencyPoundIcon fontSize={'small'} />, // British Pound Sterling
        RUB: <CurrencyRubleIcon fontSize={'small'} />, // Russian Ruble
        INR: <CurrencyRupeeIcon fontSize={'small'} />, // Indian Rupee
        JPY: <CurrencyYenIcon fontSize={'small'} />, // Japanese Yen
        CNY: <CurrencyYuanIcon fontSize={'small'} />, // Chinese Yuan Renminbi
        USD: <AttachMoneyIcon fontSize={'small'} />, // United States Dollar
    };

    const icon = iconMap[currency.toUpperCase()];

    return icon ? (
        icon
    ) : (
        <>currency</>
    );
}