import { AxiosRequestConfig } from "axios";

const axiosConfig : AxiosRequestConfig<FormData> = {
    headers: {
        "Content-type": `multipart/form-data;`
    },
    responseType: 'json'
}

const Config = {
    localStoragePrefix: 'UniCasino_',
    apiHost: 'https://api.play.unisoft.codes/',
    hostDomain: 'https://play.unisoft.codes',
    axiosConfig
}

export default Config