import { Box, Typography } from "@mui/material";
import { FC, useState } from "react";
import { RoundButton } from "./RoundButton";

interface DigitsFieldProps {
    value?: number
    onValueChanged?: (value: number) => void
    size?: number
}

export const DigitsField: FC<DigitsFieldProps> = ({value, onValueChanged, size = 5}) => {
    const baseSize = 48 / size
    const [localValue, setLocalValue] = useState<number>(0)

    const incrementDecrementHandler = (increment: boolean) => () => {
        if (increment) {
            if (onValueChanged) onValueChanged(localValue + 1)
            setLocalValue(localValue + 1)
        } else {
            if (onValueChanged) onValueChanged(localValue - 1)
            setLocalValue(localValue - 1)
        }
    }

    return <Box
        sx={{
            backgroundColor: 'rgba(0, 0, 0, 0.35)',
        }}
        py={`${1 / baseSize}vh`}
        px={`${4 / baseSize}vh`}
        borderRadius={'222px'}
        border={'2px solid rgba(255, 255, 255, 0.25)'}
        // width={'100%'}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        columnGap={`${14 / baseSize}vh`}
        minWidth={`${120 / baseSize}vh`}
    >
        <RoundButton sign={'-'} size={size} onClick={incrementDecrementHandler(false)} disabled={value !== undefined ? value === 0 : localValue === 0}/>
        <Typography fontSize={`${22 / baseSize}vh`} fontWeight={700}>{value !== undefined ? value : localValue}</Typography>
        <RoundButton sign={'+'} size={size} onClick={incrementDecrementHandler(true)}/>
    </Box>
}