import { Box, Modal } from "@mui/material";
import { FC, ReactNode } from "react";
import { BoardBase } from "../board/BoardBase";
import CloseIcon from '@mui/icons-material/Close';

interface GameDialogProps {
    children?: ReactNode
    onClose?: () => void
}

export const GameDialog: FC<GameDialogProps> = ({ children, onClose = () => {} }) => {
    return <Modal
        sx={{
            backgroundColor: 'rgba(22,9,67, 0.8)',
            backdropFilter: 'blur(1vh)',
        }}
        open
        onClose={() => console.log('close')}
    >
        <Box
            position={'absolute'}
            top={'50%'}
            left={'50%'}
            sx={{
                transform: 'translate(-50%, -50%)',
                outline: 'none'
            }}
            pb={'1vh'}
        >
            <Box position={'relative'}>
                <Box position={'absolute'} top={0} left={0} right={0} bottom={0} zIndex={-1}>
                    <BoardBase width={'100%'} height={'100%'}>
                        &nbsp;
                    </BoardBase>
                </Box>
                <Box
                    height={'7vh'}
                    width={'7vh'}
                    position={'absolute'}
                    top={'0'}
                    right={'0'}
                    onClick={onClose}
                    sx={{
                        cursor: 'pointer',
                    }}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                >
                    <CloseIcon/>
                </Box>
                <Box
                    padding={'6vh 7vh'}
                >
                    {children}
                </Box>
            </Box>
        </Box>
    </Modal>
}