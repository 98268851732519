import { FC, ReactNode } from "react";
import { Typography } from "@mui/material";

interface SubtleTextProps {
    children?: ReactNode
    size?: string
    fontWeight?: number
    textTransform?: 'none' | 'capitalize' | 'uppercase' | 'lowercase' | 'initial' | 'inherit' | 'unset'
    lineHeight?: number
    color?: string
}

export const SubtleText: FC<SubtleTextProps> = ({children, size = '1.8vh', fontWeight = 400, textTransform, lineHeight, color}) => {

    return <Typography
        lineHeight={lineHeight ?? 1.2}
        textAlign={'left'}
        fontWeight={fontWeight}
        textTransform={textTransform ?? 'initial'}
        fontSize={size}
        color={color}
        sx={{
            opacity: '0.4'
        }}
    >
        {children}
    </Typography>
}