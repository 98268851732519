import React, { FC, ReactNode, useState } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { ContainedIconButton } from "../common/controls/ContainedIconButton";
import PersonPinIcon from '@mui/icons-material/PersonPin';
import { ProvidersList } from "../../pages/home_page/providers/ProvidersList";
import { Logo } from "./Logo";
import { Link } from "react-router-dom";
import LanguageSelector from "../common/lang/LanguageSelector";
import { BalanceDisplay } from "../balance/BalanceDisplay";
import { useUser } from "../../features/user/useUser";
import { chooseName } from "../../features/user/userFuncs";
import { LoginDialog } from "../common/login/LoginDialog";
import PriceChangeIcon from '@mui/icons-material/PriceChange';

interface TopHeaderProps {
    inDashboardMode?: boolean
    leftAdornment?: ReactNode | string
}

export const TopHeader: FC<TopHeaderProps> = ({ inDashboardMode = false, leftAdornment }) => {
    const tabletAndLargeScreens = useMediaQuery('(min-width:700px)')
    const { user, token } = useUser()
    const userIsLogged = Boolean(user) && Boolean(token)
    const [ isLoginDialogOpened, setLoginDialogOpened ] = useState<boolean>(false)

    return <Box
        display={'flex'}
        flexDirection={'column'}
        component={'header'}
        sx={{ backgroundColor: 'var(--dark-bg-color)' }}
        width={'100vw'}
    >
        <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            height={'50px'}
            paddingY={'4px'}
            paddingX={'10px'}
        >
            <Box display={'flex'} alignItems={'center'} columnGap={'15px'}>
                {leftAdornment}
                <Logo/>
            </Box>
            <Box
                display={'flex'}
                columnGap={'12px'}
                alignItems={'center'}
            >
                <BalanceDisplay/>
                {tabletAndLargeScreens && <>
                    {userIsLogged && <Typography>
                        {chooseName(user)}
                    </Typography>}
                    <LanguageSelector/>
                </>}

                {!inDashboardMode && <>
                    {userIsLogged ?
                        (tabletAndLargeScreens &&
                            <>
                            <Link to={'/dashboard?tab=depositMoney'}>
                                <ContainedIconButton color={'secondary'}>
                                    <PriceChangeIcon/>
                                </ContainedIconButton>
                            </Link>
                            <Link to={'/dashboard'}>
                                <ContainedIconButton color={'primary'}>
                                    <PersonPinIcon/>
                                </ContainedIconButton>
                            </Link></>)
                        :
                        <ContainedIconButton color={'primary'} onClick={() => setLoginDialogOpened(true)}>
                            <PersonPinIcon/>
                        </ContainedIconButton>
                    }
                </>}
            </Box>
        </Box>

        {!inDashboardMode && <ProvidersList/>}

        {isLoginDialogOpened && <LoginDialog close={() => setLoginDialogOpened(false)}/>}
    </Box>
}