import React, { FC, useEffect, useState } from "react";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Skeleton, Typography } from "@mui/material";
import { useLanguage } from "../../../../features/localisation/useLanguage";
import { PaymentMethodTemplate } from "../../../../features/paymentSystems/PaymentSystemModels";
import { usePaymentSystems } from "../../../../features/paymentSystems/usePaymentSystems";

interface PaymentMethodDropdownProps {
    onChange: (paymentMethod: PaymentMethodTemplate|undefined) => void
}

export const PaymentMethodDropdown: FC<PaymentMethodDropdownProps> = ({ onChange }) => {
    const { languagePack: {pack: {paymentSystems: lang}}} = useLanguage()
    const [ initLoad, setInitLoad ] = useState<boolean>(true)
    const { paymentMethods, isPaymentMethodsLoading, getPaymentMethods } = usePaymentSystems()

    useEffect(() => {
        if (!isPaymentMethodsLoading && initLoad) {
            setInitLoad(false)
            if (paymentMethods === null) {
                getPaymentMethods()
            }
        }
    }, [ getPaymentMethods, initLoad, isPaymentMethodsLoading, paymentMethods ])

    const selectChangeHandler = (event: SelectChangeEvent<number>) => {
        if (paymentMethods !== null) {
            const id = event.target.value
            const method = paymentMethods.find(m => m.id === id)
            if (method) {
                onChange(method)
            }
        }
    }

    return <>
        {isPaymentMethodsLoading ?
            <Skeleton animation={'wave'} height={56}/> :
            (paymentMethods !== null && paymentMethods.length !== 0 ? <FormControl fullWidth>
                <InputLabel id="payment-methods">{lang.paymentMethodsDropdownLabel}</InputLabel>
                <Select
                    labelId="payment-methods"
                    label={lang.paymentMethodsDropdownLabel}
                    onChange={selectChangeHandler}
                    disabled={paymentMethods.length === 0}
                >
                    {paymentMethods.map((paymentMethod) => (
                        <MenuItem key={`payment-method-id-${paymentMethod.id}`} value={paymentMethod.id}>
                            {paymentMethod.method_name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl> :
            <Typography>
                {lang.noAvailablePaymentMethodsMessage}
            </Typography>)
        }
    </>
}