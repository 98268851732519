import { FC, ReactNode } from "react";
import { Box } from "@mui/material";
import { LeftButtonPanel } from "./LeftButtonPanel";
import { WheelButton } from "./WheelButton";

interface ButtonPanelLandscapeProps {
    children?: ReactNode
}

export const ButtonPanelLandscape: FC<ButtonPanelLandscapeProps> = ({ children }) => {
    return <Box
        flex={1}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        rowGap={'4vh'}
        mb={'1vh'}
    >
        <LeftButtonPanel>
            {children}
        </LeftButtonPanel>
        <WheelButton/>
    </Box>
}