import { FC, ReactNode } from "react";
import { Box } from "@mui/material";
import { useCommonMediaqueries } from "../../../features/mediaQueries/useCommonMediaqueries";

interface AfterContentPanelProps {
    children?: ReactNode
}

export const AfterContentPanel: FC<AfterContentPanelProps> = ({children}) => {
    const {portraitMode} = useCommonMediaqueries()

    return portraitMode ? <></> : <Box
        flex={1}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        rowGap={'4vh'}
        mb={'1vh'}
    >
        {children}
    </Box>
}