import { FC, ReactNode, useState } from "react";
import backgroundImage from "../../../../assets/sweep/background.webp";
import { Box } from "@mui/material";
import { TopBar } from "../top_bar/TopBar";
import { ButtonPanel } from "../button_panel/ButtonPanel";
import { ButtonWithIcon } from "../button/ButtonWithIcon";
import Icon1 from "../../../../assets/sweep/icons/1.webp";
import Icon2 from "../../../../assets/sweep/icons/2.webp";
import Icon3 from "../../../../assets/sweep/icons/3.webp";
import AllIcon from "../../../../assets/sweep/icons/AllIcon.webp";
import FullScreen from "react-fullscreen-crossbrowser";
import { WalletWindow } from "../wallet/WalletWindow";

interface MainLayoutPortraitProps {
    children?: ReactNode
}

export const MainLayoutPortrait: FC<MainLayoutPortraitProps> = ({children}) => {
    const [fullscreenEnabled, setFullscreenEnabled] = useState<boolean>(false)
    const [isWalletOpened, setIsWalletOpened] = useState<boolean>(false)

    const toggleFullscreen = () => {
        setFullscreenEnabled(!fullscreenEnabled)
    }

    return <FullScreen enabled={fullscreenEnabled}><Box
        sx={{
            backgroundColor: '#1E1060',
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        }}
        height={'100vh'}
        width={'100vw'}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        borderRadius={'3.5vh'}
        overflow={'hidden'}
    >
        <TopBar/>

        {children}

        <ButtonPanel>
            <ButtonWithIcon iconURL={Icon1} iconAlt={'Settings icon'}/>
            <ButtonWithIcon iconURL={Icon2} iconAlt={'Settings icon'}/>
            <ButtonWithIcon iconURL={Icon3} iconAlt={'Settings icon'} onClick={() => setIsWalletOpened(true)}/>
            <Box height={0}/>
            <ButtonWithIcon
                iconURL={AllIcon}
                iconAlt={'Settings icon'}
                green
                onClick={toggleFullscreen}
            />
        </ButtonPanel>

        {isWalletOpened && <WalletWindow onClose={() => setIsWalletOpened(false)}/>}
    </Box></FullScreen>
}