import React, { FC } from "react";
import { FormControl, MenuItem, Select, SelectChangeEvent, Stack } from "@mui/material";
import { PictureBoxHolder } from "../common_components/PictureBoxHolder";

export interface CryptoHolder {
    iconUrl: string
    name: string
}

interface CryptoDropdownWithIconsProps {
    value?: CryptoHolder
    setValue: (value: CryptoHolder) => void
    list: CryptoHolder[]
}

export const CryptoDropdownWithIcons: FC<CryptoDropdownWithIconsProps> = ({value, setValue, list}) => {
    const handleChange = (event: SelectChangeEvent) => {
        const newValue = list.find(item => item.name === event.target.value);
        if (newValue) {
            setValue(newValue);
        }
    };

    return <FormControl
        sx={{
            "& .MuiInputBase-root": {
                borderRadius: '12px',
                backgroundColor: 'rgba(110,71,255,0.45)',
                '& fieldset': {
                    borderColor: '#3E29B7', // изменить цвет обводки
                    borderRadius: '12px',
                    borderWidth: '2px',
                    borderBoxSize: 'content-box',
                    boxShadow: 'none'
                },
            }
        }}
    ><Select
        value={value?.name ?? ''}
        onChange={handleChange}
        defaultValue={''}
    >
        {list.map((item) => <MenuItem value={item.name} key={item.name}>
            <Stack direction={'row'} columnGap={1} alignItems={'center'}>
                <PictureBoxHolder picUrl={item.iconUrl} heightUniversalPx={24} round/>
                {item.name}
            </Stack>

        </MenuItem>)}
    </Select>
    </FormControl>
}