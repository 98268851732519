import { Typography } from "@mui/material";
import { FC, ReactNode } from "react";

interface Green3dTitleProps {
    children?: ReactNode
    size?: string
    shadowSize?: number
    fontWeight?: number
    glowing?: boolean
    withShadows?: boolean
}

export const Green3dTitle: FC<Green3dTitleProps> = ({ children, size= '3.2vh', shadowSize = 5, fontWeight = 900, glowing = true, withShadows = true }) => {
    const baseSize = 48 / shadowSize

    const shadowsList: string[] = [
        `0 ${2 / baseSize}vh 0 #325A25`,
        `0 ${3 / baseSize}vh 0 #3B7627`
    ]
        .concat(withShadows ? [
            `0 ${8 / baseSize}vh ${6 / baseSize}vh rgba(7, 37, 13, 0.62)`,
            `0 -${6 / baseSize}vh ${12 / baseSize}vh rgba(7, 37, 13, 0.62)`,
        ] : [])
        .concat(glowing ? [
        `0 -${4 / baseSize}vh ${12 / baseSize}vh rgba(48, 181, 1, 0.65)`,
        `0 -${4 / baseSize}vh ${24 / baseSize}vh #0062D6`,
    ] : [])

    const shadows = shadowsList.map(shadow => `drop-shadow(${shadow})`).join(' ')

    return <Typography
        lineHeight={1}
        textAlign={'center'}
        fontWeight={fontWeight}
        textTransform={'uppercase'}
        sx={{
            background: 'linear-gradient(to top, #44B444, #EAF944)',
            "-webkit-background-clip": "text",
            color: 'transparent',
            filter: shadows,
            userSelect: 'none',
        }}
        fontSize={size}
        color={'#00FF00'}
    >
        {children}
    </Typography>
}