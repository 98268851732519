import { FC } from "react";
import { Box } from "@mui/material";
import { GameButtonBase } from "../button/GameButtonBase";
import { SmallButtonBase } from "../button/SmallButtonBase";

import Ava from '../../../../assets/sweep/Ava.png'
import EditIcon from '../../../../assets/sweep/icons/Edit.png'

export const AvatarButton: FC = () => {
    return <Box
        className={'pointer'}
        position={'relative'}
    >
        <GameButtonBase>
            <Box height={'100%'}
                width={'100%'}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                sx={{
                    background: `url('${Ava}'), linear-gradient(0.00deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 60%)`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                }}
            />
        </GameButtonBase>

        <Box
            position={'absolute'}
            bottom={'-1.5vh'}
            left={'-0.5vh'}
        >
            <SmallButtonBase imageIconUrl={EditIcon}/>
        </Box>
    </Box>
}