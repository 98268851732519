import { ApiResponse } from "../apiResponseModels";

export interface GameProvider {
    id: number
    provider_name: string
    title?: string|null
    logo?: string|null
}

export interface GameObj {
    Name: string;
    internal_id: number;
    external_id: string;
    Pic: string|null;
    GameRang: string|null;
    provider_name: string
}

// export interface GameOutcome {
//     Id: string;
//     Name: string;
//     Description: string;
//     SectionId: string;
//     Type: string;
//     Tags?: string[];
//     Format: string;
//     Settings?: {
//         Bets?: number[];
//         CoinValue?: number;
//         Denominations?: number[];
//     }
// }

export interface OutcomeGameSession {
    SessionId: string|null
    SessionUrl: string|null
}

export interface PlayGamesState {
    providersOutcome: GameProvider[]|null
    selectedProvider: string|null
    outcomeGames: GameObj[]|null
    gameSession: OutcomeGameSession

    isProvidersOutcomeLoading: boolean
    isOutcomeGameLoading: boolean
    isGameSessionLoading: boolean
}

export enum PlayGamesLoader {
    isProvidersOutcomeLoading,
    isOutcomeGameLoading,
    isGameSessionLoading
}

export interface iUsePlayGames extends PlayGamesState {
    getProviders: () => void
    getGames: (providerName: string) => void
    startGameSession: (gameId: number) => void
}

export interface ShowProvidersResponse extends ApiResponse {
    providers?: GameProvider[]
}

export interface GetGamesResponse extends ApiResponse {
    games?: GameObj[]
}

export interface StartGameSessionResponse extends ApiResponse {
    SessionId?: string
    SessionUrl?: string
}