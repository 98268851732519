export const getShadows = (baseSize: number): string => {
    const shadowList = [
        // outer glowing
        // `0 -${3 / baseSize}vh ${5 / baseSize}vh rgba(113, 255, 47, 0.45)`,
        // `0 -${3 / baseSize}vh ${8 / baseSize}vh #33FF00`,
        // inner border
        `inset 0 -${2 / baseSize}vh 0 rgb(186, 218, 255, 0.35)`,
        `inset 0 ${2 / baseSize}vh 0 rgb(186, 218, 255, 0.35)`,
        `inset ${2 / baseSize}vh 0 0 rgb(186, 218, 255, 0.35)`,
        `inset -${2 / baseSize}vh 0 0 rgb(186, 218, 255, 0.35)`,
        // bottom 3d effects
        `0 ${1 / baseSize}vh 0 #56AEFF`,
        `0 ${4 / baseSize}vh 0 #0E4BB9`,
        `0 ${6 / baseSize}vh 0 #1558D0`,
        // bottom shadow
        `0 ${7 / baseSize}vh ${14 / baseSize}vh rgba(14, 0, 54, 0.65)`
    ]

    return shadowList.join(', ')
}

export const backgroundGradient = 'linear-gradient(180.00deg, rgb(9, 82, 191),rgb(62, 162, 254) 100%)'



/*
* box-shadow:
* 0px 7px 14px 0px rgba(14, 0, 54, 0.65),
* 0px 6px 0px 0px rgb(21, 88, 208),
* 0px 4px 0px 0px rgb(14, 75, 185),
* 0px 1px 0px 0px rgb(86, 174, 255);
*
* */