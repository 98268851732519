import { FC } from "react";

interface TopBarBottomShapeProps {
    height: number
}

export const TopBarBottomShape: FC<TopBarBottomShapeProps> = ({height }) => {
    return <svg width={`${height * 4.7}vh`} height={`${height}vh`} viewBox="0 0 330 70" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_ddi_70_125)">
            <path d="M57.9999 61.9997L6.99988 -0.000254092L164.999 -0.000274658L164.999 61.9997H57.9999Z" fill="url(#paint0_linear_70_125)"/>
            <path d="M272 61.9997L323 -0.000274658H164.999L164.999 61.9997H272Z" fill="url(#paint1_linear_70_125)"/>
            <path d="M58.4721 60.9997L9.11731 0.999746L163.999 0.999725L163.999 60.9997H58.4721ZM165.999 60.9997L165.999 0.999725H320.882L271.528 60.9997H165.999Z" stroke="url(#paint2_linear_70_125)" strokeOpacity="0.25" strokeWidth="2"/>
        </g>
        <path d="M57.9999 61.9997L7 -9.15527e-05L164.999 -0.000259399L164.999 61.9997H57.9999Z" fill="url(#paint3_radial_70_125)"/>
        <path d="M272 61.9997L323 -0.000259399H164.999L164.999 61.9997H272Z" fill="url(#paint4_radial_70_125)"/>
        <g style={{mixBlendMode:"overlay"}} opacity="0.45">
            <path d="M57.9999 61.9999L6.99988 -0.000152588L58.9999 59.9999H271L323 -8.39233e-05L272 61.9999L57.9999 61.9999Z" fill="url(#paint5_linear_70_125)"/>
        </g>
        <mask id="mask0_70_125" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="7" y="0" width="316" height="62">
            <path d="M58.0973 62L7.00002 2.05663e-05L164.999 0L164.999 62H58.0973Z" fill="url(#paint6_radial_70_125)"/>
            <path d="M271.902 62L323 0H164.999L164.999 62L271.902 62Z" fill="url(#paint7_radial_70_125)"/>
        </mask>
        <g mask="url(#mask0_70_125)">
            <path opacity="0.65" d="M-397 305L46.9998 -138V305H-397Z" fill="url(#paint8_linear_70_125)"/>
            <path opacity="0.65" d="M-276 305L168 -138V305H-276Z" fill="url(#paint9_linear_70_125)"/>
            <path opacity="0.65" d="M-182 305L261 -138V305H-182Z" fill="url(#paint10_linear_70_125)"/>
            <path opacity="0.65" d="M-96.0001 305L347 -138V305H-96.0001Z" fill="url(#paint11_linear_70_125)"/>
            <path opacity="0.65" d="M-19.0001 305L424 -138V305H-19.0001Z" fill="url(#paint12_linear_70_125)"/>
            <path opacity="0.65" d="M59.9999 305L503 -138V305H59.9999Z" fill="url(#paint13_linear_70_125)"/>
        </g>
        <g style={{mixBlendMode:"overlay"}}>
            <mask id="mask1_70_125" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="7" y="0" width="316" height="62">
                <path d="M58.0973 62L7.00002 1.29369e-05L164.999 -7.62939e-06L164.999 62H58.0973Z" fill="url(#paint14_radial_70_125)"/>
                <path d="M271.902 62L323 -7.62939e-06H164.999L164.999 62L271.902 62Z" fill="url(#paint15_radial_70_125)"/>
            </mask>
            <g mask="url(#mask1_70_125)">
                <path opacity="0.65" d="M-397 305L46.9998 -138V305H-397Z" fill="url(#paint16_linear_70_125)"/>
                <path opacity="0.65" d="M-276 305L168 -138V305H-276Z" fill="url(#paint17_linear_70_125)"/>
                <path opacity="0.65" d="M-182 305L261 -138V305H-182Z" fill="url(#paint18_linear_70_125)"/>
                <path opacity="0.65" d="M-96.0001 305L347 -138V305H-96.0001Z" fill="url(#paint19_linear_70_125)"/>
                <path opacity="0.65" d="M-19.0001 305L424 -138V305H-19.0001Z" fill="url(#paint20_linear_70_125)"/>
                <path opacity="0.65" d="M59.9999 305L503 -138V305H59.9999Z" fill="url(#paint21_linear_70_125)"/>
            </g>
        </g>
        <path d="M319 10L324.5 4H330L324 10H319Z" fill="#1D1061"/>
        <path d="M11 10L5.5 4H0L6 10H11Z" fill="#1D1061"/>
        <defs>
            <filter id="filter0_ddi_70_125" x="6.99988" y="-0.000274658" width="316" height="70" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="8"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.127604 0 0 0 0 0.0692708 0 0 0 0 0.4375 0 0 0 1 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_70_125"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="6"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.11059 0 0 0 0 0.0600347 0 0 0 0 0.379167 0 0 0 1 0"/>
                <feBlend mode="normal" in2="effect1_dropShadow_70_125" result="effect2_dropShadow_70_125"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_70_125" result="shape"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="-4"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.172387 0 0 0 0 0.104444 0 0 0 0 0.533333 0 0 0 1 0"/>
                <feBlend mode="normal" in2="shape" result="effect3_innerShadow_70_125"/>
            </filter>
            <linearGradient id="paint0_linear_70_125" x1="165" y1="62" x2="165" y2="2.69544" gradientUnits="userSpaceOnUse">
                <stop stopColor="#2A178C"/>
                <stop offset="1" stopColor="#26177B"/>
            </linearGradient>
            <linearGradient id="paint1_linear_70_125" x1="165" y1="62" x2="165" y2="2.69544" gradientUnits="userSpaceOnUse">
                <stop stopColor="#2A178C"/>
                <stop offset="1" stopColor="#26177B"/>
            </linearGradient>
            <linearGradient id="paint2_linear_70_125" x1="165" y1="62" x2="165" y2="58.6304" gradientUnits="userSpaceOnUse">
                <stop stopColor="white"/>
                <stop offset="1" stopColor="white" stopOpacity="0"/>
            </linearGradient>
            <radialGradient id="paint3_radial_70_125" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(159.622 62) rotate(-90) scale(64.0003 279.123)">
                <stop stopColor="#3A20C9"/>
                <stop offset="1" stopColor="#1C0C77" stopOpacity="0"/>
            </radialGradient>
            <radialGradient id="paint4_radial_70_125" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(159.622 62) rotate(-90) scale(64.0003 279.123)">
                <stop stopColor="#3A20C9"/>
                <stop offset="1" stopColor="#1C0C77" stopOpacity="0"/>
            </radialGradient>
            <linearGradient id="paint5_linear_70_125" x1="165" y1="61.9999" x2="165" y2="3.99989" gradientUnits="userSpaceOnUse">
                <stop stopColor="#B8C3FF"/>
                <stop offset="0.35439" stopColor="#B8C3FF" stopOpacity="0"/>
            </linearGradient>
            <radialGradient id="paint6_radial_70_125" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(159.621 62.0003) rotate(-90) scale(62.0003 279.123)">
                <stop stopColor="#3A20C9"/>
                <stop offset="1" stopColor="#1C0C77" stopOpacity="0"/>
            </radialGradient>
            <radialGradient id="paint7_radial_70_125" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(159.621 62.0003) rotate(-90) scale(62.0003 279.123)">
                <stop stopColor="#3A20C9"/>
                <stop offset="1" stopColor="#1C0C77" stopOpacity="0"/>
            </radialGradient>
            <linearGradient id="paint8_linear_70_125" x1="-174.692" y1="56.1582" x2="46.4404" y2="306.11" gradientUnits="userSpaceOnUse">
                <stop stopColor="#E2E8FF" stopOpacity="0.1"/>
                <stop offset="0.536694" stopColor="#E2E8FF" stopOpacity="0"/>
            </linearGradient>
            <linearGradient id="paint9_linear_70_125" x1="-53.6922" y1="56.1582" x2="167.44" y2="306.11" gradientUnits="userSpaceOnUse">
                <stop stopColor="#E2E8FF" stopOpacity="0.1"/>
                <stop offset="0.536694" stopColor="#E2E8FF" stopOpacity="0"/>
            </linearGradient>
            <linearGradient id="paint10_linear_70_125" x1="39.8071" y1="56.1582" x2="261" y2="305.614" gradientUnits="userSpaceOnUse">
                <stop stopColor="#E2E8FF" stopOpacity="0.1"/>
                <stop offset="0.536694" stopColor="#E2E8FF" stopOpacity="0"/>
            </linearGradient>
            <linearGradient id="paint11_linear_70_125" x1="125.807" y1="56.1582" x2="347" y2="305.614" gradientUnits="userSpaceOnUse">
                <stop stopColor="#E2E8FF" stopOpacity="0.1"/>
                <stop offset="0.536694" stopColor="#E2E8FF" stopOpacity="0"/>
            </linearGradient>
            <linearGradient id="paint12_linear_70_125" x1="202.807" y1="56.1582" x2="424" y2="305.614" gradientUnits="userSpaceOnUse">
                <stop stopColor="#E2E8FF" stopOpacity="0.1"/>
                <stop offset="0.536694" stopColor="#E2E8FF" stopOpacity="0"/>
            </linearGradient>
            <linearGradient id="paint13_linear_70_125" x1="281.807" y1="56.1582" x2="503" y2="305.614" gradientUnits="userSpaceOnUse">
                <stop stopColor="#E2E8FF" stopOpacity="0.1"/>
                <stop offset="0.536694" stopColor="#E2E8FF" stopOpacity="0"/>
            </linearGradient>
            <radialGradient id="paint14_radial_70_125" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(159.621 62.0003) rotate(-90) scale(62.0003 279.123)">
                <stop stopColor="#3A20C9"/>
                <stop offset="1" stopColor="#1C0C77" stopOpacity="0"/>
            </radialGradient>
            <radialGradient id="paint15_radial_70_125" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(159.621 62.0003) rotate(-90) scale(62.0003 279.123)">
                <stop stopColor="#3A20C9"/>
                <stop offset="1" stopColor="#1C0C77" stopOpacity="0"/>
            </radialGradient>
            <linearGradient id="paint16_linear_70_125" x1="-174.692" y1="56.1582" x2="46.4404" y2="306.11" gradientUnits="userSpaceOnUse">
                <stop stopColor="#E2E8FF" stopOpacity="0.1"/>
                <stop offset="0.536694" stopColor="#E2E8FF" stopOpacity="0"/>
            </linearGradient>
            <linearGradient id="paint17_linear_70_125" x1="-53.6922" y1="56.1582" x2="167.44" y2="306.11" gradientUnits="userSpaceOnUse">
                <stop stopColor="#E2E8FF" stopOpacity="0.1"/>
                <stop offset="0.536694" stopColor="#E2E8FF" stopOpacity="0"/>
            </linearGradient>
            <linearGradient id="paint18_linear_70_125" x1="39.8071" y1="56.1582" x2="261" y2="305.614" gradientUnits="userSpaceOnUse">
                <stop stopColor="#E2E8FF" stopOpacity="0.1"/>
                <stop offset="0.536694" stopColor="#E2E8FF" stopOpacity="0"/>
            </linearGradient>
            <linearGradient id="paint19_linear_70_125" x1="125.807" y1="56.1582" x2="347" y2="305.614" gradientUnits="userSpaceOnUse">
                <stop stopColor="#E2E8FF" stopOpacity="0.1"/>
                <stop offset="0.536694" stopColor="#E2E8FF" stopOpacity="0"/>
            </linearGradient>
            <linearGradient id="paint20_linear_70_125" x1="202.807" y1="56.1582" x2="424" y2="305.614" gradientUnits="userSpaceOnUse">
                <stop stopColor="#E2E8FF" stopOpacity="0.1"/>
                <stop offset="0.536694" stopColor="#E2E8FF" stopOpacity="0"/>
            </linearGradient>
            <linearGradient id="paint21_linear_70_125" x1="281.807" y1="56.1582" x2="503" y2="305.614" gradientUnits="userSpaceOnUse">
                <stop stopColor="#E2E8FF" stopOpacity="0.1"/>
                <stop offset="0.536694" stopColor="#E2E8FF" stopOpacity="0"/>
            </linearGradient>
        </defs>
    </svg>

}