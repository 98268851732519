import { FC, ReactNode } from "react";
import { Box } from "@mui/material";

interface GameDialogContentProps {
    children?: ReactNode
}

export const GameDialogContent: FC<GameDialogContentProps> = ({children}) => {
    return <Box
        display={'flex'}
        flexDirection={'column'}
        rowGap={'2vh'}
        minWidth={'45vh'}
        maxHeight={'80vh'}
    >
        {children}
    </Box>
}