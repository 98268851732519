import { FC, ReactNode } from "react";
import { BoardBase } from "../board/BoardBase";
import { Box } from "@mui/material";

interface LeftButtonPanelProps {
    children?: ReactNode
}

export const LeftButtonPanel: FC<LeftButtonPanelProps> = ({children}) => {
    return <Box
        width={'10vw'}
        minWidth={'10vh'}
        maxWidth={'12vh'}
        flex={4}
    >
        <BoardBase height={'100%'} width={'100%'}>
            {children}
        </BoardBase>
    </Box>
}