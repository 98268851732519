import { FC } from "react";
import { Box } from "@mui/material";

import FinanceIcon from '../../../../../assets/sweep/icons/moneyWithShadow.png'
import InfoIcon from '../../../../../assets/sweep/icons/notificationWithShadow.png'

interface NotificationIconProps {
    size?: number
    type: 'finance' | 'info'
    read: boolean
}

export const NotificationIcon: FC<NotificationIconProps> = ({size = 5, type, read}) => {
    const baseSize = 48 / size
    const height = 30
    const borderWidth = 3
    const icon = (() => {
        switch (type) {
            case 'finance':
                return FinanceIcon
            case 'info':
                return InfoIcon
            default:
                return InfoIcon
        }
    })()

    const borderGradient = read ? 'linear-gradient(180.00deg, #55CF55, #F5FF8B 100%)' : 'linear-gradient(180.00deg, #FFE535, #FF9838 100%)'
    const bgGradient = read ? 'linear-gradient(180.00deg, #44B444, #EAF944 100%)' : 'linear-gradient(180.00deg, #EED218, #F88A25 100%)'

    return <Box
        height={`${height / baseSize}vh`}
        width={`${height / baseSize}vh`}
        padding={`${borderWidth/ baseSize}vh`}
        borderRadius={`${height / 2 / baseSize}vh`}
        boxShadow={`0 ${6 / baseSize}vh ${24 / baseSize}vh rgba(14, 0, 54, 0.65)`}
        sx={{
            backgroundImage: borderGradient,
        }}
    >
        <Box
            height={'100%'}
            width={'100%'}
            borderRadius={`${(height- borderWidth) / 2 / baseSize}vh`}
            sx={{
                backgroundImage: `url('${icon}'), ${bgGradient}`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '110%',
            }}
        />
    </Box>
}