export const getShadows = (baseSize: number): string => {
    const shadowList = [
        // outer glowing
        `0 -${3 / baseSize}vh ${5 / baseSize}vh rgba(255,59,47, 0.45)`,
        `0 -${3 / baseSize}vh ${8 / baseSize}vh #FF1F00`,
        // inner border
        `inset 0 -${2 / baseSize}vh 0 rgb(255,186,186, 0.35)`,
        `inset 0 ${2 / baseSize}vh 0 rgb(255,186,186, 0.35)`,
        `inset ${2 / baseSize}vh 0 0 rgb(255,186,186, 0.35)`,
        `inset -${2 / baseSize}vh 0 0 rgb(255,186,186, 0.35)`,
        // bottom 3d effects
        `0 ${1 / baseSize}vh 0 #FF5656`,
        `0 ${4 / baseSize}vh 0 #BF1F09`,
        `0 ${6 / baseSize}vh 0 #D93019`,
        // bottom shadow
        `0 ${7 / baseSize}vh ${14 / baseSize}vh rgba(14, 0, 54, 0.65)`
    ]

    return shadowList.join(', ')
}

export const backgroundGradient = 'linear-gradient(0.00deg, #FE553E 0%, #BF1F09 100%)'