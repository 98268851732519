import { FC, SyntheticEvent, useEffect, useState } from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Box, Button,
    LinearProgress,
    Stack,
    useMediaQuery
} from "@mui/material";
import { usePaymentSystems } from "../../../features/paymentSystems/usePaymentSystems";
import PaymentsIcon from '@mui/icons-material/Payments';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from "@mui/material/Typography";
import { clearPaymentSystems } from "../../../features/paymentSystems/paymentSystemsSlice";
import { useAppDispatch } from "../../../store";
import { InfoWithLabel } from "../../../components/common/info_with_label/InfoWithLabel";
import { useLanguage } from "../../../features/localisation/useLanguage";
import { AddPaymentDialog } from "./add_payment/AddPaymentDialog";

export const PaymentAccounts: FC = () => {
    const dispatch = useAppDispatch()
    const { languagePack: { pack: { paymentSystems: lang } } } = useLanguage()
    const { isPaymentAccountsLoading, userPaymentAccounts, getUserPaymentAccounts } = usePaymentSystems()
    const [ expandedAccordion, setExpandedAccordion ] = useState<number | null>(null)
    const normalScreens = useMediaQuery('(min-width:700px)');
    const [ showAddPayment, setShowAddPayment ] = useState<boolean>(false)

    useEffect(() => {
        return () => {
            dispatch(clearPaymentSystems())
        }
    }, [ dispatch ])

    useEffect(() => {
        getUserPaymentAccounts()
    }, [ getUserPaymentAccounts ])

    const handleChangeAccordion = (paymentId: number) => (event: SyntheticEvent, isExpanded: boolean) => {
        setExpandedAccordion(!isExpanded ? null : paymentId)
    }

    return <>
        <Box
            display={'flex'}
            flexDirection={'column'}
            gap={'20px'}
            padding={'20px'}
            height={'calc(100vh - 64px - 40px - 24px)'}
            alignItems={'center'}
        >
            <Stack direction={'column'} rowGap={'4px'} alignItems={'center'} width={normalScreens ? '640px' : '100%'}>
                {isPaymentAccountsLoading && <LinearProgress sx={{width: '100%'}}/>}

                {userPaymentAccounts && userPaymentAccounts.map(paymentAccount => <Accordion
                    expanded={expandedAccordion === paymentAccount.id}
                    onChange={handleChangeAccordion(paymentAccount.id)}
                    key={`payment-account-id-${paymentAccount.id}`}
                    sx={{ width: '100%' }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Stack direction={'row'} alignItems={'center'} columnGap={'18px'}>
                            <Avatar
                                alt={`${paymentAccount.method_name} payment method icon`}
                                src={paymentAccount.pic_url}
                                // src={'https://unigames.richbear.space/' + paymentAccount.pic_url}
                                sx={{ width: 52, height: 52 }}
                            >
                                <PaymentsIcon fontSize={'small'}/>
                            </Avatar>
                            <Typography fontSize={18} fontWeight={'500'}>
                                {paymentAccount.method_name}
                            </Typography>
                        </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Stack rowGap={'14px'}>
                            {paymentAccount.params.fields.map(field => <InfoWithLabel
                                key={`payment-fields-label-${field.label}`}
                                label={field.label}>{field.value}</InfoWithLabel>)}
                        </Stack>
                    </AccordionDetails>
                </Accordion>)}

                <Box paddingY={'10px'} width={'100%'} display={'flex'} justifyContent={'flex-end'}>
                    <Button variant={'contained'} onClick={() => setShowAddPayment(true)}>
                        {lang.addPaymentMethodBTN}
                    </Button>
                </Box>
            </Stack>
        </Box>

        {showAddPayment && <AddPaymentDialog close={() => setShowAddPayment(false)}/>}
    </>
}