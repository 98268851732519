import { Box } from "@mui/material";
import { FC } from "react";
import OrangeCoin from "../../../../assets/sweep/orange_coin.webp"
import GreenCoin from "../../../../assets/sweep/green_coin.webp"

interface CoinProps {
    coinType: 'fun' | 'real'
    size?: number
    heightUniversalPx?: number
}

export const Coin: FC<CoinProps> = ({coinType, size = 5, heightUniversalPx}) => {
    const baseSize = 48 / size

    return <Box
        width={`${(heightUniversalPx ? heightUniversalPx * 1.26875 : 88.81) / baseSize}vh`}
        height={`${(heightUniversalPx ?? 70) / baseSize}vh`}
        sx={{
            pointerEvents: 'none',
            backgroundImage: `url(${coinType === 'fun' ? GreenCoin : OrangeCoin})`,
            backgroundPosition: 'center top',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',

            // backgroundColor: '#FFFFFF',
        }}
    />
}