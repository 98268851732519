import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { GameButtonBase } from "../button/GameButtonBase";
import { ToggleKnob } from "./ToggleKnob";

interface SwitcherProps {
    size?: number
    leftText: string
    rightText: string
    leftIcon: string
    rightIcon: string
    leftIsActive: boolean
    onSwitch?: () => void
    width?: string
}

export const Switcher: FC<SwitcherProps> = ({ size = 5, rightIcon, rightText, leftText, leftIcon, leftIsActive , onSwitch = () => {}, width = '20vh'}) => {
    const baseSize = 48 / size

    const textShadowsList: string[] = [
        `0 -${4 / baseSize}vh ${8 / baseSize}vh rgba(255,255,255, 0.35)`,
        `0 ${4 / baseSize}vh ${8 / baseSize}vh #128101`
    ]

    const textShadows = textShadowsList.join(', ')

    return <GameButtonBase green shaded width={width} size={size}>
        <Box
            display={'flex'}
            width={'100%'}
            height={'100%'}
            padding={`${2 / baseSize}vh`}
            boxSizing={'border-box'}
        >
            <Box
                onClick={onSwitch}
                className={'pointer'}
                width={'100%'}
                height={'100%'}
                display={'flex'}
                flexDirection={leftIsActive ? 'row' : 'row-reverse'}
            >

                <Box
                    flex={1}
                    height={'100%'}
                >
                    <ToggleKnob>
                        <Box
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            columnGap={`${2 / baseSize}vh`}
                        >
                            <Box
                                sx={{
                                    background: `url('${leftIsActive ? leftIcon : rightIcon}')`,
                                    backgroundSize: '135%',
                                    backgroundPosition: 'center 20%',
                                    backgroundRepeat: 'no-repeat',
                                }}
                                height={`${30 / baseSize}vh`}
                                width={`${30 / baseSize}vh`}
                            />
                            <Typography
                                color={'#094803'}
                                fontSize={`${20 / baseSize}vh`}
                                fontWeight={700}
                                sx={{
                                    textShadow: `0 ${2 / baseSize}vh 0 #fff`,
                                }}
                            >
                                {leftIsActive ? leftText : rightText}
                            </Typography>
                        </Box>
                    </ToggleKnob>
                </Box>
                <Box flex={1} display={'flex'} alignItems={'center'} justifyContent={'center'} pb={`${2 / baseSize}vh`}>
                    <Box
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        columnGap={`${2 / baseSize}vh`}
                    >
                        <Box
                            sx={{
                                background: `url('${!leftIsActive ? leftIcon : rightIcon}')`,
                                backgroundSize: '135%',
                                backgroundPosition: 'center 20%',
                                backgroundRepeat: 'no-repeat',
                            }}
                            height={`${30 / baseSize}vh`}
                            width={`${30 / baseSize}vh`}
                        />
                        <Typography
                            color={'#fff'}
                            fontSize={`${20 / baseSize}vh`}
                            fontWeight={700}
                            sx={{
                                textShadow: textShadows
                            }}
                        >
                            {!leftIsActive ? leftText : rightText}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    </GameButtonBase>
}