import { FC } from "react";
import { Box } from "@mui/material";
import { HexagonShape } from "./HexagonShape";
import { HexagonShapeFilled } from "./HexagonShapeFilled";

interface HexagonButtonProps {
    size?: number
    iconImageUrl: string
}

export const HexagonButton: FC<HexagonButtonProps> = ({size = 5, iconImageUrl}) => {
    const baseSize = 48 / size

    const bottomShapeShadowsList: string[] = [
        `0 ${6 / baseSize}vh 0 #1C0F61`,
        `0 ${2 / baseSize}vh 0 #2C1894`,
        `0 ${2 / baseSize}vh ${5 / baseSize}vh rgba(14, 0, 38, 0.45)`
    ]

    const bottomShapeShadows = bottomShapeShadowsList.map(shadow => `drop-shadow(${shadow})`).join(' ')

    const shadowsList: string[] = [
        `0px ${1 / baseSize}vh ${1 / baseSize}vh #3A850F`,
        `0px ${2 / baseSize}vh ${4 / baseSize}vh #53CB0E`,
        `0px ${2 / baseSize}vh ${8 / baseSize}vh #79CC49`,
    ]

    const shadows = shadowsList.map(shadow => `drop-shadow(${shadow})`).join(' ')

    return <Box
        height={'7.16vh'}
        width={'7.16vh'}
        className={'pointer'}
        position={'relative'}
    >
        {/*-----Bottom shape------*/}
        <Box
            position={'absolute'}
            height={'100%'}
            width={'100%'}
            sx={{
                filter: bottomShapeShadows
            }}
        >
            <HexagonShapeFilled/>
        </Box>

        {/*-----Top shape------*/}
        <Box
            position={'absolute'}
            height={'100%'}
            width={'100%'}
            sx={{
                filter: shadows
            }}
        >
            <HexagonShape/>
        </Box>

        <Box
            position={'absolute'}
            height={'100%'}
            width={'100%'}
            sx={{
                background: `url('${iconImageUrl}')`,
                backgroundSize: 'cover',
            }}
        />
    </Box>
}