import React, { FC, useState } from "react";
import { GameDialog } from "../game_dialog_windows/GameDialog";
import { GameDialogContent } from "../game_dialog_windows/GameDialogContent";
import { WhiteShadowedText } from "../text/WhiteShadowedText";
import { TabsPanel } from "../tabs/tabs_panel/TabsPanel";
import { CoinsSection } from "./coins/CoinsSection";
import { RedeemSection } from "./redeem_coins/RedeemSection";
import { TransfersSection } from "./transfers/TransfersSection";

interface WalletWindowProps {
    onClose?: () => void
}

const tabs = [ { name: 'Coins' }, { name: 'Redeem' }, { name: 'Transaction' } ]

export const WalletWindow: FC<WalletWindowProps> = ({onClose}) => {
    const [activeTabIndex, setActiveTabIndex] = useState<number>(0)

    return <GameDialog onClose={onClose}>
        <GameDialogContent>
            <WhiteShadowedText>
                Wallet
            </WhiteShadowedText>

            <TabsPanel activeTabIndex={activeTabIndex} onTabIndexChanged={setActiveTabIndex} tabs={tabs} size={4}/>

            {(() => {
                switch (activeTabIndex) {
                    case 0:
                        return <CoinsSection/>
                    case 1:
                        return <RedeemSection/>
                    case 2:
                        return <TransfersSection/>
                    // case 3:
                    //     return <></>
                    default:
                        return null;
                }
            })()}
        </GameDialogContent>
    </GameDialog>
}