import { FC, useEffect, useState } from "react";
import { Box, Button, LinearProgress, Stack, TextField } from "@mui/material";
import { PaymentAccount, PaymentMethodTemplate, Proof } from "../../../../features/paymentSystems/PaymentSystemModels";
import { useUser } from "../../../../features/user/useUser";
import { usePaymentSystems } from "../../../../features/paymentSystems/usePaymentSystems";
import { useSnackbar, VariantType } from "notistack";
import { AddPayment } from "../../payment_accounts/add_payment/AddPayment";
import { MoneyInput } from "../../../../components/money_input/MoneyInput";
import { InfoWithLabel } from "../../../../components/common/info_with_label/InfoWithLabel";
import Typography from "@mui/material/Typography";
import { convertStringCurrencyToCents } from "../../../../features/common_funcs";

interface RequestPaymentProps {
    onDone?: () => void
    cashierPaymentAccount: PaymentAccount;
}

export const RequestPayment: FC<RequestPaymentProps> = ({onDone= () => {}, cashierPaymentAccount}) => {
    const { user } = useUser();
    const {
        userPaymentAccounts,
        getUserPaymentAccounts,
        isPaymentAccountsLoading,
        isRequestPayoutLoading,
        getPaymentMethods,
        isPaymentMethodsLoading,
        unfilteredPaymentMethods,
        isBonusRequestLoading,
        requestBonus,
        isPaymentRequestLoading,
        requestPayment
    } = usePaymentSystems();
    const [ userPaymentAccount, setUserPaymentAccount ] = useState<PaymentAccount | null>(null);
    const [ paymentMethodTemplate, setPaymentMethodTemplate ] = useState<PaymentMethodTemplate | null>(null);
    const [ amount, setAmount ] = useState<string>('');
    const [ hasError, setHasError ] = useState<boolean>(false);
    const [ initLoad, setInitLoad ] = useState(true);
    const { enqueueSnackbar } = useSnackbar();
    const [ promo, setPromo ] = useState<string>('')
    const [ secondStep, setSecondStep ] = useState<boolean>(false)
    const [ proof, setProof ] = useState<string>('')

    useEffect(() => {
        if (initLoad) {
            // If the component has just been mounted
            if (userPaymentAccounts === null && !isPaymentAccountsLoading) {
                // If user payment accounts are not loaded and not currently loading
                // Request user payment accounts
                getUserPaymentAccounts();
            }
            if (unfilteredPaymentMethods === null && !isPaymentMethodsLoading) {
                // If payment method templates are empty and not currently loading
                // Request payment method templates
                getPaymentMethods();
            }
            setInitLoad(false);
        }
    }, [getPaymentMethods, getUserPaymentAccounts, initLoad, isPaymentAccountsLoading, isPaymentMethodsLoading, unfilteredPaymentMethods, userPaymentAccounts]);

    useEffect(() => {
        if (userPaymentAccounts !== null && unfilteredPaymentMethods !== null) {
            // If user payment accounts and payment method templates are loaded
            if (userPaymentAccount === null) {
                // If there is no user payment account yet (e.g., it has not been found
                // or created yet), then search for it
                const paymentAccount = userPaymentAccounts.find(acc => acc.method_id === cashierPaymentAccount.method_id);
                if (paymentAccount) {
                    // If the method is found, save it to the state
                    setUserPaymentAccount(paymentAccount);
                }
            }
            if (paymentMethodTemplate === null) {
                console.log('paymentMethodTemplate === null')
                const template = unfilteredPaymentMethods.find(t => t.id === cashierPaymentAccount.method_id);
                if (template) {
                    setPaymentMethodTemplate(template);
                }
            }
        }
    }, [cashierPaymentAccount, paymentMethodTemplate, unfilteredPaymentMethods, userPaymentAccount, userPaymentAccounts]);

    const nextBtnClickHandler = () => {
        if (promo.trim() !== '') {
            requestBonus(promo, convertStringCurrencyToCents(amount), () => {
                setSecondStep(true)
            })
        } else {
            setSecondStep(true)
        }
    }

    const paymentRequestClickHandler = () => {
        if (paymentMethodTemplate) {
            const proofObj: Proof = {
                ...paymentMethodTemplate.proof,
                value: proof
            }
            requestPayment(
                cashierPaymentAccount.method_id,
                convertStringCurrencyToCents(amount),
                proofObj,
                promo.trim() !== '' ? promo : undefined,
                () => {
                    sendSnackBar('success', 'Your request has been sent. Soon the moderator will check your payment and you will receive money into your account.')
                    onDone()
                }
            )
        }
    }

    const sendSnackBar = (variant: VariantType, message: string) => {
        enqueueSnackbar(message, { variant });
    };

    return <Stack rowGap={'20px'}>
        {(isPaymentAccountsLoading || isRequestPayoutLoading || isPaymentMethodsLoading) && <LinearProgress sx={{ width: '100%' }} />}

        {/* If user payment account is not available, but the payment method template is loaded,
        show the option to add a payment */}
        {userPaymentAccount === null && paymentMethodTemplate !== null && <AddPayment paymentMethodTemplate={paymentMethodTemplate} />}



        {userPaymentAccount !== null && <>
            <MoneyInput
                disabled={secondStep || isBonusRequestLoading}
                label={"Deposit amount"}
                value={amount}
                // maxAmount={maxAmount}
                onValueChanged={setAmount}
                currency={user?.user_currency}
                hasError={(has) => setHasError(has)}
            />


            {(!secondStep || promo.trim() !== '') && <TextField
                disabled={secondStep || isBonusRequestLoading}
                value={promo}
                onChange={e => setPromo(e.target.value)}
                label={'Promocode'}
                fullWidth
                size={'small'}
            />}

            <Box
                display={'flex'}
                justifyContent={secondStep ? 'start' : 'end'}>
                <Button
                    color={secondStep ? 'secondary' : 'primary'}
                    onClick={secondStep ? () => setSecondStep(false) : nextBtnClickHandler}
                    disabled={hasError || amount === '' || isBonusRequestLoading}
                    variant={'contained'}>
                    {secondStep ? 'Back' : 'Next'}
                </Button>
            </Box>

            {secondStep && <>
                <Typography>
                    1. Transfer money to this account
                </Typography>
                <Typography>
                    Payment method: {cashierPaymentAccount.method_name}
                </Typography>
                {cashierPaymentAccount.params.fields.map(field => <InfoWithLabel
                label={field.label}>{field.value}</InfoWithLabel>)}

                <Typography>
                    2. Enter your proof for checking your payment
                </Typography>
                {paymentMethodTemplate && <TextField
                    onChange={e => setProof(e.target.value)}
                    value={proof}
                    label={paymentMethodTemplate.proof.label}
                    helperText={paymentMethodTemplate.proof.helper}
                    placeholder={paymentMethodTemplate.proof.placeholder}
                />}

                <Button onClick={paymentRequestClickHandler} variant={'contained'} disabled={proof.trim() === '' || isPaymentRequestLoading}>
                    I transferred money
                </Button>
            </>}
        </>}
    </Stack>
}