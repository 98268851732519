import { FC, ReactNode } from "react";
import { useCommonMediaqueries } from "../../../features/mediaQueries/useCommonMediaqueries";
import { ButtonPanelLandscape } from "./ButtonPanelLandscape";
import { ButtonPanelPortrait } from "./ButtonPanelPortrait";

interface ButtonPanelProps {
    children?: ReactNode
}

export const ButtonPanel: FC<ButtonPanelProps> = ({children}) => {
    const { portraitMode } = useCommonMediaqueries()

    return !portraitMode ? <ButtonPanelLandscape>{children}</ButtonPanelLandscape> : <ButtonPanelPortrait>{children}</ButtonPanelPortrait>
}