import { TextField } from "@mui/material";
import { ChangeEvent, FC, HTMLInputTypeAttribute, ReactNode } from "react";


import FileCopyIcon from '@mui/icons-material/FileCopy';
import IconButton from "@mui/material/IconButton";

interface GameTextFieldProps {
    placeholder?: string
    value?: string
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void
    type?:  HTMLInputTypeAttribute
    copyEnabled?: boolean
    disabled?: boolean
    rightComponent?: ReactNode
}

export const GameTextField: FC<GameTextFieldProps> = ({placeholder, value, onChange, type, copyEnabled = false, disabled = false, rightComponent}) => {
    const handleCopy = async () => {
        if (value) {
            try {
                await navigator.clipboard.writeText(value);
                console.log('Текст успешно скопирован в буфер обмена');
            } catch (err) {
                console.error('Ошибка при копировании текста:', err);
            }
        }
    };

    return <TextField
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        type={type}
        sx={{
            '& .MuiOutlinedInput-root': {
                borderRadius: '12px',
                    backgroundColor: 'rgba(22,9,67,0.45)',
                '& fieldset': {
                    borderColor: '#3E29B7', // изменить цвет обводки
                    borderRadius: '12px',
                    borderWidth: '2px',
                    borderBoxSize: 'content-box',
                    boxShadow: 'none'
                },
            }
        }}
        InputProps={{
            endAdornment: (copyEnabled || rightComponent) && <>
                {copyEnabled &&
                <IconButton
                    disabled={!Boolean(value)}
                    onClick={handleCopy}
                >
                    <FileCopyIcon color={'inherit'}/>
                </IconButton>}
                {rightComponent}
            </>,
            disabled: disabled
        }}
    />
}