import { FC, ReactNode } from "react";
import { Box } from "@mui/material";

interface CoinsPackContainerProps {
    children?: ReactNode
}

export const CoinsPackContainer: FC<CoinsPackContainerProps> = ({children}) => {
    return <Box
        position={'relative'}
        width={'27vh'}
        height={'20vh'}
        overflow={'visible'}
    >
        {children}
    </Box>
}

