import { Box, Typography } from "@mui/material";
import React, { FC, useState } from "react";
import { BackButton } from "../../button/BackButton";
import { WarningBox } from "../../info_boxes/WarningBox";
import { GameButtonFilled } from "../../button/GameButtonFilled";
import { Coin } from "../../coin_pack_cover/Coin";
import { WhiteConvexText } from "../../text/WhiteConvexText";
import { SubtleText } from "../../text/SubtleText";
import { GameTextField } from "../../game_text_field/GameTextField";
import { SimpleGameDropdown } from "../../dropdown/SimpleGameDropdown";

interface ThirdCoinsScreenProps {
    onProceed?: () => void
    hidden?: boolean
    onBack?: () => void
}

export const ThirdCoinsScreen: FC<ThirdCoinsScreenProps> = ({onProceed= () => {}, hidden = false, onBack = () => {}}) => {
    const [currency, setCurrency] = useState('')
    const [network, setNetwork] = useState('')

    return <Box
        maxWidth={'50vh'}
        display={hidden ? 'none' : 'flex'}
        flexDirection={'column'}
        justifyContent={'space-between'}
        rowGap={'2vh'}
        maxHeight={'60vh'}
    >
        <BackButton onBack={onBack}/>

        <Box
            display={'flex'}
            flexDirection={'column'}
            rowGap={'2vh'}
            flexGrow={1}
            sx={{
                overflowY: 'auto',
                scrollbarWidth: 'thin',
                scrollbarColor: 'rgba(255, 255, 255, 0) transparent',
                '&:hover': {
                    scrollbarColor: 'rgba(255, 255, 255, 0.1) transparent',
                }
            }}
        >
            <WarningBox>
                Please note: All "Golden coin" received using crypto can only will redeemed via crypto.
            </WarningBox>

            <Box
                display={'flex'}
                flexDirection={'column'}
                rowGap={'0.8vh'}
            >
                <Typography fontWeight={700} fontSize={'2.8vh'} sx={{ opacity: '0.5' }}>
                    You will receive:
                </Typography>

                <Box
                    display={'flex'}
                    columnGap={'2vh'}
                    alignItems={'center'}
                >
                    <Box
                        display={'flex'}
                        columnGap={'1vh'}
                        alignItems={'center'}
                    >
                        <Coin coinType={'real'} heightUniversalPx={24}/>
                        <WhiteConvexText size={'2vh'} shadowSize={3}>
                            1100 GC
                        </WhiteConvexText>
                    </Box>

                    <SubtleText>
                        plus free
                    </SubtleText>

                    <Box
                        display={'flex'}
                        columnGap={'1vh'}
                        alignItems={'center'}
                    >
                        <Coin coinType={'fun'} heightUniversalPx={24}/>
                        <WhiteConvexText size={'2vh'} shadowSize={3}>
                            110 CS
                        </WhiteConvexText>
                    </Box>
                </Box>

                <SubtleText>
                    This is an estimation based on current conversion rates, it may change by the time you exchange
                    currencies
                </SubtleText>

                <Box
                    display={'flex'}
                    columnGap={'2vh'}
                >
                    <Box
                        flex={1}
                        display={'flex'}
                        flexDirection={'column'}
                        rowGap={'1vh'}
                    >
                        <Typography>Currency</Typography>
                        <SimpleGameDropdown value={currency} setValue={setCurrency} list={[
                            'ETH',
                            'USDT',
                            'BTC',
                        ]}/>
                    </Box>

                    <Box
                        flex={1}
                        display={'flex'}
                        flexDirection={'column'}
                        rowGap={'1vh'}
                    >
                        <Typography>Network</Typography>
                        <SimpleGameDropdown value={network} setValue={setNetwork} list={[
                            'Ethereum', 'Omni', 'Tron', 'EOS', 'Liquid', 'Algorand'
                        ]}/>
                    </Box>
                </Box>

                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    rowGap={'1vh'}
                >
                    <Typography>Your USDT address</Typography>
                    <GameTextField
                        type={'text'}
                        copyEnabled
                        value={'0x7cf7b1e4d1454f2878f0d8e7f8d8e0e1e0b96c2c8b16d5e3f1b2e3182a60c5d9'}
                    />
                </Box>

                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    rowGap={'1vh'}
                >
                    <Typography>Your USDT address</Typography>
                    <GameTextField
                        type={'text'}
                        copyEnabled
                        value={'19.9989895'}
                    />
                </Box>
            </Box>
        </Box>
        <GameButtonFilled onClick={() => onProceed()}>
            Confirm transfer
        </GameButtonFilled>
    </Box>
}