import { FC, ReactNode } from "react";
import { Box } from "@mui/material";

interface BoardBaseProps {
    width?: string
    height?: string
    children?: ReactNode
    size?: number
}

export const BoardBase: FC<BoardBaseProps> = ({width, height, children, size= 5}) => {
    const baseSize = 48 / size

    // the last is backward
    const shadowList = [
        `inset 0 -${4 / baseSize}vh 0 #2C1B88`,
        `inset 0 ${4 / baseSize}vh 0 #3923B5`,
        `inset 0 0 ${12 / baseSize}vh #3C2995`,

        `0 ${6 / baseSize}vh 0 #1C0F61`,
        `0 ${10 / baseSize}vh 0 #211270`,
        `0 ${42 / baseSize}vh ${42 / baseSize}vh rgba(14,0,38, 0.45)`,
    ]

    const shadows = shadowList.join(', ')

    return <Box
        borderRadius={`${24 / baseSize}vh`}
        width={width}
        height={height}
        sx={{background: 'linear-gradient(0.00deg, rgb(38, 23, 123) 0%,rgb(51, 28, 175) 133.451%)',
            // backgroundColor: 'rgba(14,0,38, 0.45)'
    }}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'space-evenly'}
        boxShadow={shadows}
        overflow={'hidden'}
    >
        {children}
    </Box>
}