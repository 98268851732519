import React, { FC } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Stack, Typography } from "@mui/material";
import { PictureBoxHolder } from "../../common_components/PictureBoxHolder";

import CoinSimpleIcon from '../../../../../assets/sweep/icons/coinSimple.png'
import TetherSimpleIcon from '../../../../../assets/sweep/icons/tetherSimple.png'
import CardSimpleIcon from '../../../../../assets/sweep/icons/cardSimple.png'
import DepositIcon from '../../../../../assets/sweep/icons/deposit@4x.png'
import WithdrawIcon from '../../../../../assets/sweep/icons/withdraw@4x.png'
import DoneIcon from '../../../../../assets/sweep/icons/check.png'
import CancelIcon from '../../../../../assets/sweep/icons/cancel.png'
import FailIcon from '../../../../../assets/sweep/icons/fail.png'

interface TransactionTableData {
    id: number
    date: string
    sum: string
    method: 'USDT' | 'card' | 'cash'
    type: 'Withdraw' | 'Deposit'
    status: 'Done' | 'Fail' | 'Cancel'
}

const rows: TransactionTableData[] = [
    {
        id: 23323,
        date: '10.12.2022',
        sum: '800',
        method: 'USDT',
        type: 'Deposit',
        status: 'Done'
    },
    {
        id: 23323,
        date: '10.12.2022',
        sum: '1.555',
        method: 'card',
        type: 'Withdraw',
        status: 'Fail'
    },
    {
        id: 23323,
        date: '10.12.2022',
        sum: '60',
        method: 'card',
        type: 'Withdraw',
        status: 'Cancel'
    },
    {
        id: 23323,
        date: '10.12.2022',
        sum: '800',
        method: 'USDT',
        type: 'Deposit',
        status: 'Done'
    },
    {
        id: 23323,
        date: '10.12.2022',
        sum: '1.555',
        method: 'card',
        type: 'Withdraw',
        status: 'Fail'
    },
    {
        id: 23323,
        date: '10.12.2022',
        sum: '60',
        method: 'card',
        type: 'Withdraw',
        status: 'Cancel'
    },
];

interface TransfersSectionProps {
    size?: number
}

export const TransfersSection: FC<TransfersSectionProps> = ({size = 5}) => {
    const baseSize = 48 / size

    return <Box
        maxWidth={'60vh'}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-between'}
        rowGap={'2vh'}
        maxHeight={'60vh'}
    >
        <TableContainer
            sx={{

                width: '100%',
                borderRadius: `${12 / baseSize}vh`,
                border: `${3 / baseSize}vh solid rgba(76, 58, 179, 0.8)`,
                '& .MuiTableHead-root': {
                    backgroundColor: 'rgba(73, 39, 192, 0.65)',
                },
                '& .MuiTableCell-head': {
                    color: '#7061C7',
                    borderBottom: `${3 / baseSize}vh solid rgba(76, 58, 179, 0.8)`,
                    fontSize: `${12 / baseSize}vh`,
                    padding: `${8 / baseSize}vh ${16 / baseSize}vh`
                },
                '& .MuiTableBody-root': {
                    backgroundColor: 'rgba(22, 9, 67, 0.45)',
                    '& .MuiTableRow-root': {
                        '& .MuiTableCell-body:first-child': {
                            color: '#7061C7',
                        },
                        '& .MuiTableCell-body:not(:first-child)': {
                            fontWeight: 600
                        }
                    }
                },
                '& .MuiTableCell-body': {
                    borderBottom: 'none',
                    fontSize: `${12 / baseSize}vh`,
                padding: `${10 / baseSize}vh ${16 / baseSize}vh`
                },
            }}
        >
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Sum</TableCell>
                    <TableCell>Method</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Status</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map((row) => (
                    <TableRow
                        key={row.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row">
                            {row.date}
                        </TableCell>
                        <TableCell>
                            <Stack direction={'row'} alignItems={'center'} columnGap={1}>
                                <PictureBoxHolder picUrl={CoinSimpleIcon} heightUniversalPx={20}/>
                                {row.sum}
                            </Stack>
                        </TableCell>
                        <TableCell>
                            <Stack
                                width={'100%'}
                                direction={'row'}
                                justifyContent={'center'}
                            >
                                {<PictureBoxHolder picUrl={(() => {
                                    switch (row.method) {
                                        case 'USDT':
                                            return TetherSimpleIcon
                                        case 'card':
                                            return CardSimpleIcon
                                        default:
                                            return 'CoinSimpleIcon'
                                    }
                                })()} heightUniversalPx={24}/>}
                            </Stack>
                        </TableCell>
                        <TableCell>
                            <Stack direction={'row'} alignItems={'center'} columnGap={1}>
                                <PictureBoxHolder picUrl={(() => {
                                    switch (row.type) {
                                        case 'Deposit':
                                            return DepositIcon
                                        case 'Withdraw':
                                            return WithdrawIcon
                                        default:
                                            return WithdrawIcon
                                    }
                                })()} heightUniversalPx={24}/>
                                {row.type}
                            </Stack>
                        </TableCell>
                        <TableCell>
                            <Stack direction={'row'} alignItems={'center'} columnGap={1}>
                                <PictureBoxHolder picUrl={(() => {
                                    switch (row.status) {
                                        case 'Done':
                                            return DoneIcon
                                        case 'Fail':
                                            return FailIcon
                                        case 'Cancel':
                                            return CancelIcon
                                        default:
                                            return CancelIcon
                                    }
                                })()} heightUniversalPx={24}/>
                                <Typography
                                    fontSize={'inherit'}
                                    fontWeight={'inherit'}
                                    sx={{
                                        background: (() => {
                                            switch (row.status) {
                                                case 'Done':
                                                    return 'linear-gradient(0.00deg, rgb(68, 180, 68),rgb(234, 249, 68))'
                                                case 'Fail':
                                                    return 'linear-gradient(180.00deg, rgb(254, 85, 62),rgb(191, 31, 9))'
                                                case 'Cancel':
                                                    return 'linear-gradient(180.00deg, rgb(147, 131, 245),rgb(112, 97, 199))'
                                                default:
                                                    return 'white'
                                            }
                                        })(),
                                        "-webkit-background-clip": "text",
                                        color: 'transparent',
                                    }}
                                >
                                    {row.status}
                                </Typography>
                            </Stack>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
    </Box>
}