import { FC } from "react";
import { GameCardBase } from "../game_card/GameCardBase";
import TempGamePic0 from "../../../../assets/sweep/gamePictures/295b18158366921 1.png";
import { Box } from "@mui/material";
import TempGamePic from "../../../../assets/sweep/gamePictures/image 18.jpg";
import TempGamePic2 from "../../../../assets/sweep/gamePictures/image 20.jpg";
import TempGamePic3 from "../../../../assets/sweep/gamePictures/image 19.jpg";
import TempGamePic4 from "../../../../assets/sweep/gamePictures/image 21.jpg";

export const GamesFlex: FC = () => {
    return <Box
        display={'flex'}
        flexDirection={'column'}
        rowGap={'2vh'}
        px={'6vw'}
        sx={{
            overflow: 'visible',
        }}
    >
        <Box
            height={'29vh'}
            sx={{
                overflow: 'visible'
            }}
        >
            <GameCardBase imageUrl={TempGamePic0} green/>
        </Box>

        <Box
            height={'29vh'}
            sx={{
                overflow: 'visible'
            }}
        >
            <GameCardBase imageUrl={TempGamePic}/>
        </Box>

        <Box
            height={'29vh'}
            sx={{
                overflow: 'visible'
            }}
        >
            <GameCardBase imageUrl={TempGamePic2}/>
        </Box>
        <Box
            height={'29vh'}
            sx={{
                overflow: 'visible'
            }}
        >
            <GameCardBase imageUrl={TempGamePic3}/>
        </Box>
        <Box
            height={'29vh'}
            sx={{
                overflow: 'visible'
            }}
        >
            <GameCardBase imageUrl={TempGamePic4}/>
        </Box>

    </Box>
}