import React, { FC } from "react";
import { FormControl, MenuItem, Select } from "@mui/material";

interface SimpleGameDropdownProps {
    value: string
    setValue: (value: string) => void
    list: string[]
}

export const SimpleGameDropdown: FC<SimpleGameDropdownProps> = ({value, setValue, list}) => {
    return <FormControl
        sx={{
            "& .MuiInputBase-root": {
                borderRadius: '12px',
                backgroundColor: 'rgba(110,71,255,0.45)',
                '& fieldset': {
                    borderColor: '#3E29B7', // изменить цвет обводки
                    borderRadius: '12px',
                    borderWidth: '2px',
                    borderBoxSize: 'content-box',
                    boxShadow: 'none'
                },
            }
        }}
    ><Select
        value={value}
        onChange={e => setValue(e.target.value)}
        defaultValue={''}
    >
        {list.map((item) => <MenuItem value={item} key={item}>{item}</MenuItem>)}
    </Select></FormControl>
}