import { FC } from "react";
import { Box } from "@mui/material";

interface SmallButtonBaseProps {
    size?: number
    imageIconUrl: string
}

export const SmallButtonBase: FC<SmallButtonBaseProps> = ({size = 5, imageIconUrl}) => {
    const baseSize = 48 / size
    const borderWidth = 5
    const borderRadius = 8

    return <Box
        sx={{
            background: 'linear-gradient(0deg, #6AD118 0%, #BCFF2F 100%)',
        }}
        padding={`${borderWidth / baseSize}vh`}
        borderRadius={`${borderRadius / baseSize}vh`}
        boxShadow={`rgba(14, 0, 54, 0.65)`}
        position={'relative'}
    >
        <Box
            height={`${20 / baseSize}vh`}
            width={`${20 / baseSize}vh`}
            sx={{
                background: 'linear-gradient(-90deg, #6AD118 0%, #BCFF2F 100%)',
            }}
            borderRadius={`${(borderRadius - (borderWidth / 3 * 2)) / baseSize}vh`}
        />
        <Box
            position={'absolute'}
            top={0}
            bottom={0}
            left={0}
            right={0}
            sx={{
                background: `url(${imageIconUrl})`,
                backgroundSize: '110%',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: '30% 30%',
            }}
            borderRadius={`${borderRadius / baseSize}vh`}
        />
    </Box>
}