import { Box } from "@mui/material";
import Gift from "../../../../assets/sweep/Gift.webp";
import Dragon from "../../../../assets/sweep/Dragon.webp";
import { FC } from "react";
import s from './BottomCornersDecoration.module.css'

export const BottomCornersDecoration: FC = () => {
    return <>
        <Box
        position={'fixed'}
        bottom={0}
        left={0}
        height={'21vh'}
        display={'flex'}
        justifyContent={'start'}
        alignItems={'end'}
    >
        <img className={s.leftCorner} alt={'left gift picture'} height={'120%'} src={Gift}/>
    </Box>
    <Box
        position={'fixed'}
        bottom={0}
        right={0}
        height={'21vh'}
        // width={'30vh'}
        display={'flex'}
        justifyContent={'end'}
        alignItems={'end'}
    >
        <img className={s.rightCorner} alt={'left dragon picture'} height={'130%'} src={Dragon}/>
    </Box>
    </>
}