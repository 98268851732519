import { useAppDispatch, useAppSelector } from "../../store";
import { iUseTransactions, ShowMoneyTransfersResponse } from "./transactionModel";
import { useCallback } from "react";
import {
    setCurrentTransactionsPage,
    setIsTransactionsLoading, setTotalTransactionsRecords,
    setTransactions,
    setTransactionsPageSize
} from "./transactionsSlice";
import axios, { AxiosError } from "axios";
import Config from "../../config";
import { useLanguage } from "../localisation/useLanguage";
import { v4 as uuidv4 } from 'uuid';
import { useUser } from "../user/useUser";

export const useTransactions = (): iUseTransactions => {
    const { setLocalizedError, handleNetworkErrors } = useLanguage()
    const { token } = useUser()
    const dispatch = useAppDispatch()
    const state = useAppSelector(state => state.transactions)

    const getTransactions = useCallback((page: number, itemsPerPage: number = state.pageSize) => {
        let itemsPerPageToSend = state.pageSize
        if (itemsPerPage !== state.pageSize) {
            dispatch(setTransactionsPageSize(itemsPerPage))
            itemsPerPageToSend = itemsPerPage
        }
        let pageToSend = state.currentPage
        if (page !== state.currentPage) {
            dispatch(setCurrentTransactionsPage(page))
            pageToSend = page
        }

        if (token) {
            dispatch(setIsTransactionsLoading(true))
            const data = new FormData();
            data.append('action', 'ShowMoneyTransfers')
            data.append('token', token)

            data.append('page', pageToSend.toString())
            data.append('pagesize', itemsPerPageToSend.toString())

            axios.post<ShowMoneyTransfersResponse>(Config.apiHost, data)
                .then(response => {
                    const { success, error, data: moneyTransfers, total_transfers } = response.data
                    if (success) {
                        if (moneyTransfers) {
                            moneyTransfers.forEach(transfer => {
                                transfer.uuid = uuidv4()
                            })
                            dispatch(setTransactions(moneyTransfers))
                            dispatch(setCurrentTransactionsPage(page))
                        }
                        if (total_transfers) {
                            dispatch(setTotalTransactionsRecords(total_transfers))
                        }
                    }
                    if (error) {
                        if (error.code === 2) {
                            dispatch(setTransactions([]))
                            if (total_transfers && total_transfers > 0) {
                                setLocalizedError(error)
                            }
                        } else {
                            setLocalizedError(error)
                        }
                    }
                })
                .catch((error: Error | AxiosError) => {
                    dispatch(setTransactions([]))
                    handleNetworkErrors(error)
                })
                .finally(() => {
                    dispatch(setIsTransactionsLoading(false))
                })
        }
    }, [ dispatch, handleNetworkErrors, setLocalizedError, state.currentPage, state.pageSize, token ])

    return { ...state, getTransactions }
}
