import { FC, ReactNode } from "react";
import { Typography } from "@mui/material";

interface WhiteShadowedTextProps {
    children: ReactNode
    size?: string
    shadowSize?: number
}

export const WhiteShadowedText: FC<WhiteShadowedTextProps> = ({children, size= '2.9vh', shadowSize = 5}) => {
    const baseSize = 48 / shadowSize

    const shadowsList: string[] = [
        `0 -${4 / baseSize}vh ${12 / baseSize}vh rgba(255,255,255, 0.15)`,
        `0 ${4 / baseSize}vh ${4 / baseSize}vh rgba(0,27,97, 0.65)`
    ]

    const shadows = shadowsList.join(', ')

    return <>
        <Typography
            fontSize={size}
            fontWeight={700}
            sx={{
                textShadow: shadows
            }}
    >
        {children}
    </Typography>
    </>
}