import { FC } from "react";
import { Box } from "@mui/material";

interface TabItemProps {
    size?: number
    borderRadius?: string
    positionInPanel?: 'left' | 'center' | 'right'
    title: string
    active?: boolean
    onClick?: () => void
}

export const TabItem: FC<TabItemProps> = ({size = 5, borderRadius, positionInPanel = 'center', title, active = false, onClick = () => {}}) => {
    const baseSize = 48 / size
    const radius = borderRadius ?? `${12 / baseSize}vh`

    const shadowList = [
        `0 ${1 / baseSize}vh 0 #56AEFF`,
        `0 ${4 / baseSize}vh 0 #0E4BB9`,
        `0 ${6 / baseSize}vh 0 #1558D0`,
    ]

    const shadows = shadowList.join(', ')

    const getRadius = () => {
        switch (positionInPanel) {
            case 'left':
                return `${radius} 0 0 ${radius}`
            case 'right':
                return `0 ${radius} ${radius} 0`
            default:
                return '0'
        }
    }

    return <Box
        className={'pointer'}
        onClick={onClick}
        borderRadius={getRadius()}
        flex={1}
        mb={`${6 / baseSize}vh`}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        px={'2vh'}
        border={`${2 / baseSize}vh solid rgba(186, 218, 255, 0.35)`}
        boxShadow={shadows}
        sx={{
            background: 'linear-gradient(0.00deg, #3EA2FE 0%, #0952BF 100%)',
            opacity: active ? '1' : '0.45'
        }}
    >
        {title}
    </Box>
}