import { FC } from "react";
import { Box } from "@mui/material";
import CheckIcon from '../../../../../assets/sweep/icons/check.png'

interface GameCheckBoxProps {
    isChecked?: boolean
    onStateChanged?: (isChecked: boolean) => void
    disabled?: boolean
    size?: number
}

export const GameCheckBox: FC<GameCheckBoxProps> = ({isChecked = false, onStateChanged = () => {}, disabled = false, size = 5}) => {
    const baseSize = 48 / size
    const sideSize = `${26 / baseSize}vh`

    return <Box
        onClick={!disabled ? () => onStateChanged(!isChecked) : undefined}
        borderRadius={sideSize}
        width={sideSize}
        height={sideSize}
        sx={{
            opacity: disabled ? '0.35' : '1',
            backgroundColor: 'rgba(0, 0, 0, 0.35)'
        }}
    >
        <Box
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            borderRadius={sideSize}
            border={`${2 / baseSize}vh solid rgba(255, 255, 255, 0.25)`}
            width={'100%'}
            height={'100%'}
            className={'pointer'}
            sx={{
                backgroundImage: isChecked ? `url(${CheckIcon})` : 'none',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
            }}
        />
    </Box>
}