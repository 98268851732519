import React, { FC, useState } from "react";
import { GameDialog } from "../game_dialog_windows/GameDialog";
import { Box, Typography } from "@mui/material";
import { WhiteShadowedText } from "../text/WhiteShadowedText";
import { GameTextField } from "../game_text_field/GameTextField";
import { GameButtonFilled } from "../button/GameButtonFilled";
import { Color } from "../button/game_button_filled_base/GameButtonFilledBase";
import { UniversalSwitcher } from "../switcher/UniversalSwitcher";
import { TermsAndCondition } from "../terms_n_condition/TermsAndCondition";

interface LobbySettingsProps {
    onClose?: () => void
}

export const LobbySettings:FC<LobbySettingsProps> = ({ onClose = () => {} }) => {
    const [isTermsAndConditionOpened, setIsTermsAndConditionOpened] = useState<boolean>(false)

    return <GameDialog onClose={onClose}>
        <Box
            display={'flex'}
            flexDirection={'column'}
            rowGap={'2vh'}
            minWidth={'45vh'}
            maxHeight={'80vh'}
        >
            <Box
                display={'flex'}
                columnGap={'3vh'}
                alignItems={'center'}
                pb={'2vh'}
            >
                <WhiteShadowedText>
                    Setting up a user lobby
                </WhiteShadowedText>
            </Box>

            <Box
                display={'flex'}
                flexDirection={'column'}
                rowGap={'1vh'}
            >
                <Typography>Member since</Typography>
                <GameTextField
                    placeholder={'https://membersince.bang#112543'}
                    type={'text'}
                />
            </Box>

            <Box
                display={'flex'}
                flexDirection={'column'}
                rowGap={'1vh'}
            >
                <Typography>Player ID</Typography>
                <GameTextField
                    placeholder={'275080'}
                    type={'text'}
                />
            </Box>

            <Box
                display={'flex'}
                columnGap={'1vh'}
                py={'3vh'}
            >
                <Box flex={1}>
                    <UniversalSwitcher firstText={'On'} secondText={'Off'} firstSelected={true}/>
                </Box>
                <Box flex={1}>
                    <UniversalSwitcher firstText={'On'} secondText={'Off'} firstSelected={false}/>
                </Box>
            </Box>

            <Box
                display={'flex'}
                columnGap={'1vh'}
                py={'3vh'}
            >
                <Box flex={1}>
                    <Typography fontWeight={300} fontSize={'1.6vh'} sx={{textDecoration: 'underline'}} color={'rgba(255,255,255, 0.6)'} className={'cursor-pointer'}>
                        Privacy Policy<br/>
                        <span onClick={() => setIsTermsAndConditionOpened(true)}>Terms of Use</span><br/>
                        How it works
                    </Typography>
                </Box>

                <Box flex={1}>
                    <GameButtonFilled color={Color.blue}>
                        Get Help
                    </GameButtonFilled>
                </Box>
            </Box>


            <GameButtonFilled color={Color.red}>
                Log Out
            </GameButtonFilled>

        </Box>

        {isTermsAndConditionOpened && <TermsAndCondition onClose={() => setIsTermsAndConditionOpened(false)}/>}
    </GameDialog>
}