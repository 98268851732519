import { FC, useState } from "react";
import { FirstCoinsRedeemSection } from "./FirstCoinsRedeemSection";
import { SecondCoinsRedeemScreen } from "./SecondCoinsRedeemScreen";

export const RedeemSection: FC = () => {
    const [activeScreenIndex, setActiveScreenIndex] = useState<number>(0);

    return <>
        <FirstCoinsRedeemSection hidden={activeScreenIndex !== 0} onProceed={() => setActiveScreenIndex(1)}/>
        <SecondCoinsRedeemScreen hidden={activeScreenIndex !== 1} onBack={() => setActiveScreenIndex(0)} onProceed={() => setActiveScreenIndex(0)}/>
    </>;
}