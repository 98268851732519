import { FC } from "react";
import logoShort from '../../../assets/spinspectra-short.svg'
import logoLong from '../../../assets/spinspectra-long.svg'
import { Box, useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";

export const Logo: FC = () => {
    const smallScreens = useMediaQuery('(min-width:500px)')

    return <Link to={'/'}>
        <Box display={'flex'} alignItems={'end'} columnGap={'10px'}>
        <img src={logoShort} height={'32px'} alt={'spin spectra short logo'}/>
            {smallScreens && <img src={logoLong} height={'18px'} alt={'spin spectra long logo'}/>}
    </Box>
    </Link>
}