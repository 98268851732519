const defaultIntermediatePagesLimit = 3

export const getPagesBeforeCurrent = (currentPage: number, intermediatePagesLimit: number = defaultIntermediatePagesLimit): number[] => {
    const pagesBeforeCurrent: number[] = [];
    let page = currentPage - 1;

    while (page > 1 && pagesBeforeCurrent.length < intermediatePagesLimit) {
        pagesBeforeCurrent.unshift(page);
        page--;
    }

    return pagesBeforeCurrent;
}

export const getPagesAfterCurrent = (currentPage: number, lastPage: number, intermediatePagesLimit: number = defaultIntermediatePagesLimit): number[] => {
    const pagesAfterCurrent: number[] = [];
    let page = currentPage + 1;

    while (page < lastPage && pagesAfterCurrent.length < intermediatePagesLimit) {
        pagesAfterCurrent.push(page);
        page++;
    }

    return pagesAfterCurrent;
}