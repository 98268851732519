import { FC } from "react";
import { Box } from "@mui/material";
import { HexagonButton } from "./HexagonButton";
import Typography from "@mui/material/Typography";

interface HexagonButtonProps {
    size?: number
    iconImageUrl: string
    title: string
}

export const HexagonButtonLong: FC<HexagonButtonProps> = ({size = 5, iconImageUrl, title}) => {
    // const baseSize = 48 / size

    return <Box
        display={'flex'}
        alignItems={'center'}
        columnGap={'2vh'}
        ml={'4vh'}
        sx={{
            background: "linear-gradient(-90deg, rgba(16,9,58, 0) 0%, rgba(16,9,58, 0.45) 100%)"
        }}
    >
        <Box
            ml={'-4vh'}
        >
            <HexagonButton iconImageUrl={iconImageUrl} size={size}/>
        </Box>
        <Typography textTransform={'uppercase'}>
            {title}
        </Typography>
    </Box>
}