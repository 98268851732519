import React, { FC } from "react";
import { Box } from "@mui/material";
import { PaymentMethodCard } from "../../payment_method/PaymentMethodCard";
import { WarningBox } from "../../info_boxes/WarningBox";
import { GameButtonFilled } from "../../button/GameButtonFilled";

interface FirstCoinsRedeemSectionProps {
    onProceed?: () => void
    hidden?: boolean
}

export const FirstCoinsRedeemSection: FC<FirstCoinsRedeemSectionProps> = ({onProceed= () => {}, hidden = false}) => {
    return <Box
        maxWidth={'50vh'}
        display={hidden ? 'none' : 'flex'}
        flexDirection={'column'}
        justifyContent={'space-between'}
        rowGap={'2vh'}
        maxHeight={'60vh'}
    >
        <Box
            // maxWidth={'55vh'}
            display={'flex'}
            columnGap={'2vh'}
            py={'0.5vh'}
            sx={{
                overflowX: 'auto',
                scrollbarWidth: 'thin',
                scrollbarColor: 'rgba(255, 255, 255, 0.08) transparent',
            }}
        >
            <PaymentMethodCard paymentMethod={'crypto'}/>
            <PaymentMethodCard paymentMethod={'card'}/>
            <PaymentMethodCard paymentMethod={'cash'}/>
        </Box>

        <WarningBox>
            Card is currently disabled and will be back online shortly
        </WarningBox>

        <GameButtonFilled onClick={() => onProceed()}>
            Proceed with order
        </GameButtonFilled>
    </Box>
}