import { FC, useState } from "react";
import { Box } from "@mui/material";
import { TopBarBottomShape } from "./TopBarBottomShape";
import jadeSweepLogo from "../../../../assets/sweep/jade-sweep-logo.png";
import ShopIcon from '../../../../assets/sweep/icons/ShopIcon.webp'
import SettingsIcon from '../../../../assets/sweep/icons/SettingIcon.webp'
import ExitIcon from '../../../../assets/sweep/icons/ExitIcon.webp'
import { ButtonWithIcon } from "../button/ButtonWithIcon";
import { Switcher } from "../switcher/Switcher";
import GreenCoin from "../../../../assets/sweep/icons/GreenCoin.png";
import GoldenCoin from "../../../../assets/sweep/icons/GoldenCoin.png";
import { UserTitle } from "../avatar/UserTitle";
import { WhiteShadowedText } from "../text/WhiteShadowedText";
import { NotificationIndicator } from "../notification/NotificationIndicator";
import { LoginDialog } from "../login_dialog/LoginDialog";
import { useCommonMediaqueries } from "../../../features/mediaQueries/useCommonMediaqueries";
import DrawerIcon from "../../../../assets/sweep/icons/MenuIcon.webp"
import { GameDrawer } from "../drawer/GameDrawer";
import { UserProfile } from "../user_profile/UserProfile";
import { ReadNotifications } from "../notification/read_notifications/ReadNotifications";
import { LobbySettings } from "../lobby_settings/LobbySettings";
import { WalletWindow } from "../wallet/WalletWindow";

const makeShadows = () => {
    const shadows = [
        `0px 0.5vh 0px 0px rgb(28, 15, 97)`,
        `0px 0.7vh 0px 0px rgb(33, 18, 112)`,
        `0px 0.6vh 2.1vh 0px rgba(14, 0, 38, 0.25)`,
        `0px 0.36vh 3.4vh 0px rgb(14, 0, 38)`
    ]
    return shadows.join(', ')
}

export const TopBar: FC = () => {
    const topBarHeight = 10
    const [funEnabled, setFunEnabled] = useState<boolean>(true)
    const [loginOpened, setLoginOpened] = useState<boolean>(false)
    const {portraitMode} = useCommonMediaqueries()
    const [isDrawerOpened, setIsDrawerOpened] = useState<boolean>(true)
    const [isUserProfileOpened, setIsUserProfileOpened] = useState<boolean>(false)
    const [isNotificationWindowOpened, setIsNotificationWindowOpened] = useState<boolean>(false)
    const [isLobbySettingsOpened, setIsLobbySettingsOpened] = useState<boolean>(false)
    const [isWalletOpened, setIsWalletOpened] = useState<boolean>(false)

    return <>
        <Box
        borderRadius={'3.5vh 3.5vh 0 0'}
        position={'fixed'}
        top={0}
        left={0}
        right={0}
        height={`${topBarHeight}vh`}
        sx={{background: 'linear-gradient(0.00deg, rgb(38, 23, 123) 0%,rgb(51, 28, 175) 133.451%)'}}
        boxShadow={makeShadows()}
    >
        <Box
            height={'100%'}
            position={'relative'}
            display={'flex'}
            justifyContent={'center'}
        >
            <Box
                position={'absolute'}
                top={0}
                left={0}
                right={0}
                bottom={'-8px'}
            >

            </Box>
            <Box
                position={'absolute'}
                top={`${topBarHeight * 0.95}vh`}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'start'}
                height={`${topBarHeight}vh`}
            >
                <TopBarBottomShape height={topBarHeight / (portraitMode ? 1.25 : 1)}/>
            </Box>
            <Box
                position={'absolute'}
                top={0}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'start'}
                height={`${topBarHeight * 2}vh`}
            >
                <img alt={'Jade Sweep Logo'} height={portraitMode ? '110%' : '120%'} src={jadeSweepLogo} />
            </Box>
            <Box
                position={'absolute'}
                top={0}
                left={0}
                right={0}
                bottom={0}
                padding={'1.8vh 4vw 1.6vh'}
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
            >
                <Box
                    display={'flex'}
                    columnGap={'1.7vw'}
                    alignItems={'center'}
                >
                    {/*---------- Left section---------*/}

                    <UserTitle compact={portraitMode} onClick={() => setIsUserProfileOpened(true)}/>

                    <NotificationIndicator onClick={() => setIsNotificationWindowOpened(true)}/>

                    {!portraitMode && <WhiteShadowedText>
                        id: 27503222
                    </WhiteShadowedText>}
                </Box>

                <Box
                    display={'flex'}
                    columnGap={'1.7vw'}
                    alignItems={'center'}
                >
                    {/*---------- Right section---------*/}
                    {!portraitMode ? <>
                        <ButtonWithIcon green iconURL={ShopIcon} iconAlt={'Shop icon'} onClick={() => setIsWalletOpened(true)}/>
                        <Switcher
                            onSwitch={() => setFunEnabled(!funEnabled)}
                            leftIsActive={funEnabled}
                            leftText={'100'}
                            leftIcon={GreenCoin}
                            rightText={'790'}
                            rightIcon={GoldenCoin}
                        />

                        <ButtonWithIcon iconURL={SettingsIcon} iconAlt={'Settings icon'} onClick={() => setIsLobbySettingsOpened(true)}/>
                        <ButtonWithIcon iconURL={ExitIcon} iconAlt={'Exit icon'} onClick={() => setLoginOpened(true)}/>
                    </> :
                        <ButtonWithIcon iconURL={DrawerIcon} iconAlt={'Drawer icon'} onClick={() => setIsDrawerOpened(true)}/>
                    }

                </Box>
            </Box>
        </Box>
    </Box>
        {isWalletOpened && <WalletWindow onClose={() => setIsWalletOpened(false)}/>}

        {isLobbySettingsOpened && <LobbySettings onClose={() => setIsLobbySettingsOpened(false)}/>}

        {isNotificationWindowOpened && <ReadNotifications onClose={() => setIsNotificationWindowOpened(false)}/>}

        {isUserProfileOpened && <UserProfile onClose={() => setIsUserProfileOpened(false)}/>}

        {loginOpened && <LoginDialog onClose={() => setLoginOpened(false)}/>}

        {portraitMode && <GameDrawer setLoginOpened={setLoginOpened} isDrawerOpened={isDrawerOpened} setIsDrawerOpened={setIsDrawerOpened}/>}
        </>
}