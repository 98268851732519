import { FC } from "react";
import { Box } from "@mui/material";
import { BoardBase } from "../board/BoardBase";
import slashedLine from "../../../../assets/sweep/slash-line.png";
import RightIcon from "../../../../assets/sweep/icons/RightIcon.webp";

export const RightVerticalButton: FC = () => {
    return <Box
        width={'10vw'}
        minWidth={'10vh'}
        maxWidth={'12vh'}
        height={'100%'}
        position={'relative'}
        className={'pointer'}
    >
        <Box
            position={'absolute'}
            top={0}
            right={0}
            left={0}
            bottom={0}
            sx={{
                opacity: '0.45'
                // backgroundColor: '#21b408'
            }}>
            <BoardBase height={'100%'} width={'100%'}>
                <Box
                    height={'100%'}
                    width={'100%'}
                    sx={{
                        background: `url(${slashedLine}), linear-gradient(0.00deg, rgb(38, 23, 123) 0%,rgb(51, 28, 175) 133.451%)`,
                        // backgroundImage: `url(${slashedLine})`,
                        backgroundSize: 'contain',
                        backgroundBlendMode: 'overlay',
                    }}
                >

                </Box>
                {/*<ButtonWithIcon iconURL={settingsIcon} iconAlt={'Settings icon'}/>*/}
            </BoardBase>
        </Box>
        <Box position={'absolute'} top={0} right={0} left={0} bottom={0} display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <Box width={'6vh'} height={'6vh'} display={'flex'} justifyContent={'center'} alignItems={'center'} pb={'1vh'}>
                <img alt={'right icon button'} src={RightIcon} height={'200%'}/>
            </Box>

        </Box>

    </Box>
}