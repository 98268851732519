import { FC } from "react";
import { GameDialog } from "../game_dialog_windows/GameDialog";
import { Box, Typography } from "@mui/material";
import { JadeSweepDialogLogo } from "../game_dialog_windows/JadeSweepDialogLogo";
import { Green3dTitle } from "../text/Green3dTitle";
import { GameButtonFilled } from "../button/GameButtonFilled";
import { Color } from "../button/game_button_filled_base/GameButtonFilledBase";

interface TermsAndConditionProps {
    onClose?: () => void
}

export const TermsAndCondition: FC<TermsAndConditionProps> = ({ onClose = () => {} }) => {
    return <GameDialog onClose={onClose}>
        <Box
            display={'flex'}
            flexDirection={'column'}
            rowGap={'2vh'}
        >
            <JadeSweepDialogLogo/>

            <Green3dTitle size={'5vmin'} fontWeight={400}>
                <Box display={'flex'} justifyContent={'center'}>Terms & conditions</Box>
            </Green3dTitle>

            <Typography fontWeight={300} component={'article'} fontSize={'1.8vmin'} sx={{opacity: '0.4', maxHeight: '30vh', overflow: 'auto'}}>
                <p>
                    These Terms and Conditions (hereinafter referred to as the “Agreement”) govern the contractual relationship between the parties, Overplayed N.V. on the one hand (hereinafter referred to as “Overplayed”, “We” or “Our”) and by you as a user on the other hand (hereinafter referred to as “User”, “You” or “Your”), collectively the “Parties”.
                </p>
                <p>
                    Overplayed is a company registered and incorporated under the laws of Curacao, with registration number 151342 (0) and registered address at Abraham de Veerstraat, Curacao. “User” means the person who agrees to these Terms and Conditions. By accepting this Agreement, the User
                </p>
            </Typography>

            <Box
                display={'flex'}
                width={'100%'}
                columnGap={'2.5vh'}
            >
                <GameButtonFilled color={Color.red}>
                    Leave
                </GameButtonFilled>
                <GameButtonFilled>
                    Agree
                </GameButtonFilled>
            </Box>
        </Box>
        </GameDialog>
}