import { FC, ReactNode } from "react";
import { Box } from "@mui/material";

interface ToggleKnobProps {
    size?: number
    children?: ReactNode
    blueShadow?: boolean
}

export const ToggleKnob: FC<ToggleKnobProps> = ({size = 5, children, blueShadow = false}) => {
    const baseSize = 48 / size

    const colorShadow = blueShadow ? '#071F9B' : '#0D9000'

    const shadowsList = [
        `0 ${2 / baseSize}vh 0 #B7B9EE`,
        `0 ${4 / baseSize}vh ${8 / baseSize}vh ${colorShadow}`,
    ]

    const shadows = shadowsList.join(', ')

    return<Box
        height={'100%'}
        pb={`${2 / baseSize}vh`}
    >
        <Box
            height={'100%'}
            sx={{
                background: `linear-gradient(0deg, #E4EAFF 0%, #fff 100%)`
            }}
            borderRadius={`${6 / baseSize}vh`}
            boxShadow={shadows}
            border={`${1 / baseSize}vh solid #fff`}
            display={'flex'}
            alignItems={'center'}
        >
            <Box flexGrow={1}>
                {children}
            </Box>
            <Box
                display={'flex'}
                columnGap={`${2 / baseSize}vh`}
                paddingX={`${4 / baseSize}vh`}
            >
                <Shape size={size}/>
                <Shape size={size}/>
            </Box>
        </Box>
    </Box>
}

interface ShapeProps {
    size?: number
}

const Shape: FC<ShapeProps> = ({size = 5}) => {
    const baseSize = 48 / size

    return <Box
        height={`${18 / baseSize}vh`}
        width={`${4 / baseSize}vh`}
        sx={{
            background: `linear-gradient(0deg, #D0DEF2 0%, #DEE9F9 100%)`
        }}
        borderRadius={`${2 / size}vh`}
        border={`${2 / baseSize}vh solid #fff`}
        boxSizing={'content-box'}
    />
}