export const contourColor = '#37D9FC'
export const overlayGradient = 'linear-gradient(180deg, rgba(55,217,252,0) 70%, #37D9FC 100%)'

export const getContainerShadows = (baseSize: number): string => {
    const shadowsList: string[] = [
        `0 0 ${12 / baseSize}vh rgba(47, 243, 255, 0.35)`, // outer frame glowing
        `0 0 ${24 / baseSize}vh rgba(0, 240, 255, 0.45)`, // thin outer frame glowing

        `inset 0 ${2 / baseSize}vh 0 #4CFBFD`, // frame bevel
    ]
    return shadowsList.join(', ')
}

export const getInnerBoxShadows = (baseSize: number): string => {
    const shadowsList: string[] = [
        `inset 0 0 ${1 / baseSize}vh #003BD3`, // thin dark contour inside frame
        `inset 0 ${2 / baseSize}vh ${4 / baseSize}vh #0041EA`, // shadow inside frame

        `inset 0 0 ${12 / baseSize}vh rgba(47, 243, 255, 0.35)`, // inner frame glowing

        `0 ${2 / baseSize}vh 0 #4CFBFD`, // frame bevel
    ]
    return shadowsList.join(', ')
}
