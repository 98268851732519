import { FC, ReactNode } from "react";
import { MainLayoutLandscape } from "./MainLayoutLandscape";
import { useCommonMediaqueries } from "../../../features/mediaQueries/useCommonMediaqueries";
import { MainLayoutPortrait } from "./MainLayoutPortrait";

interface MainLayoutProps {
    children?: ReactNode
}

export const MainLayout: FC<MainLayoutProps> = ({children}) => {
    const {portraitMode} = useCommonMediaqueries()

    return !portraitMode ? <MainLayoutLandscape>{children}</MainLayoutLandscape> : <MainLayoutPortrait>{children}</MainLayoutPortrait>
}