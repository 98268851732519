import { FC } from "react";
import { Box } from "@mui/material";
import {
    contourColor as blueContourColor,
    getContainerShadows as getBlueContainerShadows,
    getInnerBoxShadows as getBlueInnerBoxShadows,
    overlayGradient as blueOverlayGradient
} from './blueGameCardStyle'
import { contourColor as greenContourColor,
    getContainerShadows as getGreenContainerShadows,
    getInnerBoxShadows as getGreenInnerBoxShadows,
    overlayGradient as greenOverlayGradient
} from './greenGameCardStyle'

interface GameCardBaseProps {
    size?: number
    green?: boolean
    imageUrl: string
}

export const GameCardBase: FC<GameCardBaseProps> = ({imageUrl, size = 5, green = false}) => {
    const baseSize = 48 / size
    const borderWidth = 6

    return <Box
        height={'100%'}
        width={'100%'}
        position={'relative'}
        borderRadius={`${24 / baseSize}vh`}
        boxShadow={!green ? getBlueContainerShadows(baseSize) : getGreenContainerShadows(baseSize)}
        sx={{
            backgroundColor: !green ? blueContourColor : greenContourColor,
            overflow: 'visible'
        }}
    >
        <Box
            borderRadius={`${(24 - (borderWidth / 3 * 2)) / baseSize}vh`}
            top={`${borderWidth / baseSize}vh`}
            bottom={`${borderWidth / baseSize}vh`}
            left={`${borderWidth / baseSize}vh`}
            right={`${borderWidth / baseSize}vh`}
            position={'absolute'}
            boxShadow={!green ? getBlueInnerBoxShadows(baseSize) : getGreenInnerBoxShadows(baseSize)}
            sx={{
                background: `${!green ? blueOverlayGradient : greenOverlayGradient}, url('${imageUrl}')`,
                backgroundSize: '100%',
                backgroundRepeat: 'no-repeat'
            }}
        />
    </Box>
}