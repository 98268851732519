import { FC, useState, useEffect } from "react";
import { Box } from "@mui/material";

import MessageIconOn from '../../../../assets/sweep/icons/notificationHaveMessage.png'
import MessageIconOff from '../../../../assets/sweep/icons/notificationNoMessage.png'

interface NotificationIndicatorProps {
    onClick?: () => void
}

export const NotificationIndicator: FC<NotificationIndicatorProps> = ({onClick = () => {}}) => {
    const [hasMessage, setHasMessage] = useState<boolean>(true);

    useEffect(() => {
        const interval = setInterval(() => {
            setHasMessage(prevHasMessage => !prevHasMessage);
        }, 500); // Toggle every second

        return () => clearInterval(interval);
    }, []); // Runs only once on component mount

    return <Box
        className={'pointer'}
        onClick={onClick}
        width={'5vh'}
        height={'5vh'}
        sx={{
            background: `url('${hasMessage ? MessageIconOn : MessageIconOff}')`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center'
        }}
    />;
}