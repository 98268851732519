import { FC, ReactNode } from "react";
import { Box, Typography } from "@mui/material";
import { Color, GameButtonFilledBase } from "./game_button_filled_base/GameButtonFilledBase";

interface GameButtonFilledProps {
    children?: ReactNode
    green?: boolean
    onClick?: () => void
    size?: number
    color?: Color
    disabled?: boolean
}

export const GameButtonFilled: FC<GameButtonFilledProps> = ({children, onClick = () => {}, size = 5, color, disabled = false}) => {
    const baseSize = 48 / size
    const verticalPadding = 16

    return <Box
        width={'100%'}
        className={'cursor-pointer'}
        position={'relative'}
        display={'flex'}
        px={`${22 / baseSize}vh`}
        pt={`${verticalPadding / baseSize}vh`}
        pb={`${(verticalPadding + 10) / baseSize}vh`}
        onClick={onClick}
        justifyContent={'center'}
        textAlign={'center'}
        sx={{
            userSelect: 'none',
        }}
    >
        <Box position={'absolute'} top={0} left={0} right={0} bottom={`${10 / baseSize}vh`}>
            <GameButtonFilledBase size={size} color={disabled ? Color.gray : color} width={'100%'} height={'100%'}/>
        </Box>
        <Box
            zIndex={100}
        >
            <Typography fontSize={'16px'} fontWeight={600}>
                {children}
            </Typography>
        </Box>
    </Box>
}