import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { AvatarButton } from "./AvatarButton";

interface UserTitleProps {
    compact?: boolean
    onClick?: () => void
}

export const UserTitle: FC<UserTitleProps> = ({compact = false, onClick = () => {}}) => {
    return <Box
        onClick={onClick}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        columnGap={'2vh'}
    >
        <AvatarButton/>

        {!compact && <Box>
            <Typography
                fontSize={'1.8vh'}
                lineHeight={1}
                fontWeight={700}
            >
                Welcome,
            </Typography>
            <Typography
                fontSize={'2.9vh'}
                lineHeight={1}
                fontWeight={900}
                sx={{
                    background: 'linear-gradient(to top, #44B444, #EAF944)',
                    "-webkit-background-clip": "text",
                    color: 'transparent'
                }}
            >
                Aleksandr
            </Typography>
        </Box>}
    </Box>
}