import { FC, ReactNode } from "react";
import { Stack, useMediaQuery } from "@mui/material";

interface CenteredListProps {
    children?: string|ReactNode|null
}

export const CenteredList: FC<CenteredListProps> = ({children}) => {
    const normalScreens = useMediaQuery('(min-width:500px)');

    return <Stack direction={'column'} rowGap={'4px'} alignItems={'center'} width={normalScreens ? '400px' : '100%'}>{children}</Stack>
}