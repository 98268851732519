export const getBlueContainerShadows = (baseSize: number): string => {
    const containerShadowsList: string[] = [
        `0 ${1 / baseSize}vh 0 #002BBB`,
        `0 ${4 / baseSize}vh 0 #1F44BE`,
        `0 ${6 / baseSize}vh 0 #2C56E0`,
        `0 ${7 / baseSize}vh ${14 / baseSize}vh rgba(14,0,54, 0.65)`,
        `inset 0 ${2 / baseSize}vh 0 #4CFBFD`,
        `0 -${4 / baseSize}vh ${16 / baseSize}vh #00F0FF`,
        `0 -${3 / baseSize}vh ${8 / baseSize}vh rgba(47, 243, 255, 0.45)`
    ]
    return  containerShadowsList.join(', ')
}

export const getBlueInnerContainerShadows = (baseSize: number): string => {
    const innerContainerShadowsList: string[] = [
        `inset 0 ${1 / baseSize}vh ${1 / baseSize}vh #003BD3`,
        `inset 0 ${2 / baseSize}vh ${4 / baseSize}vh #0041EA`,
        `0 ${2 / baseSize}vh 0 #4CFBFD`,
    ]

    return  innerContainerShadowsList.join(', ')
}

export const backgroundGradient = 'linear-gradient(0.00deg, #3EAAFE 0%, #095DBF 100%)'

export const contourColor = '#37D9FC'