import { FC, ReactNode } from "react";
import { Box } from "@mui/material";
import WarningIcon from "../../../../assets/sweep/icons/Warning.png";
import { SubtleText } from "../text/SubtleText";

interface WarningBoxProps {
    size?: number
    children?: string | ReactNode
}


export const WarningBox: FC<WarningBoxProps> = ({size = 5, children}) => {
    const baseSize = 48 / size

    return <Box
        display={'flex'}
        columnGap={'1vh'}
        border={`${3 / baseSize}vh dashed #3E29B7`}
        borderRadius={`${12 / baseSize}vh`}
        py={`${12 / baseSize}vh`}
        px={`${24 / baseSize}vh`}
        sx={{
            backgroundColor: 'rgba(22, 9, 67, 0.45)'
        }}
    >
        <Box>
            <Box
                width={`${24 / baseSize}vh`}
                height={`${24 / baseSize}vh`}
                sx={{
                    pointerEvents: 'none',
                    backgroundImage: `url(${WarningIcon})`,
                    backgroundPosition: 'center top',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                }}
            />
        </Box>

        <Box
            display={'flex'}
            alignItems={'center'}
        >
            <SubtleText color={'#6f55ff'} size={`${16 / baseSize}vh`}>
                {children}
            </SubtleText>
        </Box>
    </Box>
}