import { FC, ReactNode } from "react";
import { Box } from "@mui/material";
import { useCommonMediaqueries } from "../../../features/mediaQueries/useCommonMediaqueries";

interface ContentCenterProps {
    children: ReactNode
}

export const ContentCenter: FC<ContentCenterProps> = ({children}) => {
    const {portraitMode} = useCommonMediaqueries()

    return portraitMode ?
        <Box
            position={'fixed'}
            left={0}
            right={0}
            top={'18vh'}
            bottom={'12vh'}
            width={'100vw'}
            sx={{
                overflow: 'visible',
                overflowY: 'auto',
                maskImage: "linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 5%, rgba(0, 0, 0, 1) 95%, rgba(0, 0, 0, 0) 100%)"

        }}
        >
            {children}
        </Box>
        : <Box
        flex={5}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
    >
        {children}
    </Box>
}