export const getGreenContainerShadows = (baseSize: number): string => {
    const containerShadowsList: string[] = [
        `0 ${1 / baseSize}vh 0 #25BB00`,
        `0 ${4 / baseSize}vh 0 #48BE1F`,
        `0 ${6 / baseSize}vh 0 #5BE02C`,
        `0 ${7 / baseSize}vh ${14 / baseSize}vh rgba(14,0,54, 0.65)`,
        `inset 0 ${2 / baseSize}vh 0 #CDFF8E`,
        `0 -${4 / baseSize}vh ${16 / baseSize}vh #33FF00`,
        `0 -${3 / baseSize}vh ${8 / baseSize}vh rgba(113,255,47, 0.45)`
    ]
    return  containerShadowsList.join(', ')
}

export const getGreenInnerContainerShadows = (baseSize: number): string => {
    const innerContainerShadowsList: string[] = [
        `inset 0 ${1 / baseSize}vh ${1 / baseSize}vh #0C9D00`,
        `inset 0 ${2 / baseSize}vh ${4 / baseSize}vh #0EB100`,
        `0 ${2 / baseSize}vh 0 #CDFF8E`,
    ]

    return  innerContainerShadowsList.join(', ')
}

export const backgroundGradient = 'linear-gradient(0.00deg, #9EFE3E 0%, #0CBF09 100%)'
export const shadedBackgroundGradient = 'linear-gradient(0.00deg, #21B815 0%, #097D00 100%)'

export const contourColor = '#8EFC37'