 import { OutcomeGame, OutcomeProvider } from "./game";


 // Function that transforms an array of OutcomeGame into an array of OutcomeProvider
 export const transformGamesToOutcomeProviders = (outcomeGames: OutcomeGame[]): OutcomeProvider[] => {
    // Array to store the OutcomeProvider objects
    const outcomeProviders: OutcomeProvider[] = [];

    // Iterate through each game in the input array
    outcomeGames.forEach(game => {
        // Check if an OutcomeProvider with the same providerName already exists
        const existingProvider = outcomeProviders.find(provider => provider.providerName === game.SectionId);

        if (existingProvider) {
            // If the OutcomeProvider already exists, add the current game to its list
            existingProvider.games.push(game);
        } else {
            // If the OutcomeProvider does not exist, create a new one with the current game
            const newProvider: OutcomeProvider = {
                providerName: game.SectionId,
                games: [game],
            };
            outcomeProviders.push(newProvider);
        }
    });

    // Return the array of OutcomeProvider objects
    return outcomeProviders;
}