import { FC } from "react";
import { BoardBase } from "../board/BoardBase";
import { Box } from "@mui/material";
import WheelIcon from "../../../../assets/sweep/icons/WheelIcon.webp";

interface WheelButtonProps {
    onClick?: () => void
    portrait?: boolean
}

export const WheelButton: FC<WheelButtonProps> = ({onClick = () => {}, portrait = false}) => {
    return <Box
        // sx={{backgroundColor: '#ea7805'}}
        width={'10vw'}
        minWidth={'10vh'}
        maxWidth={portrait ? '12vw' : '12vh'}
        flex={1}
    >
        <BoardBase height={'100%'} width={'100%'}>
            <Box
                height={'6vh'}
                width={'6vh'}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                onClick={onClick}
                className={'pointer'}
            >
                <img height={portrait ? '200%' : '230%'} alt={'Wheel icon'} src={WheelIcon}/>
            </Box>
        </BoardBase>
    </Box>
}