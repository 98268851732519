import React, { FC } from "react";
import { Box, Typography } from "@mui/material";
import { ToggleKnob } from "./ToggleKnob";
import { NegativeBoard } from "../negative_board/NegativeBoard";

interface UniversalSwitcherProps {
    firstText: string
    secondText: string
    firstSelected: boolean
    onSwitch?: () => void
    size?: number
}

export const UniversalSwitcher: FC<UniversalSwitcherProps> = ({firstText, secondText, firstSelected, onSwitch = () => {}, size = 5}) => {
    const baseSize = 48 / size

    return <Box
        width={'100%'}
        display={'flex'}
        // sx={{backgroundColor: '#9bad26'}}
        flexDirection={firstSelected ? 'row' : 'row-reverse'}
        position={'relative'}
        padding={`${4 / baseSize}vh`}
        onClick={onSwitch}
        className={'pointer'}
    >
        <NegativeBoard size={size}/>
        <Box flex={1}>
            <ToggleKnob blueShadow>
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} pl={'1.5vh'}>
                    <Typography color={'#190F48'} fontWeight={500}>
                        {firstSelected ? firstText : secondText}
                    </Typography>
                </Box>
            </ToggleKnob>
        </Box>
        <Box flex={1}>
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} pt={'0.1vh'}>
                <Typography color={'#4A3C8D'} fontWeight={500}>
                    {!firstSelected ? firstText : secondText}
                </Typography>
            </Box>
        </Box>
    </Box>
}