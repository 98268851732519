export const getShadows = (baseSize: number): string => {
    const shadowList = [
        // outer glowing
        `0 -${3 / baseSize}vh ${5 / baseSize}vh rgba(113, 255, 47, 0.45)`,
        `0 -${3 / baseSize}vh ${8 / baseSize}vh #33FF00`,
        // inner border
        `inset 0 -${2 / baseSize}vh 0 rgb(216, 255, 186, 0.35)`,
        `inset 0 ${2 / baseSize}vh 0 rgb(216, 255, 186, 0.35)`,
        `inset ${2 / baseSize}vh 0 0 rgb(216, 255, 186, 0.35)`,
        `inset -${2 / baseSize}vh 0 0 rgb(216, 255, 186, 0.35)`,
        // bottom 3d effects
        `0 ${1 / baseSize}vh 0 #25BB00`,
        `0 ${4 / baseSize}vh 0 #48BE1F`,
        `0 ${6 / baseSize}vh 0 #5BE02C`,
        // bottom shadow
        `0 ${7 / baseSize}vh ${14 / baseSize}vh rgba(14, 0, 54, 0.65)`
    ]

    return shadowList.join(', ')
}

export const backgroundGradient = 'linear-gradient(0.00deg, #9EFE3E 0%, #0CBF09 100%)'