import { FC, useEffect } from "react";
import { useAppDispatch } from "../../../store";
import { useTransactions } from "../../../features/transactions/useTransactions";
import s from "./Transactions.module.css";
import { Paginator } from "../../../components/common/paginator/Paginator";
import { PageSizeSwitcher } from "../../../components/common/pageSizeSwitcher/PageSizeSwitcher";
import { TransactionsTable } from "./TransactionsTable";
import { useMediaQuery } from "@mui/material";
import { TransactionListCardStyle } from "../../../components/transaction/TransactionListCardStyle";
import { clearTransactions } from "../../../features/transactions/transactionsSlice";
import { useTransactionsWatcher } from "../../../features/transactions/useTransactionsWatcher";

export const Transactions: FC = () => {
    const dispatch = useAppDispatch()
    const tabletAndLarge = useMediaQuery('(min-width:700px)')
    const { pageSize, currentPage, totalPages } = useTransactions()

    useTransactionsWatcher()

    useEffect(() => {
        return () => {
            dispatch(clearTransactions())
        }
    }, [dispatch])

    return <div className={s.container}>
        <div className={s.tableContainer}>
            {tabletAndLarge ? <TransactionsTable/> : <TransactionListCardStyle/>}
        </div>
        <div className={s.bottomBar}>
            <Paginator totalPages={totalPages} currentPage={currentPage}/>
            <span className={s.flexGrow}/>
            <PageSizeSwitcher pageSize={pageSize}/>
        </div>
    </div>
}