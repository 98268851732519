import { Typography } from "@mui/material";
import { FC, ReactNode } from "react";

interface WhiteConvexTextProps {
    children?: ReactNode
    size?: string
    shadowSize?: number
    fontWeight?: number
    textTransform?: 'none' | 'capitalize' | 'uppercase' | 'lowercase' | 'initial' | 'inherit' | 'unset'
    lineHeight?: number
}

export const WhiteConvexText: FC<WhiteConvexTextProps> = ({children, size= '3.2vh', shadowSize = 5, fontWeight = 900, textTransform, lineHeight}) => {
    const baseSize = 48 / shadowSize

    const shadowsList: string[] = [
        `0 ${4 / baseSize}vh ${8 / baseSize}vh rgba(0, 0, 0, 0.35)`,
        `0 -${4 / baseSize}vh ${8 / baseSize}vh rgba(255, 255, 255, 0.65)`
    ]

    const shadows = shadowsList.map(shadow => `drop-shadow(${shadow})`).join(' ')

    return <Typography
        lineHeight={lineHeight ?? 1}
        textAlign={'center'}
        fontWeight={fontWeight}
        textTransform={textTransform ?? 'uppercase'}
        sx={{
            background: '#fff',
            "-webkit-background-clip": "text",
            color: 'transparent',
            filter: shadows,
            userSelect: 'none',
        }}
        fontSize={size}
        // color={'#fff'}
    >
        {children}
    </Typography>
}