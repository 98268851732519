import { FC, ReactNode } from "react";
import { Box } from "@mui/material";
import { BoardBase } from "../board/BoardBase";

interface BottomButtonPanelProps {
    children?: ReactNode
}

export const BottomButtonPanel: FC<BottomButtonPanelProps> = ({children}) => {
    return <Box
        flex={1}
    >
        <BoardBase height={'100%'} width={'100%'}>
            <Box
                height={'100%'}
                width={'100%'}
                display={'flex'}
                justifyContent={'space-evenly'}
                alignItems={'center'}
            >
                {children}
            </Box>
        </BoardBase>
    </Box>
}