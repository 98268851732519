import { FC, useCallback, useEffect, useRef, useState } from "react";
import { Avatar, Box, Chip } from "@mui/material";
import { usePlayGames } from "../../../features/play_games/usePlayGames";
import { useAppDispatch } from "../../../store";
import Config from "../../../config";
import s from './ProvidersList.module.css'
import { Link } from "react-router-dom";
import { useProviderWatcher } from "../../../features/play_games/useProviderWatcher";
import { GameProvider } from "../../../features/play_games/playGamesModels";
import { setGameSession } from "../../../features/play_games/playGamesSlice";

export const ProvidersList: FC = () => {
    const dispatch = useAppDispatch()
    useProviderWatcher()

    const { getProviders, isProvidersOutcomeLoading, providersOutcome, selectedProvider } = usePlayGames()
    const boxRef = useRef<HTMLDivElement>(null)
    const [isMouseOverBox, setIsMouseOverBox] = useState(false)
    const [initLoad, setInitLoad] = useState<boolean>(true)

    useEffect(() => {
        if (!isProvidersOutcomeLoading && providersOutcome === null && initLoad) {
            getProviders()
            setInitLoad(false)
        }
    }, [dispatch, getProviders, initLoad, isProvidersOutcomeLoading, providersOutcome])

    const handleWheel = useCallback((event: WheelEvent) => {
        if (isMouseOverBox) {
            event.preventDefault();
            const chipsBox = boxRef.current;
            chipsBox?.scrollBy({ left: event.deltaY * 7, behavior: 'smooth' });
        }
    }, [isMouseOverBox]);

    useEffect(() => {
        document.removeEventListener('wheel', handleWheel);
        document.addEventListener('wheel', handleWheel);

        return () => {
            document.removeEventListener('wheel', handleWheel);
        };
    }, [handleWheel]);

    return <Box
        borderTop={'var(--secondary-main-color) thin solid'}
        borderBottom={'var(--secondary-main-color) thin solid'}
        display={'flex'}
        height={'50px'}
        maxHeight={'50px'}
        paddingY={'4px'}
        paddingX={'10px'}
        className={s.list}

        ref={boxRef}
        onMouseEnter={() => setIsMouseOverBox(true)}
        onMouseLeave={() => setIsMouseOverBox(false)}
    >

        <Box display="flex" columnGap={'6px'} alignItems={'center'}>
            {providersOutcome && providersOutcome.map(provider => {
                return  provider.provider_name !== selectedProvider ?
                <Link className={s.link} key={provider.id}
                      onClick={() => dispatch(setGameSession({SessionUrl: null, SessionId: null}))}
                      to={`/games?provider_name=${provider.provider_name}`}>
                    <ProviderChip provider={provider}/>
                </Link> : <ProviderChip provider={provider}/>
            })}
        </Box>
    </Box>
}

interface ProviderChipProps {
    provider: GameProvider
}

const ProviderChip: FC<ProviderChipProps> = ({provider}) => <Chip
    color={'secondary'}
    label={provider.title}
    sx={{ fontSize: '13px', fontWeight: 300, '& .MuiChip-icon': {
            marginLeft: '8px'
        } }}
    icon={provider.logo ? <Avatar
        alt={`${provider.title} icon`}
        src={Config.hostDomain + provider.logo}
        sx={{ width: 19, height: 19 }}
    /> : undefined}
/>