import { FC } from "react";
import { Box } from "@mui/material";
import CardIcon from "../../../../assets/sweep/payment_icons/card.png";
import CashIcon from "../../../../assets/sweep/payment_icons/cash.png";
import CryptoIcon from "../../../../assets/sweep/payment_icons/crypto.png";

interface PaymentMethodIconProps {
    iconVariant: 'card' | 'crypto' | 'cash'
    size?: number
    heightUniversalPx?: number
}

export const PaymentMethodIcon:FC<PaymentMethodIconProps> = ({iconVariant, size = 5, heightUniversalPx}) => {
    const baseSize = 48 / size

    return <Box
        width={`${(heightUniversalPx ?? 70) / baseSize}vh`}
        height={`${(heightUniversalPx ?? 70) / baseSize}vh`}
        sx={{
            pointerEvents: 'none',
            backgroundImage: `url(${(() => {
                switch (iconVariant) {
                    case 'card': return CardIcon
                    case 'crypto': return CryptoIcon
                    case 'cash': return CashIcon
                }
            })()})`,
            backgroundPosition: 'center top',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
        }}
    />
}