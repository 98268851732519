import { FC, useState } from "react";
import { Dialog, DialogContent } from "@mui/material";
import { LoginForm } from "./LoginForm";
import Typography from "@mui/material/Typography";

interface LoginDialogProps {
    close: () => void
}

export const LoginDialog: FC<LoginDialogProps> = ({close = () => {}}) => {
    const [ opened, setOpened ] = useState(true)

    const pendingClose = () => {
        setOpened(false);
        setTimeout(() => close(), 500)
    }

    return <Dialog
        maxWidth={'xs'}
        fullWidth
        open={opened}
        onClose={pendingClose}
    >
        <DialogContent sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '15px',
        }}>
            <Typography alignSelf={'center'}>
                Log In
            </Typography>
            <LoginForm onDone={close}/>
        </DialogContent>
    </Dialog>
}