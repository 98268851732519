import { FC } from "react";
import { Box } from "@mui/material";
import { CoinPackCover } from "../../coin_pack_cover/CoinPackCover";
import { CoinsPackContainer } from "../../coin_pack_cover/CoinsPackContainer";
import { GameButtonFilled } from "../../button/GameButtonFilled";
import { CoinsPackColor } from "../../coin_pack_cover/CoinPackBackground";

interface FirstCoinScreenProps {
    onProceed?: (pack: CoinPack[]) => void
    hidden?: boolean
}

export const FirstCoinScreen: FC<FirstCoinScreenProps> = ({onProceed= (pack: CoinPack[]) => {}, hidden = false}) => {
    return <Box
        display={hidden ? 'none' : 'flex'}
        flexDirection={'column'}
        justifyContent={'space-between'}
        rowGap={'2vh'}
        maxHeight={'60vh'}
    >
        <Box
            display={'flex'}
            flexDirection={'column'}
            rowGap={'2vh'}
            flexGrow={1}
            sx={{
                overflowY: 'auto',
                scrollbarWidth: 'thin',
                scrollbarColor: 'rgba(255, 255, 255, 0) transparent',
                '&:hover': {
                    scrollbarColor: 'rgba(255, 255, 255, 0.1) transparent',
                }
            }}
        >

            <Box
                maxWidth={'60vh'}
                display={'flex'}
                flexWrap={'wrap'}
                justifyContent={'center'}
                columnGap={'2vh'}
                rowGap={'2vh'}
                py={'2vh'}
            >
                {coinPacks.map((coinPack, index) => <CoinPackCover key={`coin-pack-${coinPack.title}-${index}`} hasFunCoins={coinPack.hasFunCoins} hasRealCoins={coinPack.hasRealCoins} title={coinPack.title} price={coinPack.price} color={coinPack.color}/>)}
                {
                    coinPacks.length % 2 === 0 && <CoinsPackContainer/>
                }
                <CoinsPackContainer>
                    Test
                </CoinsPackContainer>
            </Box>
        </Box>

        <GameButtonFilled onClick={() => onProceed(coinPacks)}>
            Proceed with order
        </GameButtonFilled>
    </Box>
}


interface CoinPack {
    title: string
    hasFunCoins: boolean
    hasRealCoins: boolean
    price: string
    color: CoinsPackColor
}

const coinPacks: CoinPack[] = [
    {
        title: '10GC',
        hasFunCoins: false,
        hasRealCoins: true,
        price: '$10',
        color: 'orange'
    },
    {
        title: '10GC + 100FUN',
        hasFunCoins: true,
        hasRealCoins: true,
        price: '$20',
        color: 'blue'
    },
    {
        title: '100GC',
        hasFunCoins: false,
        hasRealCoins: true,
        price: '$50',
        color: 'purple'
    },
    {
        title: '10GC + 100FUN',
        hasFunCoins: true,
        hasRealCoins: true,
        price: '$1000',
        color: 'cyan'
    },
    {
        title: '10GC + 10FUN',
        hasFunCoins: true,
        hasRealCoins: true,
        price: '$70',
        color: 'violet'
    }
]