import { FC, ReactNode, useState } from "react";
import { Box } from "@mui/material";
import backgroundImage from "../../../../assets/sweep/background.webp";
import { TopBar } from "../top_bar/TopBar";
import { BottomCornersDecoration } from "./BottomCornersDecoration";
import { BottomMenu } from "../bottom_menu/BottomMenu";
import FullScreen from "react-fullscreen-crossbrowser";
import { ButtonPanel } from "../button_panel/ButtonPanel";
import { ButtonWithIcon } from "../button/ButtonWithIcon";
import Icon1 from "../../../../assets/sweep/icons/1.webp";
import Icon2 from "../../../../assets/sweep/icons/2.webp";
import Icon3 from "../../../../assets/sweep/icons/3.webp";
import AllIcon from "../../../../assets/sweep/icons/AllIcon.webp";

interface MainLayoutLandscapeProps {
    children?: ReactNode
}

export const MainLayoutLandscape: FC<MainLayoutLandscapeProps> = ({children}) => {
    const [fullscreenEnabled, setFullscreenEnabled] = useState<boolean>(false)

    const toggleFullscreen = () => {
        setFullscreenEnabled(!fullscreenEnabled)
    }

    return <FullScreen enabled={fullscreenEnabled}><Box
        sx={{
            backgroundColor: '#1E1060',
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        }}
        height={'100vh'}
        width={'100vw'}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        borderRadius={'3.5vh'}
        overflow={'hidden'}
    >
        <TopBar/>

        <Box
            width={'100%'}
            height={'58vh'}
            display={'flex'}
            justifyContent={'space-between'}
            zIndex={900}
            overflow={'visible'}
        >
            <ButtonPanel>
                <ButtonWithIcon iconURL={Icon1} iconAlt={'Settings icon'}/>
                <ButtonWithIcon iconURL={Icon2} iconAlt={'Settings icon'}/>
                <ButtonWithIcon iconURL={Icon3} iconAlt={'Settings icon'}/>
                <Box height={0}/>
                <ButtonWithIcon
                    iconURL={AllIcon}
                    iconAlt={'Settings icon'}
                    green
                    onClick={toggleFullscreen}
                />
            </ButtonPanel>
            {children}
        </Box>

        <BottomCornersDecoration/>
        <BottomMenu/>
    </Box>
    </FullScreen>
}