import React, { FC, useState } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { BackButton } from "../../button/BackButton";
import { GameButtonFilled } from "../../button/GameButtonFilled";
import { WarningBox } from "../../info_boxes/WarningBox";
import { GameTextField } from "../../game_text_field/GameTextField";
import { PictureBoxHolder } from "../../common_components/PictureBoxHolder";
import { CryptoDropdownWithIcons, CryptoHolder } from "../../dropdown/CryptoDropdownWithIcons";

import TetherIcon from "../../../../../assets/sweep/temp_icons/tether-icon.png"
import FluciIcon from "../../../../../assets/sweep/temp_icons/fluci-icon.png"
import D17Icon from "../../../../../assets/sweep/temp_icons/d17-icon.png"
import CreditIcon from "../../../../../assets/sweep/temp_icons/credit-icon.png"

interface SecondCoinsRedeemScreenProps {
    onProceed?: () => void
    hidden?: boolean
    onBack?: () => void
}

export const SecondCoinsRedeemScreen: FC<SecondCoinsRedeemScreenProps> = ({onProceed= () => {}, hidden = false, onBack = () => {}}) => {
    const [currency, setCurrency] = useState<CryptoHolder | undefined>()

    return <Box
        maxWidth={'50vh'}
        display={hidden ? 'none' : 'flex'}
        flexDirection={'column'}
        justifyContent={'space-between'}
        rowGap={'2vh'}
        maxHeight={'60vh'}
    >
        <BackButton onBack={onBack}/>

        <Box
            display={'flex'}
            flexDirection={'column'}
            rowGap={'2vh'}
            flexGrow={1}
            sx={{
                overflowY: 'auto',
                scrollbarWidth: 'thin',
                scrollbarColor: 'rgba(255, 255, 255, 0) transparent',
                '&:hover': {
                    scrollbarColor: 'rgba(255, 255, 255, 0.1) transparent',
                }
            }}
        >

            <CryptoDropdownWithIcons setValue={setCurrency} value={currency} list={[
                {name: 'USDT', iconUrl: TetherIcon},
                {name: 'FLUCI', iconUrl: FluciIcon},
                {name: 'D17', iconUrl: D17Icon},
                {name: 'CREDIT', iconUrl: CreditIcon},
            ]}/>

            <WarningBox>
                Please note: All "Golden coin" received using crypto can only will redeemed via crypto.
            </WarningBox>

            <Box
                display={'flex'}
                flexDirection={'column'}
                rowGap={'1vh'}
            >
                <Stack direction={'row'} alignItems={'center'} gap={1}>
                    <PictureBoxHolder picUrl={TetherIcon} heightUniversalPx={24} round/>
                    <Typography>USDT address <Box component={'span'} color={'red'}>*</Box></Typography>
                </Stack>
                <GameTextField
                    type={'text'}
                    // copyEnabled
                    // value={'19.9989895'}
                />
            </Box>

            <Box
                display={'flex'}
                flexDirection={'column'}
                rowGap={'1vh'}
            >
                <Typography>Cash amount <Box component={'span'} color={'red'}>*</Box></Typography>
                <GameTextField
                    type={'text'}
                    // copyEnabled
                    // value={'19.9989895'}
                    placeholder={'Max amount: $4.240'}
                    rightComponent={
                        <Button
                            sx={{
                                position: 'relative',
                                background: 'linear-gradient(180deg, rgb(12, 191, 9), rgb(158, 254, 62) 100%)',
                                color: 'white',
                                '&::after': {
                                    content: '""',
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    border: '2px solid rgba(216, 255, 186, 0.35)',
                                    borderRadius: 'inherit',
                                    pointerEvents: 'none',
                                }
                            }}
                        >
                            Max
                        </Button>
                    }
                />
            </Box>

            <Box
                display={'flex'}
                flexDirection={'column'}
                rowGap={'1vh'}
            >
                <Typography>Email for communication</Typography>
                <GameTextField
                    // value={login}
                    // onChange={event => setLogin(event.target.value)}
                    placeholder={'@'}
                    type={'email'}
                />
            </Box>
        </Box>

        <GameButtonFilled onClick={() => onProceed()}>
            Redeem
        </GameButtonFilled>
    </Box>
}