import { FC } from "react";
import { Box, Typography } from "@mui/material";

interface RoundButtonProps {
    sign: '+' | '-'
    size?: number
    disabled?: boolean
    onClick?: () => void
}

export const RoundButton: FC<RoundButtonProps> = ({size=5, sign, disabled = false, onClick = () => {}}) => {
    const baseSize = 48 / size
    const sideSize = `${26 / baseSize}vh`

    return <Box
        className={'pointer'}
        onClick={!disabled ? onClick : undefined}
        borderRadius={sideSize}
        width={sideSize}
        height={sideSize}
        sx={{
            userSelect: 'none',
            opacity: disabled ? '0.35' : '1',
            background: sign === '-' ? 'linear-gradient(180.00deg, rgb(191, 31, 9),rgb(254, 85, 62) 100%),rgb(217, 217, 217)' : 'linear-gradient(180.00deg, rgb(12, 191, 9),rgb(158, 254, 62) 100%)',
        }}
    >
        <Box
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            borderRadius={sideSize}
            border={'2px solid rgba(255, 255, 255, 0.15)'}
            width={'100%'}
            height={'100%'}>
            <Typography fontSize={`${22 / baseSize}vh`} fontWeight={950}>{sign}</Typography>
        </Box>

    </Box>
}