import { FC } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { NotificationIcon } from "./NotificationIcon";
import { PictureBoxHolder } from "../../common_components/PictureBoxHolder";

import YellowArrowIcon from '../../../../../assets/sweep/icons/yellowArrow.png'
import IconButton from "@mui/material/IconButton";

export interface NotificationObj {
    title: string;
    shortMessage: string;
    fullMessage: string
    read: boolean
    type: 'finance' | 'info'
}

interface NotificationItemProps {
    notification: NotificationObj
    size?: number
    onClickMore?: (message: NotificationObj) => void
    fullMode?: boolean
}

export const NotificationItem: FC<NotificationItemProps> = ({notification, size = 5, onClickMore = (message: NotificationObj) => {}, fullMode = false}) => {
    const baseSize = 48 / size

    return <Box
        display={'flex'}
        flexDirection={'column'}
        py={`${12 / baseSize}vh`}
        px={`${16 / baseSize}vh`}
        border={`${3 / baseSize}vh solid #3B29A7`}
        borderRadius={`${12 / baseSize}vh`}
        rowGap={'1.5vh'}
        sx={{
            backgroundColor: 'rgba(11, 8, 73, 0.4)',
        }}
    >
        <Stack direction={'row'} columnGap={1} alignItems={'center'}>
            <NotificationIcon size={size} type={notification.type} read={notification.read}/>
            <Typography fontSize={`${24 / baseSize}vh`} fontWeight={700}>
                {notification.title}
            </Typography>
        </Stack>
        <Box
            display={'flex'}
        >
            <Box
                flexGrow={1}
            >
                <Typography fontSize={`${16 / baseSize}vh`} color={'#7061C7'}>
                    {fullMode ? notification.fullMessage : notification.shortMessage}
                </Typography>
            </Box>
            {!fullMode && <Box
                display={'flex'}
                alignItems={'end'}
            >
                <IconButton onClick={() => onClickMore(notification)}>
                    <PictureBoxHolder picUrl={YellowArrowIcon} heightUniversalPx={24}/>
                </IconButton>
            </Box>}
        </Box>
    </Box>
}