import { FC } from "react";
import { Box } from "@mui/material";
import { CoinsPackColor } from "./CoinPackBackground";
import { Green3dTitle } from "../text/Green3dTitle";

interface CoinsPackPriceProps {
    size?: number
    borderRadius?: string
    color?: CoinsPackColor
    price: string
}

export const CoinsPackPrice: FC<CoinsPackPriceProps> = ({size = 5, borderRadius , color = 'orange', price}) => {
    const baseSize = 48 / size
    const radius = `${30 / baseSize}vh 0 ${borderRadius ? borderRadius : `${12 / baseSize}vh`} 0`

    return <Box
        sx={{
            backgroundImage: getGradient(color)
        }}
        borderRadius={radius}
        width={`${100 / baseSize}vh`}
        height={`${60 / baseSize}vh`}
    >
        <Box
            border={`${3 / baseSize}vh solid rgba(255, 255, 255, 0.2)`}
            width={'100%'}
            height={'100%'}
            borderRadius={radius}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
        >
            <Green3dTitle glowing={false} withShadows={false} size={`${22 / baseSize}vh`} shadowSize={3}>
                {price}
            </Green3dTitle>
        </Box>
    </Box>
}

// для текста
// /* $50 */
// position: absolute;
// width: 54px;
// height: 24px;
// left: 143px;
// top: 116px;
// display: flex;
// flex-direction: row;
// align-items: center;

//background: linear-gradient(0.00deg, rgb(68, 180, 68),rgb(234, 249, 68));
// -webkit-background-clip:
// text;
// -webkit-text-fill-color:
// transparent;
// background-clip:
// text;
// text-fill-color:
// transparent;
// font-family: Gotham;
// font-size: 20px;
// font-weight: 900;
// line-height: 24px;
// letter-spacing: 0%;
// text-align: center;
// text-transform: uppercase;

//Внешняя тень
// X:0 Y:4 Размытие: 12 расширение: 0 rgba(7, 37, 13, 0.62)
// Внешняя тень
// X:0 Y:2 Размытие: 0 расширение: 0 rgb(59, 118, 39)
// Внешняя тень
// X:0 Y:1 Размытие: 0 расширение: 0 rgb(50, 90, 37)
// Внешняя тень
// X:0 Y:-4 Размытие: 24 расширение: 0 rgb(214, 38, 0)

//1px	Внутренний
// rgb(85, 207, 85)
// rgb(245, 255, 139)

const getGradient = (color: CoinsPackColor) => {
    switch (color) {
        case 'orange':
            return 'linear-gradient(135.00deg, rgb(255, 206, 33) 0%,rgba(207, 17, 85, 0) 100%)'
        case 'purple':
            return 'linear-gradient(135.00deg, rgb(237, 33, 255) 0%,rgba(17, 25, 207, 0) 100%)'
        case 'cyan':
            return 'linear-gradient(135.00deg, rgb(20, 224, 114) 0%,rgba(17, 81, 207, 0) 100%)'
        case 'violet':
            return 'linear-gradient(135.00deg, rgb(33, 201, 255) 0%,rgba(146, 17, 207, 0) 100%)'
        case 'blue':
            return 'linear-gradient(135.00deg, rgb(33, 95, 255) 0%,rgba(17, 70, 207, 0) 100%)'
    }
}