import { FC } from "react";
import { Box, Typography } from "@mui/material";
import BackIcon from '../../../../assets/sweep/icons/back-btn-icon.png'

interface BackButtonProps {
    onBack?: () => void
    size?: number
    text?: string
}

export const BackButton: FC<BackButtonProps> = ({onBack = () => {}, size=5, text = 'Back'}) => {
    const baseSize = 48 / size

    return <Box
        className={'pointer'}
        onClick={onBack}
        display={'flex'}
        columnGap={`${15 / baseSize}vh`}
        alignItems={'center'}
    >
        <Box
            height={`${20 / baseSize}vh`}
            width={`${20 / baseSize}vh`}
            sx={{
                backgroundImage: `url(${BackIcon})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center'
        }}/>
        <Typography fontSize={`${15 / baseSize}vh`}>
            {text}
        </Typography>
    </Box>
}