import { FC, useState } from "react";
import { Dialog, DialogContent } from "@mui/material";
import { AddPayment } from "./AddPayment";

interface AddPaymentDialogProps {
    close: () => void
}

export const AddPaymentDialog: FC<AddPaymentDialogProps> = ({close = () => {}}) => {
    const [ opened, setOpened ] = useState(true)

    const pendingClose = () => {
        setOpened(false);
        setTimeout(() => close(), 500)
    }

    return <Dialog
        maxWidth={'xs'}
        fullWidth
        open={opened}
        onClose={pendingClose}
    >
        <DialogContent>
            <AddPayment onDone={pendingClose}/>
        </DialogContent>
    </Dialog>
}